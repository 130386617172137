import React, { useEffect, useState } from 'react';
import { getIcon } from '@flybits/webapp-design-system-react';
import FlowBox from 'components/FlowBox/FlowBox';
import PushBox from 'components/FlowBox/PushBox/PushBox';
import ContentBox from 'components/FlowBox/ContentBox/ContentBox';
import FullPageOverlay from 'components/FullPageOverlay/FullPageOverlay';
import AudienceIframeParent from 'components/Audience/AudienceIframeParent/AudienceIframeParent';
import AudienceIcon from 'assets/icons/audience.svg';

import LocationPreview from 'components/AudiencePreview/LocationPreview/LocationPreview';
import { stringRepFinal } from 'helpers/rule.helper';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
import TriggerBox from 'components/FlowBox/TriggerBox/TriggerBox';
import ContentAPI from 'services/api/content.api';
import { Content } from 'interface/content/content.interface';
import isInAppContentTemplate from 'helpers/is-in-appcontent.helper';

function AudienceBox(props: any) {
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [isAudienceEditIframeVisible, setIsAudienceEditIframeVisible] = useState(false);

  const [ruleData, setRuleData] = useState<any>(undefined);
  const [seedRuleData, setSeedRuleData] = useState<any>(undefined);
  const experience = props.experience;
  const [audienceHasError, setAudienceHasError] = useState(true);

  const restriction: any = {
    and: 'All of the following restrictions define your audience reach',
    or: 'Any of the following restrictions define your audience reach',
  };

  const preferred: any = {
    and: 'All of the following conditions define your preferred audience',
    or: 'Any of the following conditions define your preferred audience',
  };

  useEffect(() => {
    stringRepFinal(props.ruleStringRepresentation, props.ruleBody).then((r) => setRuleData(r));
    stringRepFinal(props.seedRuleStringRepresentation, props.seedRuleBody).then((r) => setSeedRuleData(r));
  }, [props.ruleStringRepresentation, props.seedRuleStringRepresentation, props.ruleBody, props.seedRuleBody]);

  function audienceRowType(audienceObj: any) {
    let locationText = 'User is in a location';
    if(audienceObj?.arguments?.[0]?.includes('Approximate')){
      locationText = 'User is in the approximate area of';
    }

    switch (audienceObj.type) {
      case 'location:map':
        return (
          <>
            {' '}
            <span>{locationText} &nbsp; <LocationPreview location={audienceObj.location} />{' '}</span>
          </>
        );
      case 'location:label':
        return <> User is in a location with the label {'"' + audienceObj.label + '"'}</>;
      case 'general':
        return (
          <>
            {' '}
            <span>{audienceObj.arguments[0]} {audienceObj.predicate} &nbsp; <b> {audienceObj.arguments[1]} </b>{' '}</span>
          </> // plugin
        );
      default:
        break;
    }
  }

  // we can probably memoize this and only update if stringObj arguments change
  function audienceRow(stringObj: any, idx: number = 1) {
    if (!Array.isArray(stringObj)) {
      // check if is not an empty array
      return (
        <div key={`stringRep_${idx}`} className="flow-box__card-container__card__step-body__plugins">
          <div className="flow-box__card-container__card__step-body__plugins__plugin-left">
            <b> {idx + 1}. </b>
          </div>
          <div className="flow-box__card-container__card__step-body__plugins__plugin-right">
            {Array.isArray(stringObj) ? (
              <> {stringObj[0]} </> // rule
            ) : (
              audienceRowType(stringObj)
            )}
          </div>
        </div>
      );
    }
  }

  function AudienceSection() {
    if (!ruleData || !seedRuleData) {
      return <></>;
    }
    const audience = ruleData.predicates.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    const seedAudience = seedRuleData.predicates.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    // Empty Restrictions
    if (!ruleData.predicates.length && !seedRuleData.predicates.length) {
      return (
        <>
          <div className="flow-box__card-container__card__step-body__descr">
            <div className="anyone">
              <span className="anyone__heading"> Anyone </span>
              <p> With no audience restrictions selected, this Experience may reach every user. </p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {ruleData.predicates.length > 0 ? (
            <div className="flow-box__card-container__card__step-body__content">
              <b> Restrictions </b>
              <div className="flow-box__card-container__card__step-body__descr">
                {ruleData.type === 'custom' ? (
                  <i> Restrictions {ruleData.toString} defines your audience reach. </i>
                ) : (
                  <p> {restriction[ruleData.type]} </p>
                )}
              </div>
              {audience}
            </div>
          ) : (
            <div className="flow-box__card-container__card__step-body__content">
              <b> Restrictions </b>
              <div className="flow-box__card-container__card__step-body__descr">
                None set
                <div className="empty-audience">
                  <span className="tab-icon"> {getIcon('infoFilled', '')} </span>
                  With no restrictions set, the audience can be anyone*.
                </div>
              </div>
              {audience}
            </div>
          )}
          {seedRuleData.predicates.length > 0 && (
            <div className="flow-box__card-container__card__step-body__content">
              <b> Preferred audience </b>
              <div className="flow-box__card-container__card__step-body__descr">
                {ruleData.type === 'custom' ? (
                  <i> Conditions {seedRuleData.toString} define the preferred audience members. </i>
                ) : (
                  <p> {preferred[ruleData.type]} </p>
                )}
              </div>
              {seedAudience}
            </div>
          )}
        </>
      );
    }
  }

  function showAudienceModal() {
    if (
      !props.isNotEditable &&
      !experience?.activation?.manualActivationAt &&
      !experience?.activation?.automaticActivationAt
    ) {
      setIsAudienceEditIframeVisible(true);
    }
  }

  function body() {
    return (
      <>
        <AudienceSection />
      </>
    );
  }

  const contentAPI = new ContentAPI();
  const [content, setContent] = useState<Content[]>([]);
  const fetchContentInstance = (contentIds: string[]) => {
    contentIds?.map((instance) => {
      return contentAPI.getInstance(instance).then((res: any) => {
        setContent((content) => [...content, res]);
      });
    });
  };

  const handleRequestUpdatedInstance = () => {
    const id = props!!.actions[0]?.actionBody?.contentIds;
    setContent([])
    fetchContentInstance(id);
  };

  const getUpdateRequired = () => {
    const expInstanceOrTemplate = props!!.actions && props!!.actions?.find((item : any) => item?.actionType === "experience")
    return expInstanceOrTemplate;
  }

  const updateContentDependencies = getUpdateRequired();
  useEffect(() => {
    if (updateContentDependencies) {
      const _contentIds = updateContentDependencies?.actionBody?.contentIds;
      if (_contentIds) {
        setContent([]);
        fetchContentInstance(_contentIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContentDependencies?.actionBody?.contentIds]);

  useEffect(() => {
    setAudienceHasError(false);
    experience.steps?.map((step: any) => {
      if (experience.showError && step.hasError && step.isRoot) {
        setAudienceHasError(true);
      }
    });
  }, [experience]);

  const renderStepActions = (props: any) => {
    const amplifiedExists = props?.actions?.find((item: any) => item?.actionType === 'push');
    return props.actions?.map((action: any, i: number) => {
      const actionProps = {
        ...action,
        key: `action_${i}`,
        isNotEditable: props.isNotEditable,
        icon: props.icon,
        tooltip: props.tooltip,
        trigger: props.trigger,
      };

      switch (action.actionType) {
        case 'push':
          if(props.trigger.type === ('')) return;       
          return <PushBox {...actionProps} />;
        case 'experience':
          if (content.length === 0) {
              return (
              <ContentBox
                {...actionProps}
                contentInstance={content}
                requestUpdatedInstance={handleRequestUpdatedInstance}
              />
            );
          } else {
            return (
            <ContentBox
              {...actionProps}
              contentInstance={content}
              contents={content}
              amplifiedContentId={amplifiedExists?.actionBody?.body[0]?.payload?.data?.contentId?.value}
              requestUpdatedInstance={handleRequestUpdatedInstance}
            />
          );
        }
        // break;
        default:
          break;
      }
    });
  };

  function onSubmitHandle(payload: {
    ruleBody: object;
    seedRuleBody: object;
    ruleStringRepresentation: string;
    seedRuleStringRepresentation: string;
  }) {
    reduxTemplatedExperienceAction.setAudience(payload);
    setIsAudienceEditIframeVisible(false);
  }


  return (
    <>
      {!props.isRuleHidden && (
        <>
          <FlowBox {...props} type="Target Audience" icon={AudienceIcon} body={body()} onBoxClick={showAudienceModal} />
          <TriggerBox {...props} experience={experience} />
        </>
      )}
      {isInAppContentTemplate(props) ? <div>{renderStepActions(props)}</div> : renderStepActions(props)}
      <FullPageOverlay isVisible={isAudienceEditIframeVisible}>
        <AudienceIframeParent
          ruleBody={props.ruleBody}
          seedRuleBody={props.seedRuleBody}
          onSubmit={onSubmitHandle}
          onCancel={() => setIsAudienceEditIframeVisible(false)}
          audienceHasError={audienceHasError}
          experience={experience}
        />
      </FullPageOverlay>
    </>
  );
}

export default AudienceBox;
