
import BaseAPI from './base.api';

import { Plugin } from 'interface/plugin/plugin.interface';

export default class PluginAPI extends BaseAPI {
  private ROUTES = {
    all: '/context/ctxschema/contextplugins?limit=1000000',
    historical: 'context/ctxhistory/query/'
  }

  // whitelist flybits/sdk plugins only.
  getPlugins(): Promise<Plugin[]> {
    return this.GET(this.ROUTES.all);
    // Use the following method for whitelisting.
    // return this.GET(this.ROUTES.all).then((plugins: any) => plugins.filter((plugin: any) => ["flybits", "sdk"].includes(plugin.provider)));
  }

  getHistoricalPlugins(id: string): Promise<any> {
    return this.GET(`${this.ROUTES.historical}${id}?limit=1000000`);
  }
}
