
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FeatureFlagActionType } from 'redux/feature-flag/feature-flag.type';
import bulletTrain from 'bullet-train-client';

// Example Usage
// ===================================================
// import useFeatureFlag from 'hooks/useFeatureFlag';
// const { flags } = useFeatureFlag();
// flags['enabled_audience_optimization'] ? 1 : 0

export default function useFeatureFlag() {
  const auth = useSelector((state: any) => state.auth);
  const featureFlag = useSelector((state: any) => state.featureFlag);
  const dispatch = useDispatch();
  let isInitialized = false;

  // List of Feature Flags used for current application. (flags cannot be updated via API)
  // The following array of value should be created on https://app.bullet-train.io/.
  const flags = [
    'tx_ao_enabled',
    'tx_ao_demo',
    'tx_fis_demo',
    'tx_point_solutions',
  ];

  // List of default values of traits for new users. (Traits can be updated via API - user specific)
  // The values will be persisted on https://app.bullet-train.io/
  const traits = [
    {key: 'tx_never_show_preferred_save_modal', value: false},
    {key: 'tx_never_show_restriction_snackbar', value: false},
    {key: 'tx_never_show_preferred_snackbar', value: false},
    {key: 'tx_never_show_delete_amplification_snackbar', value: false},
  ];

  function initBulletTrain() {
    bulletTrain.identify(`${auth.user.email} - ${auth.user.id}`);
    bulletTrain.init({
      environmentID: process.env.REACT_APP_BULLET_TRAIN_ID,
        onChange: ((res: any) => {
          if (isInitialized) return;
          const traitsObj: any = {};
          const flagsObj: any = {};
          flags.forEach((name: string) => { flagsObj[name] = res[name].enabled });
          traits.forEach((trait: any) => {
            const value = bulletTrain.getTrait(trait.key);
            if (value !== undefined) {
              traitsObj[trait.key] = value;
            } else {
              traitsObj[trait.key] = trait.value;
            }
          });
          dispatch({type: FeatureFlagActionType.INITIALIZE_FLAGS, payload: { flags: flagsObj, traits: traitsObj }});
          bulletTrain.setTraits(traitsObj);
          isInitialized = true;
        }
      )
    });
  }

  function setTrait(key: string, value: any) {
    bulletTrain.setTrait(key, value);
    dispatch({
      type: FeatureFlagActionType.SET_TRAITS,
      payload: {
        traits: {
          ...featureFlag.traits,
          [key]: value
        }
      }
    })
  }

  useEffect(() => {
    if (!auth.authenticated) return;
    if (featureFlag.isInitialized) return;
    if (isInitialized) return;
    initBulletTrain();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.authenticated,
    isInitialized,
    featureFlag.isInitialized]);

  return {
    flags: featureFlag.flags,
    traits: featureFlag.traits,
    setTrait
  };

}
