import React, { useEffect, useState, useRef } from 'react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import Storage from 'services/storage';

import { isEmpty } from 'lodash';
import { IFrameHelper } from 'helpers/iframe.helper';
import { FlightButton, FlightSnackbar, FlightModal } from '@flybits/webapp-design-system-react';
import AudienceSearchableModal from 'components/Modal/AudienceSearchableModal/AudienceSearchableModal';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import './AudienceIframe.scss';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';

interface IProps {
  type: 'restriction' | 'preferred';
  rule: string; // base64
  isVisible: boolean;
  onInit: (iframe: any) => void;
  audienceHasError?: boolean;
  experience: TExperienceInstance;
  hideOptions?: boolean;
}

declare global {
  var Flatted:any;
}
var inject = function(){
  const script = document.createElement('script');
  script.setAttribute('src', 'https://unpkg.com/flatted@3.1.0/min.js');
  document.body.appendChild(script);
}();

export default function AudienceIframe(props: IProps | any) {
  const hideSnackbarTraitKey = `tx_never_show_${props.type}_snackbar`;
  let iframe: any;
  const storage = new Storage();
  const { traits, setTrait } = useFeatureFlag();

  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isAudienceSearchableModalVisible, setIsAudienceSearchableModalVisible] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    title: '',
    className: 'audience-snackbar',
    type: 'info',
    isVisible: !traits[hideSnackbarTraitKey],
    isFloating: false,
    actionName: 'Got it!',
    isAutoDismiss: true,
    action: () => closeSnackbar(),
    content: <div> </div>,
    icon: '',
  });

  const _confirmModalProps = {
    isVisible: false,
    icon: '',
    title: `Overwrite existing ${props.type === 'restriction' ? 'audience restrictions' : 'preferred audience'}?`,
    content: `Using an audience template will overwrite your existing ${
      props.type === 'restriction' ? 'audience restrictions' : 'preferred audience'
    }.`,
    primaryButton: {
      name: 'Continue and overwrite',
      onClick: () => onOverwriteConfirm(),
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setConfirmModalProps({ ...confirmModalProps, isVisible: false }),
    },
  };

  const [confirmModalProps, setConfirmModalProps] = useState({ ..._confirmModalProps });

  const [selectedRule, _setSelectedRule] = useState(undefined);
  const selectedRuleRef = useRef(selectedRule);
  function setSelectedRule(rule: any) {
    selectedRuleRef.current = rule;
    _setSelectedRule(rule);
  }

  useEffect(() => {
    openIframe(props.rule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.type === 'restriction' && props?.experience?.audienceOptimizationEligible) {
      setSnackbarData({
        ...snackbarData,
        isVisible: true,
        content: (
          <div>
            This will restrict the experience reach to only people within those restrictions. We will not target people
            outside of the restrictions. With no restrictions set, this experience can reach every user.
          </div>
        ),
      });
    }
    if (props.type === 'preferred') {
      setSnackbarData({
        ...snackbarData,
        isVisible: true,
        content: (
          <div>
            Add conditions to describe the types of people who you think are most likely to enjoy your experience. To
            generate accurate audience optimization suggestions we will target up to{' '}
            {props.experience?.explorationBudget?.populationSize || 0} users outside of your preferred audience.
          </div>
        ),
      });
    }
    if (props.audienceHasError) {
      setSnackbarData({
        ...snackbarData,
        isVisible: true,
        type: 'error',
        content: (
          <div>
            Audience can not be <strong>anyone</strong> for this type of experience. You must select atleast one
            audience restriction.
          </div>
        ),
        actionName: 'Dismiss',
        icon: 'warning',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.audienceHasError]);

  function onOverwriteConfirm() {
    setIsAudienceSearchableModalVisible(false);
    openIframe(selectedRuleRef.current);
    setConfirmModalProps({ ...confirmModalProps, isVisible: false });
  }

  function onInfoConfirm() {
    setIsInfoModalVisible(false);
    // set trait
  }

  async function openIframe(rule?: any) {
    const base64rule = rule
      ? window.btoa(escape(Flatted.stringify(rule)))
      : !isEmpty(props.rule)
      ? window.btoa(escape(props.rule))
      : '';
    let origin = await storage.getItem('origin');
    origin = origin || 'http://localhost:4200/#';
    let baseURL = `${origin.split('/#')[0]}/#/context-hub/create-v2/?hideSidebar=true&type=${props.type}`;
    let URL = base64rule ? baseURL + `&data=${base64rule}` : baseURL;
    if (!iframe) {
      iframe = new IFrameHelper(`id-${props.type}`, document.querySelector(`.iframe-${props.type}`), URL);
    } else {
      iframe.setURL(URL);
    }
    iframe.loadIframe().then(() => {
      props.onInit(iframe);
    });
  }

  function openInfoModal() {
    setIsInfoModalVisible(true);
  }

  function closeSnackbar() {
    setTrait(hideSnackbarTraitKey, true);
    setSnackbarData({
      ...snackbarData,
      isVisible: false,
    });
  }

  return (
    <div className={`audience-iframe ${!props.isVisible ? 'hidden' : ''}`}>
      {(!props.hideOptions && snackbarData.title !== '') ? <FlightSnackbar
        title={snackbarData.title}
        className={snackbarData.className}
        type={snackbarData.type}
        isVisible={snackbarData.isVisible}
        isFloating={snackbarData.isFloating}
        actionName={snackbarData.actionName}
        isAutoDismiss={snackbarData.isAutoDismiss}
        action={snackbarData.action}
        content={snackbarData.content}
        icon={snackbarData.icon}
      /> : null}
      <div className="audience-select">
        {!props.hideOptions && traits[hideSnackbarTraitKey] && (
          <FlightButton
            theme="minor"
            className="help"
            onClick={() => openInfoModal()}
            iconLeft="infoFilled"
            label="Help"
          />
        )}
        <FlightButton
          theme="minor"
          onClick={() => setIsAudienceSearchableModalVisible(true)}
          iconLeft="people"
          label="Use an audience template"
        />
      </div>
      <div className={`iframe-container iframe-${props.type}`}></div>
      <AudienceSearchableModal
        isVisible={isAudienceSearchableModalVisible}
        onApply={(rule: any) => {
          setSelectedRule(rule);
          setConfirmModalProps({ ...confirmModalProps, isVisible: true });
          setIsAudienceSearchableModalVisible(false);
        }}
        onCancel={() => setIsAudienceSearchableModalVisible(false)}
      />
      {/* Overwrite Confirm Modal */}
      <ConfirmModal {...confirmModalProps} />
      {/* Info Confirm Modal */}
      <FlightModal
        size="large"
        isVisible={isInfoModalVisible}
        toggleModalShown={() => setIsInfoModalVisible(false)}
        content={
          <div className="audience-iframe-guide">
            <div className="audience-iframe-guide__image">
              {props.type === 'restriction' ? (
                <img src={require('assets/images/audience-restriction.svg')} alt="audience restriction guide" />
              ) : (
                <img src={require('assets/images/audience-preferred.svg')} alt="audience preferred guide" />
              )}
            </div>
            <div className="audience-iframe-guide__detail">
              <h2> {props.type === 'restriction' ? 'Restrictions' : 'Preferred Audience'} </h2>
              <p>
                {props.type === 'restriction'
                  ? 'This will restrict the experience reach to only people within those restrictions. With no restrictions set, this experience will reach every user.'
                  : 'Add conditions to describe the types of people who you think are most likely to enjoy your experience. People in this group are more likely to see the experience.'}
              </p>
              <FlightButton size="large" label="Got It!" onClick={() => onInfoConfirm()} />
            </div>
          </div>
        }
      />
    </div>
  );
}
