import React, { useState, useEffect } from 'react';
import { FlightButton, FlightCarousel, FlightCarouselItem, FlightSnackbar } from '@flybits/webapp-design-system-react';
import PlayBtn from 'assets/icons/play-btn.svg';
import SettingsAPI from 'services/api/setting.api';
import UseAsyncState from 'hooks/useAsyncState';
// import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { SidePanel } from 'components/Shared/SidePanel/SidePanel';
import { MediaPreviewModal } from 'components/Shared/MediaPreviewModal/MediaPreviewModal';
import { getAfterDelimiter } from 'helpers/string.helper';
import './TemplateSidePanel.scss';

interface TemplateSidePanelProps {
  template?: any;
  className?: string;
  location?: any;
  showPanel: boolean;
  togglePanel: () => void;
}

export const TemplateSidePanel = (props: TemplateSidePanelProps) => {
  const { template, className, showPanel, location, togglePanel } = props;
  const history = useHistory();
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
  const MAIN_CLASS = 'template-side-panel';
  const settingsApi = new SettingsAPI();
  const [settingsData, isSettingsError] = UseAsyncState(
    () => settingsApi.getSidePanelSettings().then((r) => r.settings),
    [],
  );

  // TODO: Need to take the keys from library/lifecycle and get correctly stylized information
  const library = template?.tags?.find((tag: string) => tag?.indexOf('category-') === 0);
  const lifecycle = template?.tags?.find((tag: string) => tag?.indexOf('subcategory-') === 0);
  const objectivesList = template?.tags?.filter((tag: string) => !tag?.includes('category-'));
  const libraryText = library ? getAfterDelimiter(library, '-') : '-';
  const lifecycleText = lifecycle ? getAfterDelimiter(lifecycle, '-') : '-';
  const objectives = objectivesList?.length > 0 ? objectivesList?.join() : '-';
  const templateMatchFromData = settingsData?.sidePanelData?.find((panelData: any) => panelData?.id === template?.id);
  const queryParams = queryString.parse(location?.search);

  const details = [
    { title: 'Library', text: libraryText },
    { title: 'Lifecycle', text: lifecycleText },
    { title: 'Objectives', text: objectives },
    { title: 'Channel(s)', text: templateMatchFromData?.channel },
  ];

  const info = [
    { title: 'Description', text: template?.description || 'No description available.' },
    {
      title: 'Target Customers',
      text: templateMatchFromData?.targetcustomers,
    },
    { title: 'Content Message', text: templateMatchFromData?.contentmessage },
    {
      title: 'Testimonials',
      name: templateMatchFromData?.testimonials?.[0]?.name,
      message: templateMatchFromData?.testimonials?.[0]?.message,
    },
  ];

  const closeVideoPreview = () => {
    setVideoPreviewUrl('');
  };

  const handleVideoPreview = (url: string) => {
    setVideoPreviewUrl(url);
  };

  const isVideo = (args: string) => {
    const VIDEO_PROVIDER = 'youtube';
    if (args.includes(VIDEO_PROVIDER)) {
      return true;
    }
  };

  const handleCreate = () => {
    return history.push(
      `/templated-experiences/create/?template_id=${template.id}${
        queryParams ? '&' + queryString.stringify(queryParams) : ''
      }`,
    );
  };

  const renderPreview = (args: string) => {
    if (isVideo(args)) {
      return (
        <>
          <div style={{ position: 'relative' }} onClick={() => handleVideoPreview(args)}>
            <img className={`${MAIN_CLASS}__thumbnail`} src={template?.icon} alt="Placeholder" />
            <img className={`${MAIN_CLASS}__play-btn`} src={PlayBtn} alt="play-btn" />
          </div>
        </>
      );
    }
    return (
      <img
        className={`${MAIN_CLASS}__thumbnail`}
        src={args}
        alt="Placeholder"
        onClick={() => handleVideoPreview(args)}
      />
    );
  };

  const RenderErrorBanner = () => {
    const [closeSnackbar, setCloseSnackBar] = useState(false);
    const message = `Error fetching sidepanel data`;
    useEffect(() => {
      if (isSettingsError) setCloseSnackBar(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSettingsError]);

    return (
      <FlightSnackbar
        isVisible={closeSnackbar}
        isFloating={false}
        type="error"
        className="library-selection__error-banner"
        content={message}
        isAutoDismiss={true}
        action={() => {
          setCloseSnackBar(false);
        }}
        actionName="Close"
      />
    );
  };

  const content = (
    <div className={`${MAIN_CLASS}__content`}>
      <RenderErrorBanner />
      <FlightCarousel className={`${MAIN_CLASS}__carousel`}>
        {templateMatchFromData?.media?.map((item: any, index: number) => (
          <FlightCarouselItem key={`video-key-${index}`}>{renderPreview(item)}</FlightCarouselItem>
        ))}
      </FlightCarousel>
      <div className={`${MAIN_CLASS}__details`}>
        {details.map((detail) => (
          <div key={detail.title} className={`${MAIN_CLASS}__detail`}>
            <span className={`${MAIN_CLASS}__detail__title`}>{detail.title}</span>
            <span className={`${MAIN_CLASS}__detail__text`}>{detail.text}</span>
          </div>
        ))}
      </div>
      {info.map((item) => (
        <div key={item.title} className={`${MAIN_CLASS}__info`}>
          <span className={`${MAIN_CLASS}__info__title`}>{item.title}</span>
          <span className={`${MAIN_CLASS}__info__text`}>{item.text}</span>
          <span className={`${MAIN_CLASS}__info__text`}>{item.message}</span>
          <span className={`${MAIN_CLASS}__info__text ${MAIN_CLASS}__info__text--bold`}>{item.name}</span>
        </div>
      ))}
      <MediaPreviewModal
        url={videoPreviewUrl}
        showModal={videoPreviewUrl !== ''}
        closeModal={closeVideoPreview}
        isVideo={isVideo(videoPreviewUrl)}
      />
    </div>
  );

  const footer = (
    <div className={`${MAIN_CLASS}__footer`}>
      {/* <div
        className={`${MAIN_CLASS}__footer__favourite`}
        onClick={() => {
          // TODO: Hook up adding favourites, find a filled heart-icon
        }}
      >
        <HeartIcon className={`${MAIN_CLASS}__heart-icon`} />
        <span className={`${MAIN_CLASS}__footer__text`}>Add to favourites</span>
      </div> */}
      <FlightButton className={`${MAIN_CLASS}__footer__button`} label="Close" theme="secondary" onClick={togglePanel} />
      <FlightButton className={`${MAIN_CLASS}__footer__button`} label="Create" onClick={handleCreate} />
    </div>
  );

  return (
    <SidePanel
      className={`${MAIN_CLASS}` + (className ? ` ${className}` : '')}
      title={template?.name || ''}
      content={content}
      footer={footer}
      showPanel={showPanel}
      togglePanel={togglePanel}
    />
  );
};
