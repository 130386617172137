import React from 'react';
import { ReactComponent as EmptyAdd } from 'assets/icons/empty_add_content.svg';
import './MultiActionPushPreview.scss';

export default function MultiActionPushPreview(props: any) {
  return (
    <div
      className="multiaction-push-preview"
    >
      <div className="multiaction-push-preview__preview-body">
        <div className="multiaction-push-preview__preview-body__header">
          <img src={require('assets/icons/your-app-icon.svg')} alt="" className="multiaction-push-preview__preview-body__your-app-icon" /> Your App
        </div>
        {
          props.title || props.message ? (
            <div>
              <br/>
              <div className="multiaction-push-preview__preview-body__title">{props.title}</div>
              <div className="multiaction-push-preview__preview-body__body">{props.message}</div>
            </div>
          ) : props.placement === 'box' ? (
            <>
            <EmptyAdd className="multiaction-push-preview__preview-body__icon" />      
            <div className="multiaction-push-preview__preview-body__icon__body">None</div>
            </>
          ): null
        }

      </div>
    </div>
  )
}
