import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { times } from 'lodash';

import qs from 'qs';
import {TExperienceInstance} from 'interface/templated-experience/templated-experience.interface';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { history } from 'redux/store';
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from "helpers/analytics.helper";
import ScheduleEditModal, { scheduleDataType } from 'components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal';
import {getWarningOnLaunchScheduled, transformScheduleData, convertDateToTimezone} from 'helpers/templated-experience.helper';
import useFeatureFlag from 'hooks/useFeatureFlag';

import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import FinishIcon from 'assets/icons/end.svg';
import ControlBar from 'components/ControlBar/ControlBar';
import AudienceBox from 'components/FlowBox/AudienceBox/AudienceBox';
import useDebounce from 'hooks/useDebounce';
import './TemplatedExperienceCreate.scss';


export default function TemplatedExperienceCreate(props:any) {
  const { flags } = useFeatureFlag();
  const location = useLocation();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const [confirmEmptyAudience, setConfirmEmptyAudience] = useState('UNSELECTED');
  const [isAudienceEmpty, setIsAudienceEmpty] = useState(false);
  const scheduleModalProps = {
    isVisible: false,
    title: 'Schedule this Experience',
    instance: {},
    primaryButton: {
      name: 'Confirm',
      onClick: (scheduleData: scheduleDataType) => setScheduleData(scheduleData),
      disabled: false,
      loading: false,
    },
    cancelButton: {
      name: '',
      onClick: () => onScheduleCancel(),
    }
  }
  const [scheduleProps, setScheduleProps] = useState(scheduleModalProps);

  const confirmModalProps = {
    isVisible: false,
    isLaunching: false,
    icon: '',
    title: "Launch Immediately",
    content: <div>This experience does not have a schedule associated with it.
      As a result it will launch <strong>immediately</strong> and be visible to your customers.
      If you wish to add a schedule, click on &quot;Cancel&quot; and add a schedule.</div>,
    primaryButton: {
      name: 'Confirm',
      onClick: () => onActivateHandle(),
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    },
    confirmEmptyAudience,
    setConfirmEmptyAudience,
    isAudienceEmpty
  }
  const [modalProps, setModalProps] = useState(confirmModalProps);
  const queryParams = queryString.parse(props.location?.search);
  const [nameHasError, setNameHasError] = useState('');
  const debouncedName = useDebounce(reduxTemplatedExperienceState?.template?.name!!, 500);

  useEffect(() => {
    if(reduxTemplatedExperienceState?.template?.steps?.length &&
      reduxTemplatedExperienceState?.template?.steps[0]?.ruleStringRepresentation === '' &&
      reduxTemplatedExperienceState?.template?.steps[0]?.seedRuleStringRepresentation === ''
    ) {
      setIsAudienceEmpty(true);
      setModalProps({...modalProps, isAudienceEmpty: true});
    } else {
      setIsAudienceEmpty(false);
      setModalProps({...modalProps, isAudienceEmpty: false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reduxTemplatedExperienceState?.template?.steps?.[0].ruleStringRepresentation, reduxTemplatedExperienceState?.template?.steps?.[0].seedRuleStringRepresentation, modalProps.isVisible]);

  useEffect(()=>{
    if(debouncedName !== 'Untitled' && reduxTemplatedExperienceState.isModified) {
      validateExperienceName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[debouncedName]);

  // supports two different query param for loading template information
  // create?template_id=xxx  <-- used for creation of instance from template
  // create?instance_id=xxx  <-- used for duplication of existing instance
  useEffect(() => {
    if (query.template_id) { // creation from template
      reduxTemplatedExperienceAction.fetchTemplate(query.template_id).then(async() => {
        setIsLoaded(true);
        await onNameEditHandle('Untitled');
      });
    } else if (query.instance_id) { // duplication of existing instance
      reduxTemplatedExperienceAction.fetchInstance(query.instance_id).then((instance: TExperienceInstance) => {
        reduxTemplatedExperienceAction.setInstance({instance: null})
        reduxTemplatedExperienceAction.setTemplate({template: Object.assign(instance, {
          name: `${instance.name || 'Untitled'} - Copy`,
          activation: {
            automaticActivationAt: 0,
            automaticDeactivationAt: 0,
            manualActivationAt: 0,
            manualDeactivationAt: 0
          },
          status: "Draft"
        })}
        );
        setIsLoaded(true)
      });
    }
    return () => {
      reduxTemplatedExperienceAction.clearTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.template_id, query.instance_id, reduxTemplatedExperienceAction]);

  async function validateExperienceName() {
    await setNameHasError('');
    if(debouncedName && debouncedName !== 'Untitled') {
      return await reduxTemplatedExperienceAction.checkExperienceName({search: debouncedName, searchExact: true})
        .then((checkName: any) => {
          if(checkName?.data?.length) {
            setNameHasError('Name is not unique. Try another one.');
            return false;
          } else {
            return true;
          }
        });
    }
    if(debouncedName === '') {
      await setNameHasError('Name can not be empty.');
      return false;
    }
    return true;
  }

  function onActivateHandle() {
    reduxTemplatedExperienceAction.createTemplatedInstance({status: 'Active'}).then(() => {
      history.push(`/templated-experiences`);
    });
    setModalProps({...modalProps, isVisible: false});
  }

  async function onLaunchHandle() {
    if(await validateExperienceName()) {
      const schedule = reduxTemplatedExperienceState.template?.schedule;
      const {startDate, endDate, newTitle, newMessage} = getWarningOnLaunchScheduled(schedule);
      let title = modalProps.title;
      let content = modalProps.content;
      let primaryButton = modalProps.primaryButton;
      let covertStartTime = convertDateToTimezone(schedule.start, schedule.timezone);
      let covertEndTime = convertDateToTimezone(schedule.end, schedule.timezone);
      if (schedule?.start!! >= covertStartTime.tz_unix) {
        title = newTitle;
        content = (
          <div>
            <div>{newMessage}</div>
            <div className="templated-experiences-edit__warning-schedule">
              <div><strong>Start date:</strong> {startDate}</div>
              <div><strong>End date:</strong> {endDate}</div>
            </div>
          </div>
        );
        primaryButton = {
          ...modalProps.primaryButton,
          onClick: () => {
            reduxTemplatedExperienceAction.createTemplatedInstance({status: 'Scheduled'}).then(() => {
              trackEvent({
                category: 'Templated Experiences',
                action: 'Create and Schedule',
              });
              history.push(`/templated-experiences`);
            });
            setModalProps({...modalProps, isVisible: false});
          }
        }
      }

      if ((schedule?.start!! && schedule?.start!! < covertStartTime.tz_unix) ||
        (schedule?.end!! && schedule?.end!! < covertEndTime.tz_unix)) {
        title = 'Expired Schedule';
        content = <div>You can&apos;t launch an experience with an expired schedule.
          To launch, edit your schedule {!schedule.start ? 'to add start date' : 'to a current date/time'}.</div>;
        primaryButton = {
          ...modalProps.primaryButton,
          name: 'Edit Schedule',
          onClick: () => {
            setScheduleProps({...scheduleProps, isVisible: true});
            setModalProps({...modalProps, isVisible: false});
          }
        }
      }

      setModalProps({
        ...modalProps,
        isLaunching: true,
        title,
        content,
        isVisible: true,
        primaryButton,
      });
    }
  }


  function onCancelHandle() {
    if(reduxTemplatedExperienceState.isModified) {
      setModalProps({
        ...modalProps,
        isVisible: true,
        isLaunching: false,
        icon: 'warning.svg',
        title: 'You have unsaved changes',
        content: <div>If you leave this page, all unsaved changes made will be lost.</div>,
        primaryButton: {
          name: 'Confirm',
          onClick: () => {
            reduxTemplatedExperienceAction.setModified(false);
            if (query.instance_id) {
              history.push(`/templated-experiences${queryParams?.page ? '/?page='+queryParams.page : ''}`);
            } else if (flags['tx_point_solutions']) {
                history.push(`/templated-experiences/library-selection${queryParams?.page ? '/?page='+queryParams.page : ''}`);
            } else {
              history.push(`/templated-experiences/template-selection${queryParams?.page ? '/?page='+queryParams.page : ''}`);
            }
          },
          disabled: false,
        },
        cancelButton: {
          name: 'Cancel',
          onClick: () => setModalProps({...modalProps, isVisible: false}),
        }
      });
    } else {
      setModalProps({
        ...modalProps,
        isLaunching: false,
      });
      if (query.instance_id) {
        history.push(`/templated-experiences${queryParams?.page ? '/?page='+queryParams.page : ''}`);
      } else if (flags['tx_point_solutions']) {
        history.push(`/templated-experiences/library-selection${queryParams?.page ? '/?page='+queryParams.page : ''}`);
    } else {
      history.push(`/templated-experiences/template-selection${queryParams?.page ? '/?page='+queryParams.page : ''}`);
    }
    }
  }

  async function onDraftHandle() {
    if(await validateExperienceName()) {
      reduxTemplatedExperienceAction.createTemplatedInstance({status: 'Draft'}).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Create and Save as Draft',
        });
        history.push(`/templated-experiences`);
      });
    }
  }

  function onNameEditHandle(name: string) {
    setNameHasError('');
    reduxTemplatedExperienceAction.setName(name);
  }

  function onScheduleHandle() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: true
    });
  }

  function setScheduleData(scheduleData:scheduleDataType) {
    reduxTemplatedExperienceAction.setSchedule(transformScheduleData(scheduleData));
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }

  function onScheduleCancel() {
    setScheduleProps(scheduleModalProps);
  }

  function renderStep(template: any, idx: number, experience: any) {
    const props = { ...template, key: `step_${idx}` };
    if (template.isRoot) {
      return <AudienceBox {...props} experience={experience} />
    }
  }

  function actionTab() {
    return isLoaded && (
      <div className="templated-experiences-create">
        <div className="templated-experiences-create__main">
          {reduxTemplatedExperienceState.isLoading ? (
            <div className="templated-experiences-create__main__progress">
              {times(3, (i) => (
                <div key={i} style={{ marginRight: 100}}>
                  <Skeleton width={300} height={300} />
                </div>
              ))}
            </div>
            ) : reduxTemplatedExperienceState.template ? (
              <div className="templated-experiences-create__main__flow">
                {reduxTemplatedExperienceState.template?.steps?.length && (
                  reduxTemplatedExperienceState.template.steps.map((_template: any, idx: number) => renderStep(_template, idx, reduxTemplatedExperienceState.template))
                )}
                <div>
                  <img className='templated-experiences-create__finish-icon' src={FinishIcon} alt="FinishIcon"/>
                </div>
              </div>
            ) : isLoaded && reduxTemplatedExperienceState.template ? (
              <div className="templated-experiences-create__main__flow">
                {reduxTemplatedExperienceState.template.steps?.map((_template: any, idx: number) => renderStep(_template, idx, reduxTemplatedExperienceState.template))}
                <div>
                  <img className='templated-experiences-create__finish-icon' src={FinishIcon} alt="FinishIcon"/>
                </div>
              </div>
            ) : isLoaded && reduxTemplatedExperienceState.errorMessage ? (
              <div className="templated-experiences-create__main__error">Error: {reduxTemplatedExperienceState.errorMsg}</div>
            ) : isLoaded && (
              <div className="templated-experiences-create__main__empty"> There are no template </div>
            )
          }
        </div>
        <ConfirmModal {...modalProps} setModalProps={setModalProps} />
        <ScheduleEditModal
          {...scheduleProps}
          type={reduxTemplatedExperienceState?.template?.steps[0]?.trigger?.type}
          schedule={reduxTemplatedExperienceState.template.schedule}
        />
      </div>
    )
  }

  return (
    <>
      {reduxTemplatedExperienceState.template && (
        <ControlBar
          name={reduxTemplatedExperienceState.template?.name || ''}
          status="Draft"
          onCancel={onCancelHandle}
          onDraft={()=>onDraftHandle()}
          onLaunch={onLaunchHandle}
          onLaunchDisabled={reduxTemplatedExperienceState.template.incompleteCount > 0}
          onNameEdit={onNameEditHandle}
          showScheduleModal={onScheduleHandle}
          template={reduxTemplatedExperienceState.template}
          count={reduxTemplatedExperienceState.template.incompleteCount}
          onIncompleteHandle={() => reduxTemplatedExperienceAction.showError('template')}
          actionTab={actionTab()}
          nameHasError={nameHasError}
        />
      )}
    </>
  )
}
