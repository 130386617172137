import BaseAPI from './base.api';
import { PaginationResponse } from 'interface/shared/api.interface';
import { TExperience, TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';

export default class TemplatedExperienceAPI extends BaseAPI {
  private ROUTES = {
    allInstances: '/kernel/journey/instances',
    getInstance: '/kernel/journey/instances/:id',
    createInstance: '/kernel/journey/instances',
    updateInstance: '/kernel/journey/instances/:id',
    updateInstanceSchedule: '/kernel/journey/instances/:id/schedules',
    activateInstance: '/kernel/journey/instances/:id/activate',
    deactivateInstance: '/kernel/journey/instances/:id/deactivate',
    deleteInstance: '/kernel/journey/instances/:id',
    allTemplates: '/kernel/journey/templates',
    getTemplate: '/kernel/journey/templates/:id',
    getPushTypes: '/push/push/payloadtypes',
  };

  getTemplatedInstances(opts = {limit: '20', offset: '0', search: '', searchExact: false, sortby: 'createdAt', sortorder: 'desc' }): Promise<PaginationResponse<TExperienceInstance>> {
    let params = {limit: '', offset: '', search: '', searchExact: false, sortby: '', sortorder: '' };
    params = Object.assign(params, opts);
    params.search = params.search.trim();
    return this.GET(this.ROUTES.allInstances, params);
  }

  getTemplatedExperiences(opts = {}): Promise<PaginationResponse<TExperience>> {
    let params = { limit: 1000, offset: 0 };
    params = Object.assign(params, opts);
    return this.GET(this.ROUTES.allTemplates, params);
  }

  getTemplatedExperience(id: string): Promise<TExperience> {
    return this.GET(this.buildURL(this.ROUTES.getTemplate, {id: id}))
  }

  getTemplatedExperienceInstance(id: string): Promise<TExperienceInstance> {
    return this.GET(this.buildURL(this.ROUTES.getInstance, {id: id}))
  }

  createTemplatedInstance(tExperience: TExperienceInstance): Promise<TExperienceInstance> {
    return this.POST(this.ROUTES.createInstance, tExperience);
  }

  updateTemplatedExperienceInstance(tExperience: TExperienceInstance, queryParams?: object|undefined): Promise<TExperienceInstance> {
    return this.PUT(this.buildURL(this.ROUTES.updateInstance,{id: tExperience.id}, queryParams), tExperience);
  }

  updateTemplatedExperienceInstanceSchedule(id: string, schedule: TExperienceInstance['schedule']): Promise<TExperienceInstance> {
    return this.PUT(this.buildURL(this.ROUTES.updateInstanceSchedule,{id}), schedule);
  }

  activateTemplatedExperienceInstance(id: string): Promise<string> {
    return this.PUT(this.buildURL(this.ROUTES.activateInstance,{id}));
  }

  deactivateTemplatedExperienceInstance(id: string): Promise<string> {
    return this.PUT(this.buildURL(this.ROUTES.deactivateInstance,{id}));
  }

  deleteTemplatedExperienceInstance(id: string): Promise<string> {
    return this.DELETE(this.buildURL(this.ROUTES.deleteInstance,{id}));
  }

  getPushTypes() {
    return this.GET(this.ROUTES.getPushTypes);
  }
}
