import React from 'react';
import {FlightButton, FlightCheckbox, FlightModal} from "@flybits/webapp-design-system-react";
import './ConfirmModal.scss';
import AudienceIcon from "assets/icons/audience.svg";

export default function ConfirmModal(props: any) {

  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={() => props.cancelButton.onClick()}
      header={
        <div className="confirm-modal__header">
          {props.icon && (
            <img src={require(`assets/icons/${props.icon}`)} className="confirm-modal__header-icon" alt="modal icon"/>
          )}
          <h3> {props.title} </h3>
        </div>
      }
      content={
        <div className="confirm-modal">
          <div className="confirm-modal__content">
            {props.content}
            {props.isAudienceEmpty && props.isLaunching &&
              <div>
                <div className="templated-experiences-create__modal-warn-section">
                  <img src={AudienceIcon} alt="Audience icon" className="templated-experiences-create__modal-warn-section__icon" />
                  <div>With no audience restrictions selected, this Experience may reach every user. Are you sure you want to proceed?</div>
                </div>
              </div>
            }
            {props.isAudienceEmpty && props.confirmEmptyAudience && props.isLaunching &&
            <div>
                <FlightCheckbox
                  label="Yes, I am sure"
                  checkState={props.confirmEmptyAudience}
                  onSelect={() => props.setModalProps({...props, confirmEmptyAudience: props.confirmEmptyAudience === 'SELECTED' ? 'UNSELECTED' : 'SELECTED'})}
                />
              </div>
            }
          </div>
        </div>
      }
      footer={
        <div className="confirm-modal__footer">
          {props.cancelButton && (
            <FlightButton
              theme="secondary"
              onClick={() => props.cancelButton.onClick()}
              label={props.cancelButton.name}
            />
          )}
          {props.secondaryButton?.name && (
            <FlightButton
              type="secondary"
              onClick={() => props.secondaryButton.onClick()}
              label={props.secondaryButton.name}
            />
          )}
          {props.primaryButton && (
            <FlightButton
              onClick={() => props.primaryButton.onClick()}
              disabled={props.primaryButton.disabled || (props.isAudienceEmpty && props.confirmEmptyAudience === 'UNSELECTED' && props.isLaunching)}
              label={props.primaryButton.name}
            />
          )}
        </div>
      }
    />
  )
}
