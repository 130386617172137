
import React from 'react';
import Image from 'material-ui-image';
import queryString from 'query-string';
import { Location } from 'interface/location/location.interface';
import './GoogleMapSnapshot.scss';
import { CircularProgress } from '@material-ui/core';
import { calculateCenter, calculateCircleData } from 'helpers/location.helper';

export default function GoogleMapSnapshot({location, stringRepresentation} : {location: Location, stringRepresentation: string|undefined}) {

  const coordinates = location.shape[0].map((coordinate: any) => coordinate.lat ? `${coordinate.lat},${coordinate.lng}` : coordinate);
  let queryParams = {
    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
    path: `color:0x29BFEDFF|weight:2|${coordinates.join('|')}`,
    size: '200x200',
    scale: 2,
  };

  if(stringRepresentation?.indexOf('.approximateArea') !== -1) {
    let shape = null;
    const approxAreaRadius = 3000; //metres

    if (location?.shape?.[0]?.length) {
      shape = location.shape?.[0];
    }

    const shapeCenter = calculateCenter(shape);
    const lat = shapeCenter.lat;
    const lng = shapeCenter.lng;
    const radius = shapeCenter.radius + approxAreaRadius;
    queryParams = {
      ...queryParams,
      path: `color:0x29BFEDFF|weight:0|fillcolor:0x29BFED63${calculateCircleData(lat, lng, radius)}`,
    };
  }

  const baseURL = '//maps.googleapis.com/maps/api/staticmap'
  const mapURL = `${baseURL}?${queryString.stringify(queryParams)}`;

  return (
    <div className="google-map-snapshot">
      <Image
        src={mapURL}
        alt={location.name}
        loading={<CircularProgress size={50} />}
        style={{backgroundColor: '#F2F3F5'}}
      />
    </div>
  )
}
