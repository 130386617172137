import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FlightButton, FlightSelect, FlightTextInput, FlightTextArea } from '@flybits/webapp-design-system-react';
import ContentActionEdit from '../ContentActionEdit/ContentActionEdit';

import './PushActionEdit.scss';

interface IProps {
  onApply: (formData: any) => void;
  step: any;
  selectedActionIndex: number;
}

// Import the same style used in PushEdit.tsx
export default function PushActionEdit({ onApply, step, selectedActionIndex }: IProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  const languages = [
    { name: 'English', id: 'en', isDefault: true },
    { name: 'French', id: 'fr', isDefault: false },
  ];

  const notificationOptions = [
    { key: 'basic', name: 'App Home Screen' },
    { key: 'weblink', name: 'Weblink' },
    { key: 'in-appcontent', name: 'In-App Content' },
  ];

  const defaultFormData = {
    notificationType: { key: 'basic', name: 'App Home Screen' },
    contentId: '',
    en: {
      title: '',
      description: '',
      url: '',
    },
    fr: {
      title: '',
      description: '',
      url: '',
    },
  };

  const [formData, setFormData] = useState<any>(defaultFormData);

  useEffect(() => {
    if (selectedActionIndex === -1) {
      setFormData(defaultFormData);
    } else if (step.actions[selectedActionIndex].type === 'push') {
      setFormData(step.actions[selectedActionIndex].data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionIndex]);

  function updateField(languageID: 'en' | ' fr', field: 'title' | 'description' | 'url', value: string) {
    formData[languageID][field] = value;
    setFormData({ ...formData });
  }

  const handleOnSelectInstance = (data: any) => {
    //stubbed function to set contentId for both languages
    if(data?.id) {
      formData['contentId'] = data?.id;
      setFormData({ ...formData });  
    }
  }

  return (
    <div className="push-edit">
      <b>Notification Leads To</b>
      <div className="notification-type">
        <FlightSelect
          label=""
          options={notificationOptions}
          selected={formData.notificationType}
          hasLabelAnimation
          handleOptionClick={(e: any) => {
            formData.notificationType = e;
            setFormData({ ...formData });
          }}
          dropdownMaxHeight="150px"
          width="252px"
        />
      </div>
      <div className="tab-section">
        <Tabs
          forceRenderTabPanel={true}
          selectedIndex={selectedTab}
          onSelect={(newValue: number) => setSelectedTab(newValue)}
          className="flight-tab"
          selectedTabClassName="flight-tab--selected"
        >
          <TabList className="flight-tab--list">
            {/* Tabs */}
            {languages.map((language: any, index: number) => (
              <Tab className="flight-tab--list__item" key={`tab-${index}`}>
                {language.name}
                {language.isDefault && <span className="required">*</span>}
              </Tab>
            ))}
          </TabList>
          {/* Tab Content */}
          <div className="tab-section__panels">
            {languages.map((language: any, index: number) => {
              return (
                <TabPanel key={`tab-panel-${index}`}>
                  <div className={index === selectedTab ? 'visible' : 'hidden'}>
                    <FlightTextInput
                      className="create-edit-push__input"
                      label="Title"
                      type="text"
                      placeholderText="Title"
                      width="100%"
                      value={formData[language.id].title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateField(language.id, 'title', e.target.value)
                      }
                    />
                    <FlightTextArea
                      className="create-edit-push__input"
                      label="Message"
                      maxLength={170}
                      width="100%"
                      value={formData[language.id].description}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateField(language.id, 'description', e.target.value)
                      }
                    />
                    {formData.notificationType.key === 'weblink' && (
                      <div style={marginBottom}>
                        <FlightTextInput
                          className="push-edit__input"
                          label="Weblink URL"
                          type="text"
                          iconInput=""
                          placeholderText=""
                          hasClearIcon={false}
                          width="100%"
                          value={formData[language.id].url}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateField(language.id, 'url', e.target.value)
                          }
                        />
                      </div>
                    )}
                    {formData.notificationType.key === 'in-appcontent' ? <hr /> : ''}
                    {formData.notificationType.key === 'in-appcontent' ? (
                      <div className="push-action-edit__content-preview" style={{ marginTop: 100 }}>
                        <ContentActionEdit
                          step={step}
                          isCalledFromPush
                          selectedActionIndex={selectedActionIndex}
                          onApply={() => undefined}
                          getContentIdOnClick={(data: any) => handleOnSelectInstance(data)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </TabPanel>
              );
            })}
          </div>
          <div style={{marginTop: 50}}>
          <FlightButton
            label="Apply"
            onClick={() => {
              onApply(formData);
              setFormData(defaultFormData);
            }}
          />
          </div>
        </Tabs>
      </div>
      <div></div>
    </div>
  );
}

const marginBottom = { marginBottom: '15px' };
