
import BaseAPI from './base.api';

export default class AOAPI extends BaseAPI {
  private ROUTES = {
    getMetrics: '/nestor/ao-learner/training/metrics/:id',
    postMetrics: '/nestor/ao-learner/training/metrics'
  }

  getMetrics(id: string): Promise<any> {
    return this.GET(this.buildURL(this.ROUTES.getMetrics, {id: id}));
    // Enable below line for testing as all instances will by default have no data to show curve
    // return this.GET(this.buildURL(this.ROUTES.getMetrics, {id: 'F4F86E9E-0AAD-4C80-B38C-B0382DCC9BDA'}))
  }

  postMetrics(body: any): Promise<any> {
    return this.POST(this.ROUTES.postMetrics, body);
  }
}
