
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import useFeatureFlag from 'hooks/useFeatureFlag';
import AnalyticsPerformanceOptimizedChart from 'components/Analytics/AnalyticsPerformanceOptimizedChart/AnalyticsPeformanceOptimizedChart';
import AOAPI from 'services/api/ao.api';

import 'pages/AODemo/Edit/AOEdit.scss';

export default function Performance(props: any) {
  // const { flags } = useFeatureFlag();
  const AoAPI = new AOAPI();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const { flags } = useFeatureFlag();

  useEffect(() => {
    if (reduxTemplatedExperienceState.isLoading) return;
    if (reduxTemplatedExperienceState.instance.status !== 'Draft') {
      setIsLoading(true);
      AoAPI.getMetrics(reduxTemplatedExperienceState.instance.id).then((response: any) => {
        setData(response);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxTemplatedExperienceState.isLoading, reduxTemplatedExperienceState.instance]);

  function noData() {
    return (
      <div className="no-data">
        <div className="no-data__center">
          <h2> No data to show</h2>
          <p> Nothing to show yet. Check back after users have been targeted. </p>
        </div>
      </div>
    )
  }

  function OptimizedGraph() {
    if (!flags['tx_ao_enabled']) {
      return <></>;
    }
    if(reduxTemplatedExperienceState.instance.audienceOptimizationEligible) {
      if (reduxTemplatedExperienceState.instance.status !== 'Draft') {
        return data ? (
          data?.optimizationGraph ? (
            <AnalyticsPerformanceOptimizedChart
              instance={reduxTemplatedExperienceState.instance}
              data={data}
              onSetData={setData}
            />
          ) : noData()
        ) : noData();
      } else {
        return noData();
      }
    } else {
      return null;
    }
  }

  return (
    <div className="templated-experiences-edit">
      <div className="templated-experiences-edit__performance">
        <div className="templated-experiences-edit__performance__container">
          <div className="analytics-chart-container">
            {isLoading ? (
              <Skeleton width={'100%'} height={500} />
            ) : (
              <OptimizedGraph/>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
