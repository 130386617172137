import React, { useState } from 'react';
import parse from 'html-react-parser';
import './StreamLinedContentPreview.scss';
import { Content } from 'interface/content/content.interface';
import { decodeURIComponentSafe } from 'helpers/safe.decodeuri.helper';

interface RenderPreviewProps {
  title: string;
  description: string;
  header: string;
  image: string;
  link: any;
  buttons: any;
  details: string;
}

const RenderCardView = (props: RenderPreviewProps) => {
  const { title, description, header, image, link, buttons } = props;
  const decodedPreviewBody = decodeURIComponentSafe(description ? description : '');
  const acceptableLength = 200;
  const truncatedBody = decodedPreviewBody?.substring(0, acceptableLength) || '';
  const fullText = decodedPreviewBody || '';
  const [isShowMore, setShowMore] = useState(false);

  const toggleShowMore = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowMore((isShowMore) => !isShowMore);
  };

  return (
    <div className="streamlined-content__preview">
      {image && <img className="streamlined-content__preview__header-img" src={image} alt="headerimg" />}
      {header && (
        <div className="streamlined-content__preview__header">
          <span className="streamlined-content__preview__header__wrap">{header}</span>
        </div>
      )}
      {title && (
        <div className="streamlined-content__preview__title">
          <span className="streamlined-content__preview__title__wrap">{title}</span>
        </div>
      )}
      {description && (
        <>
          <div className="streamlined-content__preview__description">
            {parse(isShowMore ? fullText : truncatedBody)}
          </div>
          <span className="streamlined-content__preview__description__showmore" onClick={toggleShowMore}>
            {decodedPreviewBody?.length > acceptableLength && <div>{isShowMore ? 'Show Less' : 'Show More'}</div>}
          </span>
        </>
      )}
      {buttons?.[0] && (
        <div className="streamlined-content__preview__button--primary">{buttons[0].localizations.en.text}</div>
      )}
      {buttons?.[1] && (
        <div className="streamlined-content__preview__button--secondary">{buttons[1].localizations.en.text}</div>
      )}
      {link && <div className="streamlined-content__preview__link">{link.localizations.en.text}</div>}
    </div>
  );
};

const RenderDetailedView = (props: Content) => {
  const { title, description, header, image } = props?.content?.data[0]?.localizations?.en;
  const { details } = props?.content?.data[0];
  const decodedPreviewBody = decodeURIComponentSafe(description ? description : '');
  const fullText = decodedPreviewBody || '';
  const detailedText = decodeURIComponentSafe(details ? details?.localizations?.en?.body : '');
  return (
    <div className="streamlined-content__preview-detailed">
      {header && <div className="streamlined-content__preview-detailed__header">{header}</div>}
      {title && (
        <div className="streamlined-content__preview-detailed__title">
          <span className="streamlined-content__preview-detailed__title__wrap">{title}</span>
        </div>
      )}
      {image && <img className="streamlined-content__preview-detailed__header-img" src={image} alt="headerimg" />}
      {description && (
        <>
          <div className="streamlined-content__preview-detailed__description">
            {parse(fullText)}
            {parse(detailedText)}
          </div>
        </>
      )}
    </div>
  );
};

export const StreamlineContentPreview = (props: Content) => {
  const [isCardView, setIsCardView] = useState(true);
  const { title, description, header, image } = props?.content?.data[0]?.localizations?.en;
  const { details, link, buttons } = props?.content?.data[0];
  const propsToRender: RenderPreviewProps = { title, description, header, image, link, buttons, details };

  const toggleCardViews = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsCardView((isCardView) => !isCardView);
  };

  return (
    <div className="streamlined-content">
      <div className="streamlined-content__title">{title}</div>
      <>
        {details && details?.localizations && (
          <div className="streamlined-content__tabs">
            <span
              className={`streamlined-content__tabs__first${isCardView ? '--selected' : ''}`}
              onClick={toggleCardViews}
            >
              Card view
            </span>
            <span
              className={`streamlined-content__tabs__second${!isCardView ? '--selected' : ''}`}
              onClick={toggleCardViews}
            >
              Detailed view
            </span>
          </div>
        )}
        {isCardView ? <RenderCardView {...propsToRender} /> : <RenderDetailedView {...props} />}
        <div className="streamlined-content__disclaimer">Actual appearance may vary</div>
      </>
    </div>
  );
};
