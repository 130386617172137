import React, { useState, useEffect, useContext } from 'react';
import { StreamlineContentPreview } from 'components/Content/PreviewContent/StreamLinedContentPreview';
import { Content } from 'interface/content/content.interface';
import { ReactComponent as PolyIcon } from 'assets/icons/collapse-polygon.svg';
import { ReactComponent as EmptyAdd } from 'assets/icons/empty_add_content.svg';

import { useSelector } from 'react-redux';

import './MiniCardContent.scss';
import { AmplifiedPushPreview } from 'components/Content/AmplifiedPush/AmplifiedPushPreview';
import { MultiActionContainerContext } from 'components/Shared/MultiActionContainer/MultiActionContainer';
import { isEmpty } from 'lodash';

interface Props {
  content?: Content;
  hideCollapse: boolean;
}

const NoContent = () => {
  return (
    <div className="mini-card-content__no-ctnt">
      <EmptyAdd className="mini-card-content__no-ctnt__icon" />
      <div className="mini-card-content__no-ctnt__icon__body">None</div>
    </div>
  );
};

const UnsupportedPreview = (props: Content) => {
  return (
    <div className="mini-card-content__unsupported-content">
      <div className="mini-card-content__unsupported-content__header">{props?.localizations?.en?.name}</div>
      <div className="mini-card-content__unsupported-content__body">
        Preview not available
      </div>
    </div>
  );
};

interface InAppContentProps {
  content: Content;
  hideCollapse: boolean;
}

const InAppContent = (props: InAppContentProps) => {
  const { templateType } = props.content;
  const { title } = props?.content?.content?.data[0]?.localizations?.en || '';
  const { collapse } = useContext(MultiActionContainerContext); // global collapse from Context
  const [_collapse, _setCollapse] = useState(false); // internal collapse state

  const toggleCollapse = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    _setCollapse((collapse) => !collapse);
  };

  useEffect(() => {
    if(props.hideCollapse) {
      _setCollapse(true);
      return;
    }
    _setCollapse(collapse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse]);

  enum StreamlinedContentTypes {
    'concierge-card-buttons',
    'concierge-card-link',
  }

  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  let contentInstanceOrTemplate =
    (!isEmpty(reduxTemplatedExperienceState?.instance) && reduxTemplatedExperienceState?.instance) ||
    reduxTemplatedExperienceState?.template;

  const showAmplificationBar = contentInstanceOrTemplate?.steps[0]?.actions?.find(
    (item: any) => item?.actionType === 'experience',
  );

  const isLocked = contentInstanceOrTemplate.status === 'Active';
  return templateType in StreamlinedContentTypes ? (
    <>
      <div className="mini-card-content__supported-content">
        {!_collapse ? <div className="mini-card-content__supported-content__title">{title}</div> : null}
      </div>
      {_collapse ? (
        <>
          <StreamlineContentPreview {...props.content} />
          <div className={isLocked ? 'mini-card-content__not-clickable' : ''}>
            {showAmplificationBar ? <AmplifiedPushPreview contentId={props.content.id} /> : null}
          </div>
        </>
      ) : (
        <div className={isLocked ? 'mini-card-content__not-clickable' : ''}>
          <>
            {showAmplificationBar ? (
              <AmplifiedPushPreview contentId={props.content.id} hidePreview={!_collapse} />
            ) : null}
          </>
        </div>
      )}
      {!props.hideCollapse && (
        <div className="streamlined-content__collapse" onClick={toggleCollapse}>
          <PolyIcon
            className="streamlined-content__collapse__polygon"
            style={_collapse ? { transform: `rotate(360deg)` } : { transform: `rotate(180deg)` }}
          />
        </div>
      )}
    </>
  ) : (
    <>
      <UnsupportedPreview {...props.content} />
      <div className={isLocked ? 'mini-card-content__not-clickable' : ''}>
        {showAmplificationBar ? <AmplifiedPushPreview contentId={props.content.id} /> : null}
      </div>
    </>
  );
};

export function MiniCardContent(props: Props) {
  return props.content ? <InAppContent content={props.content} hideCollapse={props.hideCollapse} /> : <NoContent />;
}
