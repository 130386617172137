
import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { PushFormContext } from 'components/PushMessage/PushFormProvider/PushFormProvider';
import {FlightSelect, FlightNumberInput, FlightCheckbox, FlightTooltip} from '@flybits/webapp-design-system-react';
import { deserializeDelivery } from 'helpers/templated-experience.helper';
import { tail } from 'lodash';
import QuestionIcon from 'assets/icons/question.svg';
import './PushDelivery.scss';
import useActions from "hooks/useActions";
import * as TExperienceActions from "redux/templated-experience/templated-experience.action";

export default function PushDelivery() {

  const tempState = useSelector((state: any) => state.templatedExperience.temp);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);

  const periods = [
    { key: 'second', name: 'Seconds' },
    { key: 'minute', name: 'Minutes' },
    { key: 'hour', name: 'Hours' },
    { key: 'day', name: 'Days' },
    { key: 'week', name: 'Weeks' },
    { key: 'month', name: 'Months' },
    { key: 'year', name: 'Years' },
  ];
  
  const limits = [
    { key: '0', name: 'Unlimited' },
    { key: '1', name: 'One' },
  ]

  const context: any = useContext(PushFormContext);

  useEffect(() => {
    let trigType = context?.action?.trigger?.type!!;
    if (trigType === 'reminder' || tempState.delivery?.trigger === 'reminder') {
      setReminderDeliveryData();
    }
    // content is amplified by triggered push, so we set that into context here
    if (trigType === 'triggered' || tempState.delivery?.trigger === 'triggered' || context.isAmplified) {
      setTriggeredDeliveryData();
    }
    if (trigType === 'broadcast' || tempState.delivery?.trigger === 'broadcast') {
      context.setDeliveryData({});
    }
    return () => {
      context.setDeliveryData({});
      reduxTemplatedExperienceAction.setTempDelivery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setReminderDeliveryData() {
    let deliveryInstance = deserializeDelivery(context.action.actionBody.delivery);
    context.setDeliveryData({
      ...context.deliveryData,
      trigger: 'reminder',
      frequencyTitle: 'Notification Interval',
      frequencyDescr: 'You can set how often each user can receive a reminder/follow up notification',
      frequencyString: ['Send ', ' notification(s) in total with a delay of ', ' between each notification'],
      limitString: ['Allow each user to enter experience ', ' time(s)'],
      delayString: ['Delay each notification by '],
      frequency: {
        total: deliveryInstance?.frequency?.total || '2',
        value: deliveryInstance?.frequency?.value || '1',
        period: {
          key: deliveryInstance?.frequency?.period?.key || 'day',
          name: deliveryInstance?.frequency?.period?.name || 'Days',
        },
      },
      limit: {
        key: deliveryInstance?.limit?.key || '',
        name: deliveryInstance?.limit?.name || 'Unlimited',
      },
      delay: {
        selected: deliveryInstance?.delay?.selected || 'UNSELECTED',
        value: deliveryInstance?.delay?.value || '0',
        period: {
          key: deliveryInstance?.delay?.period?.key || 'minute',
          name: deliveryInstance?.delay?.period?.name || 'Minutes',
        },
      },
    });

  }

  function setTriggeredDeliveryData() {
    let deliveryInstance = deserializeDelivery(context?.action?.actionBody?.delivery);
    context.setDeliveryData({
      ...context.deliveryData,
      trigger: 'triggered',
      frequencyTitle: 'Notification Limit',
      frequencyDescr: 'You can limit the number of times a triggered notification is sent to the same user',
      frequencyString: ['Send no more than one notification per user every '],
      frequency: {
        total: deliveryInstance?.frequency?.total || '',
        value: deliveryInstance?.frequency?.value || '1',
        period: {
          key: deliveryInstance?.frequency?.period?.key || 'day',
          name: deliveryInstance?.frequency?.period?.name || 'Days',
        },
      },
      limitString: ['Allow an absolute maximum of ', ' notification(s) per user in total'],
      limitCheck: deliveryInstance?.limit?.key !== '' && deliveryInstance?.limit?.key !== undefined ? 'SELECTED' : 'UNSELECTED',
      limit: {
        key: deliveryInstance?.limit?.key || '',
        name: deliveryInstance?.limit?.name || 'Unlimited',
      },
      remain: deliveryInstance?.remain || 'UNSELECTED',
      delayString: ['Delay each notification by '],
      delay: {
        selected: deliveryInstance?.delay?.selected || 'UNSELECTED',
        value: deliveryInstance?.delay?.value || '0',
        period: {
          key: deliveryInstance?.delay?.period?.key || 'minute',
          name: deliveryInstance?.delay?.period?.name || 'Minutes',
        },
      },
    });
  }

  return (
    <>
     {(context.deliveryData.trigger === 'triggered' || context.deliveryData.trigger === 'reminder') && (
        <div className="push-delivery">
          <div className="push-delivery__heading">{context.deliveryData.frequencyTitle}</div>
          <div className="push-delivery__description">{context.deliveryData.frequencyDescr}</div>
          <div className="push-delivery__inline">
            {context.deliveryData.frequencyString[0]!!}
            {context.deliveryData.trigger === 'reminder' && (
              <FlightNumberInput
                className="push-delivery__number"
                placeholderText={'1'}
                width={'70px'}
                maxValue={999}
                minValue={1}
                hasError={false}
                errorMessage={''}
                onChange={(e) =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    frequency: {
                      ...context.deliveryData.frequency,
                      total: e.target.value,
                    },
                  })
                }
                disabled={false}
                value={context.deliveryData.frequency?.total}
              />
            )}
            {context.deliveryData.frequencyString[1]!!}
            <FlightNumberInput
              className="push-delivery__number"
              placeholderText={'1'}
              width={'70px'}
              maxValue={999}
              minValue={1}
              hasError={false}
              errorMessage={''}
              onChange={(e) =>
                context.setDeliveryData({
                  ...context.deliveryData,
                  frequency: {
                    ...context.deliveryData.frequency,
                    value: e.target.value,
                  },
                })
              }
              disabled={false}
              value={context.deliveryData.frequency.value}
            />
            <FlightSelect
              label=""
              options={tail(periods)}
              selected={context.deliveryData.frequency.period}
              hasLabelAnimation={true}
              handleOptionClick={(option) =>
                context.setDeliveryData({
                  ...context.deliveryData,
                  frequency: {
                    ...context.deliveryData.frequency,
                    period: option,
                  },
                })
              }
              dropdownMaxHeight={'200px'}
              disabled={false}
              className="push-delivery__select"
              dropdownDirection={'bottom'}
            />
            {context.deliveryData.frequencyString[2]!!}
          </div>
          <div className="push-delivery__inline">
            {context.deliveryData.trigger === 'triggered' && (
              <FlightCheckbox
                checkState={context.deliveryData.limitCheck}
                onSelect={() =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    limitCheck: context.deliveryData.limitCheck === 'SELECTED' ? 'UNSELECTED' : 'SELECTED',
                    limit: {
                      ...context.deliveryData.limit,
                      key: context.deliveryData.limitCheck === 'SELECTED' ? '' : '1',
                    },
                  })
                }
                disabled={false}
                className="push-delivery__checkbox"
              />
            )}
            {context.deliveryData.limitString[0]!!}
            {context.deliveryData.trigger === 'reminder' && (
              <FlightSelect
                label=""
                options={limits}
                selected={context?.deliveryData?.limit || { key: '', name: 'Unlimited'}}
                hasLabelAnimation={true}
                handleOptionClick={(option) =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    limit: option,
                  })
                }
                dropdownMaxHeight={'200px'}
                disabled={false}
                className="push-delivery__select push-delivery__limit-selector"
                dropdownDirection={'bottom'}
              />
            )}
            {context.deliveryData.trigger === 'triggered' && (
              <FlightNumberInput
                className="push-delivery__limit"
                placeholderText={'1'}
                width={'70px'}
                maxValue={999}
                minValue={1}
                hasError={false}
                errorMessage={''}
                onChange={(e) =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    limit: {
                      ...context.deliveryData.limit,
                      key: e.target.value,
                    },
                  })
                }
                disabled={context.deliveryData.limitCheck === 'UNSELECTED'}
                value={context.deliveryData.limit.key}
              />
            )}
            {context.deliveryData.limitString[1]!!}
            {context.deliveryData.trigger === 'triggered' && (
              <FlightTooltip
                direction="top"
                isEnabled={true}
                description={'By default, the system will allow unlimited number of messages to be sent per user. ' +
                'You can limit that by checking this box and specifying the maximum number allowed.'}
                delay={0}
              >
                <img className="push-delivery__question-icon" src={QuestionIcon} alt="Question Acon"/>
              </FlightTooltip>
            )}
          </div>
          {context.deliveryData.trigger === 'triggered' && (
            <div className="push-delivery__inline push-delivery__subline">
              <FlightCheckbox
                checkState={context.deliveryData.remain}
                onSelect={() =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    remain: context.deliveryData.remain === 'SELECTED' ? 'UNSELECTED' : 'SELECTED',
                  })
                }
                disabled={false}
                className="push-delivery__checkbox"
              />
              <div>Include users who remain in the audience </div>
              <FlightTooltip
                direction="top"
                isEnabled={true}
                description={'Trigger a notification for users who remain part of the target audience even if they have already triggered it before.'}
                delay={0}
              >
                <img className="push-delivery__question-icon" src={QuestionIcon} alt="Question Acon"/>
              </FlightTooltip>
            </div>
          )}
          <div>
            <div className="push-delivery__heading">Notification delay</div>
            <div className="push-delivery__inline">
              <FlightCheckbox
                checkState={context.deliveryData.delay?.selected}
                onSelect={() =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    delay: {
                      ...context.deliveryData.delay,
                      selected: context.deliveryData.delay?.selected === 'SELECTED' ? 'UNSELECTED' : 'SELECTED',
                      value: context.deliveryData.delay?.selected === 'SELECTED' ? '0' : '1',
                    },
                  })
                }
                disabled={false}
                className="push-delivery__checkbox"
              />
              {context.deliveryData.delayString[0]!!}
              <FlightNumberInput
                className="push-delivery__number"
                placeholderText={'1'}
                width={'70px'}
                maxValue={999}
                minValue={1}
                hasError={false}
                errorMessage={''}
                onChange={(e) =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    delay: {
                      ...context.deliveryData.delay,
                      value: e.target.value,
                    },
                  })
                }
                disabled={context.deliveryData.delay?.selected === 'UNSELECTED'}
                value={context.deliveryData.delay.value}
              />
              <FlightSelect
                label=""
                options={periods}
                selected={context.deliveryData.delay?.period}
                hasLabelAnimation={true}
                handleOptionClick={(option) =>
                  context.setDeliveryData({
                    ...context.deliveryData,
                    delay: {
                      ...context.deliveryData.delay,
                      period: option,
                    },
                  })
                }
                dropdownMaxHeight={'200px'}
                disabled={false}
                className="push-delivery__delay"
                dropdownDirection={'bottom'}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
