import _axios from 'axios';
import Session from 'services/session';
import Storage from 'services/storage';

const session = new Session();
const storage = new Storage();

const http = _axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

async function redirect() {
  const origin = await storage.getItem('origin');
  if (origin) {
    window.location.href = origin as string;
    return;
  } else {
    window.location.href = process.env.REACT_APP_SAFE_ROUTE as string;
  }
}

http.interceptors.request.use(
  async function (config) {
    const token = await session.getToken();
    if (token) {
      config.headers['X-Authorization'] = token;
      config.headers['X-User-Agent'] = `{deviceType: "Desktop", make:"Chrome", model: "58.0.3006.0", osVersion: "macOS 10.12.3", physicalDeviceId: "PHYSICAL-DEVICE-ID", sdkVersion:"1.0.0"}`
    } else {
      await session.unauthorizeUser();
      await redirect();
    }
    return config;
  },
  function (error) {
    throw error; // use throw to use handle error in "catch" block instead of "then" block.
  }
);

http.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      await session.unauthorizeUser();
      await redirect();
    }
    throw error; // use throw to use handle error in "catch" block instead of "then" block.
  }
);

export default http;
