import React from 'react';

import { Action } from 'interface/experience/experience.interface';

import EditModal from 'components/Modal/EditModal/EditModal';
import PreviewPush from 'components/PushMessage/PreviewPush/PreviewPush';

import PushFormProvider, { PushFormContext } from 'components/PushMessage/PushFormProvider/PushFormProvider';
import PushEdit from 'components/PushMessage/PushEdit/PushEdit';

interface IProps {
  action: Action;
  isVisible: boolean;
  triggerContentModal?: (form: any, type: any) => void;
  amplified?: boolean;
  onApply: (form: any, type: any) => void;
  onCancel: () => void;
}


 
export default function PushEditModal(props: IProps) {
  return (
    <PushFormProvider action={props.action} amplified={props.amplified}>
      <PushFormContext.Consumer>
        {(context: any) => (
          <EditModal
            size="large"
            icon="push.svg"
            title="Configure push notification"
            isVisible={props.isVisible}
            primaryButton={{
              name: `${                
                (!props.amplified && context?.selectedNotificationType?.key === 'content') ? 'Next - Select in-app content' : 'Apply'
              }`,
              onClick: context.validateForm(
                (data: any, type: any) =>
                  `${
                    (!props.amplified && context?.selectedNotificationType?.key === 'content')
                      ? props?.triggerContentModal && props.triggerContentModal(data, type)
                      : props.onApply(data, type)
                  }`,
              ),
              disabled: false,
            }}
            cancelButton={{
              name: 'Cancel',
              onClick: props.onCancel,
            }}
          >
            <PushEdit />
            <PreviewPush
              title={context.formFields.title?.[context.selectedLang]}
              message={context.formFields.alert?.[context.selectedLang]}
            />
          </EditModal>
        )}
      </PushFormContext.Consumer>
    </PushFormProvider>
  );
}
