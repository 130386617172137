import { v4 as uuidv4 } from 'uuid';
import { TExperience, TExperienceInstance } from "interface/templated-experience/templated-experience.interface";
import momentTZ from "moment-timezone";
import moment from 'moment';
import pluralize from 'pluralize';

import {scheduleDataType} from "components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal";
import {ExperienceSchedule, PushDelivery} from "interface/experience/experience.interface";
import { TExperienceState } from 'redux/templated-experience/templated-experience.type';
import { upperFirst } from 'lodash';
import { triggerOptions } from 'components/Modal/EditModal/TriggerEditModal/TriggerEditModal';

/**
 * Returns the index of steps array, where actionID is present in actions array of this step.
 * @param template template object in current reducer
 * @param actionID actionID of the card/box
 * @return [parent, child] returns parent and child, where parent is the index in steps array,
 * and child is the index in actionBody array.
 */
export function findActionParent(template: TExperience, actionID: string): [number, number]{
  for (var s = 0; s < template.steps?.length; s++) {
    let currentStep = template.steps[s];
    for (var a = 0; a < currentStep.actions?.length; a++) {
      if (currentStep.actions[a].actionID === actionID) {
        return [s, a];
      }
    }
  }
  return [0, 0];
}

// Convert the JSON response received from GET to a compatible object ready for POST request
export function convertToInstance(template: TExperience): TExperienceInstance {
  delete template.updatedAt;
  delete template.createdAt;
  return {
    ...template,
    name: '',
    status: '',
    schedule: {
      start: 0,
      end: 0,
      timezone: '',
    },
    incompleteCount: 0,
    showError: false,
  }
}

/**
 * Generates UUID for a single step templated experience creation.
 * This function should be updated to meet the critera for nested steps in the future.
 * @param template
 */
export function generateUUID(template: TExperienceInstance): TExperienceInstance {
  template.rootStepID = uuidv4().toUpperCase();
  const rootStep = template.steps[0];
  rootStep.id = template.rootStepID;
  for (var i = 0; i < rootStep.actions.length; i++) {
    rootStep.actions[i].id = uuidv4().toUpperCase();
  }
  return template;
}

/**
 *
 * @param action The push action step
 * @returns the payload object that is located inside the actionBody of step.
 */
export function getPushPayload(action?: any) {
  if (action?.actionBody?.contentIds) {
    return action?.actionBody;
  } else {
    return action?.actionBody?.body[0]?.payload;
  }
}

/**
 * Returns the push type for the PushBox component
 * @returns the push type string.
 * @param action
 */
export function getPushType(action: any) {
  const payload = getPushPayload(action);
  if (payload?.data?.url !== undefined) {
    return 'weblink';
  } else if (payload?.data?.contentId !== undefined) {
    return 'content';
  } else if (payload?.data?.pushPayloadTypeId) {
    return `${payload?.data?.pushPayloadTypeId}`;
  } else {
    return 'basic';
  }
}

/**
 * Returns the appropriate journeyMode that should be set given restriction and preferred rules
 * @returns the journeyMode string.
 * @param journeyMode
 */
export function getJourneyMode(instance: any) {
  if (instance.steps[0]?.seedRuleStringRepresentation) {
    return 'exploration'
  } else {
    return 'manual';
  }
}

/**
 * Returns the style for the experience label, based on the status property
 * @returns the style name
 * @param status
 */
export function defineStatusStyle(status: string) {
  if (status === 'Active') return 'success';
  if (status === 'Inactive') return 'error';
  if (status === 'Draft') return 'default';
  if (status === 'Scheduled') return 'info';
  return 'default';
}

/**
 * Returns updated instance with a new status
 * @returns updated instance object
 * @param instances
 * @param id
 * @param status
 */
export function updateTemplatedInstanceStatus(instances: any, id: string, status: string) {
  return instances.data.map((instance: TExperienceInstance) => {
    if (instance.id === id) {
      instance.status = status;
    }
    return instance;
  })
}

/**
 * Returns updated instance with Untitled content name if the content name is empty
 * @returns updated instance object
 * @param instance
 * @param name
 */
export function updateEmptyContentName(instance: TExperienceInstance, name: string = 'Untitled') {
  if(instance.steps.length) {
    instance.steps.map(step => {
      if(step.actions.length) {
        step.actions.map(action => {
          if(action.actionType === 'experience') {
            if(action.actionBody?.localizations?.en?.name === ''){
              return action.actionBody.localizations.en.name = name;
            }
          }
          return action;
        })
      }
      return step;
    })
  }
  return instance;
}


/**
 * Returns the list of timezones from the 'moment' library
 * @returns array
 */
export function getTimezoneList(searchZone: string = '') {
  let timezones = momentTZ.tz.names().map((timezone:any, idx: number) => {
    return {
      key: idx+1,
      name: timezone.replace(/_/g, ' ')+' (UTC '+momentTZ.tz(timezone).format('Z')+')',
      offset: parseInt(momentTZ.tz(timezone).format('Z')),
    };
  });

  if(searchZone) {
    timezones = timezones.filter((timezone) => {
      return timezone.name.toLowerCase().search(
        searchZone.toLowerCase().replace(/[_)(+]/g, ' ')
      ) >= 0;
    })
  }

  return timezones.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
}


/**
 * Returns timezone name without UTC data
 * @returns string
 */
export function cleanupTimezone(timezone: string) {
  const start = timezone?.indexOf(' (UTC');
  if(start > -1) {
    timezone = timezone?.slice(0, start);
  }
  timezone = timezone?.replace(/ /, '_');
  return timezone;
}


/**
 * Returns date and time, converted to timezone + user's local time
 * @returns object
 */
export function convertDateToTimezone(date: number, timezone: string) {
  let dateFormatted = moment(date*1000).format('YYYY-MM-DD HH:mm');
  let selZoneTime = momentTZ.tz(dateFormatted, cleanupTimezone(timezone!!));
  let localZoneTime = selZoneTime.clone().tz(moment.tz.guess());
  let dateTZFormatted = momentTZ.tz(date*1000, cleanupTimezone(timezone!!)).format('YYYY-MM-DD HH:mm');
  let localZoneUnixTime = moment(dateTZFormatted).unix();
  return {
    tz_date: selZoneTime.format('MMM D, YYYY'),
    tz_time: selZoneTime.format('hh:mmA'),
    tz_unix: localZoneUnixTime,
    local_date: localZoneTime.format('MMM D, YYYY'),
    local_time: localZoneTime.format('hh:mmA'),
    local_unix: localZoneTime.unix()
  };
}


/**
 * Returns date and time, converted to timezone + user's local time
 * @returns object
 */
export function convertTimezoneToDate(date: number, timezone: string) {
  let dateFormatted = moment(date!! * 1000).format('YYYY-MM-DD HH:mm:ss');
  let localZoneTime = momentTZ.tz(dateFormatted, moment.tz.guess());
  let selZoneTime = timezone ? localZoneTime.clone().tz(cleanupTimezone(timezone)) : null;
  let dateTZFormatted = momentTZ.tz(date!!*1000, cleanupTimezone(timezone)).format('YYYY-MM-DD HH:mm:ss');
  let localZoneUnixTime = moment(dateTZFormatted).unix();

  return {
    tz_date: selZoneTime ? selZoneTime.format('MMM D, YYYY') : '',
    tz_time: selZoneTime ? selZoneTime.format('hh:mmA') : '',
    tz_unix: localZoneUnixTime,
    local_date: localZoneTime.format('MMM D, YYYY'),
    local_time: localZoneTime.format('hh:mmA'),
    local_unix: localZoneTime.unix()
  };
}

/**
 * Returns transformed schedule data, to save in state
 * @returns object
 */
export function transformScheduleData(scheduleData: scheduleDataType) {
  const start = moment(scheduleData.start || 0).unix();
  const end = moment(scheduleData.end || 0).unix();

  let schedule: any = {
    start: scheduleData.start ? start : 0,
    end: scheduleData.end ? end : 0,
    timezone: scheduleData.timezone.name,
  };

  return schedule;
}

/**
 * Returns serialized schedule data, that match the backend requirements
 * @returns object
 */
export function serializeScheduleData(scheduleData: TExperienceInstance['schedule']) {
  const cleanTimezone = cleanupTimezone(scheduleData.timezone!!);
  const start = moment(scheduleData.start!! * 1000 || 0).format("YYYY-MM-DD HH:mm:ss");
  const end = moment(scheduleData.end!! * 1000 || 0).format("YYYY-MM-DD HH:mm:ss");

  const convertedStartTime = momentTZ.tz(start, cleanTimezone).unix();
  const convertedEndTime = momentTZ.tz(end, cleanTimezone).unix();

  let schedule:ExperienceSchedule = {
    start: scheduleData.start ? convertedStartTime : 0,
    end: scheduleData.end ? convertedEndTime : 0,
    timezone: scheduleData.timezone,
  };

  return schedule;
}

/**
 * Returns serialized activation data, that match the backend requirements
 * @returns object
 */
export function serializeActivationData (activationData: TExperienceInstance['activation'], scheduleData: TExperienceInstance['schedule']) {
  let cleanTimezone = cleanupTimezone(scheduleData.timezone!!) || moment.tz.guess();
  let automaticActivationAt = moment(activationData?.automaticActivationAt!! * 1000 || 0).format("YYYY-MM-DD HH:mm:ss");
  let automaticDeactivationAt = moment(activationData?.automaticDeactivationAt!! * 1000 || 0).format("YYYY-MM-DD HH:mm:ss");
  let manualActivationAt = moment(activationData?.manualActivationAt!! * 1000 || 0).format("YYYY-MM-DD HH:mm:ss");
  let manualDeactivationAt = moment(activationData?.manualDeactivationAt!! * 1000 || 0).format("YYYY-MM-DD HH:mm:ss");

  const convertedAutomaticActivationAt = momentTZ.tz(automaticActivationAt, cleanTimezone).unix();
  const convertedAutomaticDeactivationAt = momentTZ.tz(automaticDeactivationAt, cleanTimezone).unix();
  const convertedManualActivationAt = momentTZ.tz(manualActivationAt, cleanTimezone).unix();
  const convertedManualDeactivationAt = momentTZ.tz(manualDeactivationAt, cleanTimezone).unix();

  return {
    automaticActivationAt: activationData?.automaticActivationAt!! ? convertedAutomaticActivationAt : 0,
    automaticDeactivationAt: activationData?.automaticDeactivationAt!! ? convertedAutomaticDeactivationAt : 0,
    manualActivationAt: activationData?.manualActivationAt!! ? convertedManualActivationAt : 0,
    manualDeactivationAt: activationData?.manualDeactivationAt!! ? convertedManualDeactivationAt : 0,
  };
}


/**
 * Returns serialized trigger data, that match the backend requirements
 * @returns object
 */
export function serializeTriggerData(steps: TExperienceInstance['steps']) {
  return steps.map((step: any) => {
    step.trigger.repeats = step.trigger.repeats?.map((repeat: any) => {
      repeat.weekday = repeat.weekday.key;
      repeat.from = moment(repeat.from).format('hh:mmA');
      repeat.to = moment(repeat.to).format('hh:mmA');
      return repeat;
    }) || [];
    step.trigger.timezone = cleanupTimezone(step.trigger?.timezone?.name!!) || '';
    step.trigger.key = step.trigger?.trigger?.key!! || triggerOptions[0].key;
    step.trigger.context = {
      value: convertPeriodToSeconds((step.trigger?.context?.value!! || 0).toString(), step.trigger?.context?.period?.key!!),
      optionId: step.trigger?.context?.plugin?.key!! || '',
    };
    delete step.trigger.dayparting;
    delete step.trigger.trigger;

    return {
      ...step,
      trigger: step.trigger
    }
  });
}


/**
 * Returns deserialized trigger data, that match the frontend requirements
 * @returns object
 */
export function deserializeTriggerData(steps: TExperienceInstance['steps']) {
  return steps.map((step: any) => {
    step.trigger = {
      repeats: step.trigger.repeats?.map((repeat: any) => {
        repeat.weekday = {
          key: repeat.weekday,
          name: upperFirst(repeat.weekday)
        };
        repeat.from = moment(repeat.from, 'HH:mmA').toDate();
        repeat.to = moment(repeat.to, 'HH:mmA').toDate();
        return repeat;
      }) || [],
      dayparting: step.trigger?.repeats?.length ? 'SELECTED' : 'UNSELECTED',
      timezone: step.trigger?.timezone ? getTimezoneList(step.trigger?.timezone) : '',
      trigger: step.trigger?.key ? triggerOptions.find((t:any) => t.key === step.trigger?.key) : null,
      context: {
          value: covertSecondsToPeriod(step.trigger?.context?.value!! || 0)?.value!!.toString(),
          period: {
            key: covertSecondsToPeriod(step.trigger?.context?.value!! || 0)?.period!! || '',
            name: upperFirst(covertSecondsToPeriod(step.trigger?.context?.value!! || 0)?.period!! || '')
          },
          plugin: {
            key: step.trigger?.context?.optionId || '',
            name: upperFirst(step.trigger?.context?.optionId) || '',
          }
      },
      type: step.trigger?.type,
    };

    return {
      ...step,
      trigger: step.trigger
    }
  });
}


/**
 * Returns deserialized schedule data, that match the frontend requirements
 * @returns object
 */
export function deserializeScheduleData(scheduleData: TExperienceInstance['schedule']) {
  const convertedStartTime = convertTimezoneToDate(scheduleData?.start!! || 0, scheduleData?.timezone!!);
  const convertedEndTime = convertTimezoneToDate(scheduleData?.end!! || 0, scheduleData?.timezone!!);

  return {
    start: scheduleData.start ? convertedStartTime.tz_unix : 0,
    end: scheduleData.end ? convertedEndTime.tz_unix : 0,
    timezone: scheduleData.timezone,
  };
}

/**
 * Returns deserialized activation data, that match the frontend requirements
 * @returns object
 */
export function deserializeActivationData(activationData: TExperienceInstance['activation'], scheduleData: TExperienceInstance['schedule']) {
  let automaticActivationAt = convertTimezoneToDate(activationData?.automaticActivationAt!! || 0, scheduleData.timezone!!);
  let automaticDeactivationAt = convertTimezoneToDate(activationData?.automaticDeactivationAt!! || 0, scheduleData.timezone!!);
  let manualActivationAt = convertTimezoneToDate(activationData?.manualActivationAt!! || 0, scheduleData.timezone!!);
  let manualDeactivationAt = convertTimezoneToDate(activationData?.manualDeactivationAt!! || 0, scheduleData.timezone!!);

  return {
    automaticActivationAt: activationData?.automaticActivationAt!! ? automaticActivationAt?.local_unix!! : 0,
    automaticDeactivationAt: activationData?.automaticDeactivationAt!! ? automaticDeactivationAt?.local_unix!! : 0,
    manualActivationAt: activationData?.manualActivationAt!! ? manualActivationAt?.local_unix!! : 0,
    manualDeactivationAt: activationData?.manualDeactivationAt!! ? manualDeactivationAt?.local_unix!! : 0,
  };
}


/**
 * Returns difference between two dates
 * @returns string
 */
const PERIODS = ["months", "weeks", "days", "hours", "minutes", "seconds"];
interface funcType {
  start:Date,
  end:Date,
  period: string
}
export function getDuration(start:Date, end:Date, period:any = "days"):funcType|string {
  const validForUnit = moment(end).diff(start, period);
  if (validForUnit > 1 || period === "seconds") {
    return `${validForUnit} ${period}`;
  }
  return getDuration(start, end, PERIODS[PERIODS.indexOf(period) + 1]);
}


/**
 * Returns the object with warning variables on launching scheduled experience
 * @returns object
 */
export function getWarningOnLaunchScheduled(schedule: TExperienceInstance['schedule']) {
  const startDate = moment((schedule.start || 0) *1000).format('MMM DD, YYYY [at] h:mmA');
  const endDate = schedule.end ? moment((schedule.end || 0)*1000).format('MMM DD, YYYY [at] h:mmA') : 'No end date';
  let newTitle = '';
  let newMessage = '';
  if(schedule.start!!) {
    newTitle = 'Launch on Date';
    newMessage = 'This experience has a schedule associated with it. '+
        'As a result, it will launch with the following dates:';
  }
  return ({startDate, endDate, newTitle, newMessage,})
}

/**
 * Returns the start date in unix
 * @returns number
 */

export function defineStartDate(scheduledStart:number, autoActivation: number, manualActivation:number, status: string) {
  let startDate = 0;
  if(scheduledStart > startDate) {
    startDate = scheduledStart;
  }
  if(autoActivation > startDate && status !== 'Active') {
    startDate = autoActivation;
  }
  if(manualActivation > startDate && status !== 'Active') {
    startDate = manualActivation;
  }

  return startDate;
}

/**
 * Returns serialized push delivery data, that match the backend requirements
 * @returns object
 */
export function serializeDelivery(delivery: TExperienceState['temp']['delivery']) {
  return {
    frequency: {
      total: parseInt(delivery.frequency?.total) || 0,
      value: parseInt(delivery.frequency?.value) || 0,
      period: delivery.frequency?.period?.key,
    },
    limit: parseInt(delivery.limit?.key) || 0,
    remain: delivery.remain === 'SELECTED',
    delay: convertPeriodToSeconds(
      delivery.delay?.selected === 'SELECTED' && delivery.delay?.value ? delivery.delay.value : '',
      delivery.delay?.selected === 'SELECTED' && delivery.delay?.period?.key ? delivery.delay.period.key : ''
    )
  }
}


/**
 * Returns deserialized push delivery data, that match the frontend requirements
 * @returns object
 */
export function deserializeDelivery(delivery: PushDelivery) {
  let convertedDelay = covertSecondsToPeriod(delivery?.delay);
  return {
    frequency: {
      total: delivery?.frequency?.total?.toString(),
      value: delivery?.frequency?.value?.toString(),
      period: {
        key: delivery?.frequency?.period,
        name: upperFirst(delivery?.frequency?.period),
      },
    },
    limitCheck: delivery?.limit > 0 ? 'SELECTED' : 'UNSELECTED',
    limit: {
      key: delivery?.limit ? delivery?.limit?.toString() : '',
      name: delivery?.limit === 0 ? 'Unlimited' :
        delivery?.limit === 1 ? 'One' : ''
    },
    remain: delivery?.remain ? 'SELECTED' : 'UNSELECTED',
    delay: {
      selected: convertedDelay.value ? 'SELECTED' : 'UNSELECTED',
      value: convertedDelay.value?.toString(),
      period: {
        key: convertedDelay.period,
        name: upperFirst(convertedDelay.period),
      },
    },
  }
}

/**
 * Returns number of seconds in period
 * @returns number
 */

export function convertPeriodToSeconds(value: string, period: string) {

  let periodValue = parseInt(value);
  let time = periodValue || 0;

  if (!period) return time;
  period = pluralize(period);

  const secondsInMinute = moment.duration(1, 'minutes').asSeconds();
  const secondsInHour = moment.duration(1, 'hours').asSeconds();
  const secondsInDay = moment.duration(1, 'days').asSeconds();
  const secondsInWeek = moment.duration(1, 'weeks').asSeconds();
  const secondsInMonth = moment.duration(1, 'months').asSeconds();
  const secondsInYear = moment.duration(1, 'years').asSeconds();

  if (period === 'minutes') {
    time = periodValue * secondsInMinute;
  }
  if (period === 'hours') {
    time = periodValue * secondsInHour;
  }
  if (period === 'days') {
    time = periodValue * secondsInDay;
  }
  if (period === 'weeks') {
    time = periodValue * secondsInWeek;
  }
  if (period === 'months') {
    time = periodValue * secondsInMonth;
  }
  if (period === 'years') {
    time = periodValue * secondsInYear;
  }

  return time;
}


/**
 * Returns period from seconds
 * @returns object
 */

export function covertSecondsToPeriod(seconds: number) {

  const secondsInDay = moment.duration(1, 'days').asSeconds();
  const secondsInHour = moment.duration(1, 'hours').asSeconds();
  const secondsInMinute = moment.duration(1, 'minutes').asSeconds();
  const secondsInMonth = moment.duration(1, 'months').asSeconds();
  const secondsInWeek = moment.duration(1, 'weeks').asSeconds();
  const secondsInYear = moment.duration(1, 'years').asSeconds();

  let period = null;
  let value = 0;

  // In years
  if (!period && seconds >= secondsInYear) {
    value = parseFloat((seconds / secondsInYear).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'years';
    }
  }

  // In months
  if (!period && seconds >= secondsInMonth) {
    value = parseFloat((seconds / secondsInMonth).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'months';
    }
  }

  // In weeks
  if (!period && seconds >= secondsInWeek) {
    value = parseFloat((seconds / secondsInWeek).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'weeks';
    }
  }

  // In days
  if (!period && seconds >= secondsInDay) {
    value = parseFloat((seconds / secondsInDay).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'days';
    }
  }

  // In hours
  if (!period && seconds >= secondsInHour) {
    value = parseFloat((seconds / secondsInHour).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'hours';
    }
  }

  // In minutes
  if (!period && seconds >= secondsInMinute) {
    value = parseFloat((seconds / secondsInMinute).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'minutes';
    }
  }

  if (value % 1 !== 0 && (value - 0.1) % 1 === 0) {
    value = Math.round(value);
  }

  if (!period) {
    period = 'seconds';
    value = seconds;
  }

  return { value, period };
}

/**
 * Returns user's timezone data
 * @returns object
 */
export function getMyTimezone() {
  let myZone = moment.tz.guess().replace(/_/g, ' ');
  let myOffset = moment.tz(myZone).format('Z');
  myZone = myZone+' (UTC '+myOffset+')';
  return {
    key: -1,
    name: myZone,
    offset: myOffset
  }
}
