import React, { useState, useEffect } from 'react';
import Labels from 'components/Shared/Labels/Labels';
import { FlightTextInput, FlightTextArea, FlightCheckbox, FlightTooltip } from '@flybits/webapp-design-system-react';
import QuestionIcon from 'assets/icons/question.svg';

import './ContentForm.scss';

interface FormState {
  title: string;
  description: string;
  labels: Array<string>;
  isContextLocked: boolean;
}

interface ValidationSchema {
  title: boolean;
  description: boolean;
}

interface IProps {
  initialFormValues?: any;
  formValues: (formData: any) => void;
  content: any;
  isValid: (valid: boolean) => void;
}

export default function ContentForm(props: IProps) {
  const [disableSave, setDisableSave] = useState(true);
  const [labels, setLabels] = useState<Array<string>>(props?.content?.labels);
  const [form, setForm] = useState<FormState>({ title: '', description: '', labels: [], isContextLocked: false });
  const [checkbox, setCheckbox] = useState<string | 'SELECTED' | 'UNSELECTED'>('UNSELECTED');
  const CONTEXT_LOCKED_MSG =
    "Only users who fulfill a Context Rule's conditions will be able to see this content (in an Experience)";

  const [required, setRequired] = React.useState<ValidationSchema>({
    title: false,
    description: false,
  });

  const getLabels = (_labels: string[]) => {
    setLabels(_labels);
    setForm({ ...form, labels: _labels });
  };

  const handleChange = (name: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequired({ ...required, [name]: false });
    setForm({ ...form, [name]: event.target.value });
  };

  const handleCheckbox = () => {
    if (checkbox === 'SELECTED') {
      setCheckbox('UNSELECTED');
    } else {
      setCheckbox('SELECTED');
    }
  };

  function handleBlur(name: keyof FormState) {
    if (form[name] === '' && name === 'title') {
      setRequired({ ...required, [name]: true });
      setDisableSave(true);
    } else if (form[name] !== '') {
      setRequired({ ...required, [name]: false });
      setDisableSave(false);
    }
  }

  useEffect(() => {
    props.formValues(form);
    handleBlur('title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    setForm({ ...form, isContextLocked: checkbox === 'SELECTED' ? true : false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkbox]);

  useEffect(() => {
    setForm({
      ...form,
      title: props.content.localizations.en.name,
      description: props.content.localizations.en.description,
      labels: labels,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.isValid(disableSave);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableSave]);

  return (
    <>
      <div className="content-form__content">
        <FlightTextInput
          className="content-form__content__input"
          label="Title"
          type="text"
          hasError={required.title}
          errorMessage={'Required.'}
          placeholderText="Title"
          width="367px"
          value={form.title}
          onChange={handleChange('title')}
          onBlur={() => handleBlur('title')}
        />
        <FlightTextArea
          className="content-form__content__input"
          label="Description"
          hasError={required.description}
          errorMessage={'Required.'}
          hideCharCounter
          width="367px"
          value={form.description}
          onChange={handleChange('description')}
          onBlur={() => handleBlur('description')}
        />
        <Labels initialValues={labels} returnValues={getLabels} fieldWidth={'367px'} />
        <div className="content-form__content__footer">
          <FlightCheckbox
            className="content-form__content__footer__checkbox"
            label="Context-Locked"
            checkState={checkbox}
            onSelect={handleCheckbox}
          />{' '}
          <FlightTooltip direction="right" description={CONTEXT_LOCKED_MSG}>
            <img src={QuestionIcon} alt="QuestionIcon" width="30" className="content-form__content__footer__icon" />{' '}
          </FlightTooltip>
        </div>
      </div>
    </>
  );
}
