import React, { useContext, useEffect, useState } from 'react';
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import LocationPreview from 'components/AudiencePreview/LocationPreview/LocationPreview';
import AudienceIcon from 'assets/icons/audience.svg';
import { stringRepFinal } from 'helpers/rule.helper';
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';

interface IProps {
  type: 'restriction' | 'preferred';
  onClick: () => void;
}

const row = {
  display: 'flex',
};

export default function Audience(props: IProps) {
  const [ruleData, setRuleData] = useState<any>(undefined);
  const multiStepContext: any = useContext(MultiStepContext);

  const { ruleStringRepresentation, seedRuleStringRepresentation } = multiStepContext.template.entry.audience;

  const texts: any = {
    restriction: {
      and: 'All of the following restrictions define your audience reach',
      or: 'Any of the following restrictions define your audience reach',
    },
    preferred: {
      and: 'All of the following conditions define your preferred audience',
      or: 'Any of the following conditions define your preferred audience',
    },
  };

  useEffect(() => {
    if (props.type === 'restriction') {
      stringRepFinal(ruleStringRepresentation, multiStepContext.template.entry.audience).then((r) => setRuleData(r));
    } else {
      stringRepFinal(seedRuleStringRepresentation, multiStepContext.template.entry.audience).then((r) => setRuleData(r));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleStringRepresentation, seedRuleStringRepresentation, multiStepContext]);

  function audienceRowType(audienceObj: any) {
    switch (audienceObj.type) {
      case 'location:map':
        return (
          <>
            {' '}
            User is in Location &nbsp; <LocationPreview location={audienceObj.location} />{' '}
          </>
        );
      case 'location:label':
        return <> User is in a location with the label {'"' + audienceObj.label + '"'}</>;
      case 'general':
        return (
          <>
            {' '}
            {audienceObj.arguments[0]} {audienceObj.predicate} &nbsp; <b> {audienceObj.arguments[1]} </b>{' '}
          </> // plugin
        );
      default:
        break;
    }
  }

  function audienceRow(stringObj: any, idx: number = 1) {
    return (
      <div key={`stringRep_${idx}`} style={row}>
        <div className="">
          <b> {idx + 1}. &nbsp;</b>
        </div>
        <div className="">
          {Array.isArray(stringObj) ? (
            <> {stringObj[0]} </> // rule
          ) : (
            audienceRowType(stringObj)
          )}
        </div>
      </div>
    );
  }

  function renderBody() {
    const audience = ruleData.predicates.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    if (ruleData.predicates.length > 0) {
      return (
        <>
          <div>
            {ruleData.type === 'custom' ? (
              <i> Restrictions {ruleData.toString} defines your audience reach. </i>
            ) : (
              <p> {texts[props.type][ruleData.type]} </p>
            )}
          </div>
          <div style={{ paddingTop: '16px' }}>{audience}</div>
          <div style={{ paddingTop: '16px' }}>{props.type === 'restriction' ? 'Qualified Audience:' : 'Estimated Audience Engagement:'}</div>
          <div style={{ paddingTop: '6px', fontWeight: 'bold' }}>{props.type === 'restriction' ? '2,300 customers' : '1,536 customers'}</div>
        </>
      );
    } else {
      return <div> None Set </div>;
    }
  }

  if (!ruleData) return null;
  return (
    <MiniBox
      {...props}
      icon={AudienceIcon}
      title={props.type === 'restriction' ? 'Entry Audience' : 'Participation Audience'}
      body={renderBody()}
    />
  );
}
