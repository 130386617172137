import React, { useState, useEffect } from 'react';

import './StarterContentPreview.scss';
import ContentPreviewFrame from 'assets/images/Content-Preview-Frame.svg';
import RightArrow from 'assets/icons/right-arrow.svg';
import LeftArrow from 'assets/icons/left-arrow.svg';

import { FlightTooltip } from '@flybits/webapp-design-system-react';

import UnionSvg from 'assets/icons/imageplacholder.svg';

import { ContentPreview } from 'interface/content/content.interface';
import { CircularProgress } from '@material-ui/core';

import parse from 'html-react-parser';
import { decodeURIComponentSafe } from 'helpers/safe.decodeuri.helper';

const renderOnModal: React.CSSProperties = { backgroundImage: `url(${ContentPreviewFrame})` };
const renderOnPage: React.CSSProperties = {
  backgroundImage: `url(${ContentPreviewFrame})`,
  position: 'static',
  marginLeft: 35,
  visibility: 'visible',
};

const viewFirstCard = '1/2 - Card';
const viewSecondCard = '2/2 - In-app page (Card opened)';

//tooltip messages
const HEADER_IMG_TOOL_TIP_TEXT =
  "Attract customers' attention with an image, recommended file size <5mb, image ratio 16:9 *a larger image will lead to automatic cropping.";
const HEADER_TOOL_TIP_TEXT = 'Header can be used to organize content.';
const TITLE_TOOL_TIP_TEXT = 'Recommended: 80 characters.';
const BODY_TOOL_TIP_TEXT = 'Recommended: 160 characters.';
const CTA_TOOL_TIP_TEXT = 'Clear action label will help consumers know where the action will take them to.';

const DetailContent = (props: any) => {
  return (
    <div className="content-modal-preview__preview-body__detailed-content">
      <div className="content-modal-preview__preview-body__detailed-content__content">
        <div className="content-modal-preview__preview-body__detailed-content__content__header">{props.header}</div>
        <div className="content-modal-preview__preview-body__detailed-content__content__title">{props.title}</div>
        {props.headerImg !== '' ? (
          <img
            className="content-modal-preview__preview-body__detailed-content__content__header-img"
            src={props.headerImg || UnionSvg}
            alt="headerImg"
          />
        ) : (
          <CircularProgress className="content-modal-preview__preview-body__content__loader" size={20} />
        )}
        <div className="content-modal-preview__preview-body__detailed-content__content__body">
          {parse(decodeURIComponentSafe(props.body))}
        </div>
        <div className="content-modal-preview__preview-body__detailed-content__content__body">
          {parse(decodeURIComponentSafe(props.details))}
        </div>
      </div>
    </div>
  );
};

const Content = (props: any) => {
  const [isShowMore, setShowMore] = useState(false);
  const decodedPreviewBody = decodeURIComponentSafe(props?.body ? props?.body : '');
  const acceptableLength = 200;
  const truncatedBody = decodedPreviewBody?.substring(0, acceptableLength) || '';
  const fullText = decodedPreviewBody || '';

  const toggleShowMore = () => setShowMore((isShowMore) => !isShowMore);

  useEffect(() => {
    return () => {
      setShowMore(false);
    };
  }, [props]);

  return (
    <div className="content-modal-preview__preview-body__content">
      <FlightTooltip
        className="content-modal-preview__preview-body__tooltip"
        direction="bottom"
        isEnabled={true}
        description={HEADER_IMG_TOOL_TIP_TEXT}
      >
        {props.headerImg ? (
          <img
            className="content-modal-preview__preview-body__content__header-img"
            src={props.headerImg}
            alt="headerImg"
          />
        ) : (
          ''
        )}
      </FlightTooltip>
      <FlightTooltip
        className="content-modal-preview__preview-body__tooltip"
        direction="bottom"
        isEnabled={true}
        description={HEADER_TOOL_TIP_TEXT}
      >
        <div className="content-modal-preview__preview-body__content__header">
          <span className="content-modal-preview__preview-body__content__header__wrap">{props.header}</span>
        </div>
      </FlightTooltip>
      <FlightTooltip
        className="content-modal-preview__preview-body__tooltip"
        direction="top"
        isEnabled={true}
        description={TITLE_TOOL_TIP_TEXT}
      >
        <div className="content-modal-preview__preview-body__content__title">
          <span className="content-modal-preview__preview-body__content__title__wrap">{props.title}</span>
        </div>
      </FlightTooltip>
      <FlightTooltip
        className="content-modal-preview__preview-body__tooltip"
        direction="top"
        isEnabled={true}
        description={BODY_TOOL_TIP_TEXT}
      >
        <div className="content-modal-preview__preview-body__content__body">
          {decodedPreviewBody?.length > acceptableLength ? (
            <>
              {parse(isShowMore ? fullText : truncatedBody)}
              <span className="content-modal-preview__preview-body__content__body__showmore" onClick={toggleShowMore}>
                <div>{isShowMore ? 'Show Less' : 'Show More'}</div>
              </span>
            </>
          ) : (
            <>{parse(fullText)}</>
          )}
        </div>
      </FlightTooltip>
      <>
        <FlightTooltip
          className="content-modal-preview__preview-body__tooltip"
          direction="top"
          isEnabled={true}
          description={CTA_TOOL_TIP_TEXT}
        >
          {props.type === 'concierge-card-buttons' && props.primaryButtonLabel ? (
            <div className="content-modal-preview__preview-body__content__button--primary">
              <span className="content-modal-preview__preview-body__content__button__text"></span>
              {props.primaryButtonLabel}
            </div>
          ) : null}
          {props.type === 'concierge-card-buttons' && props.secondaryButtonLabel ? (
            <div className="content-modal-preview__preview-body__content__button--secondary">
              {props.secondaryButtonLabel}
            </div>
          ) : null}
          {props.type === 'concierge-card-link' ? (
            <span className="content-modal-preview__preview-body__content__link">{props.linkLabel}</span>
          ) : null}{' '}
        </FlightTooltip>
      </>
    </div>
  );
};

const RenderContent = (props: any) => {
  return props.cardState === viewFirstCard ? <Content {...props} /> : <DetailContent {...props} />;
};

export default function StarterContentPreview(props: ContentPreview) {
  const [card, setCard] = useState(viewFirstCard);
  const handleViewDetailedView = (e: any) => {
    e.stopPropagation();
    if (card === viewFirstCard) {
      setCard(viewSecondCard);
    } else {
      setCard(viewFirstCard);
    }
  };

  useEffect(() => {
    return () => {
      setCard(viewFirstCard);
    };
  }, [props]);

  return (
    <>
      {props.type === 'concierge-card-buttons' && props.details ? (
        <span className={props.isVisibleOnBox ? 'content-modal-cards' : 'content-modal-cards-modal'}>{card}</span>
      ) : (
        ''
      )}
      <div className="content-modal-preview" style={props.isVisibleOnBox ? renderOnPage : renderOnModal}>
        {props.type === 'concierge-card-buttons' && props.details ? (
          <>
            {card === viewFirstCard ? (
              <img
                src={RightArrow}
                className="content-modal-preview__arrow-right"
                tabIndex={0}
                alt="headerImg"
                onClick={(e) => handleViewDetailedView(e)}
              />
            ) : (
              <img
                src={LeftArrow}
                className="content-modal-preview__arrow-left"
                tabIndex={0}
                alt="headerImg"
                onClick={(e) => handleViewDetailedView(e)}
              />
            )}
          </>
        ) : null}
        {!props.isStarter ? (
          <div className="content-modal-preview__preview-body__no-content">
            {!props.isContentInstance ? (
              'No preview available for this template '
            ) : props.unSupportedTitle !== '' ? (
              <div className="content-modal-preview__preview-body__no-content__card">
                <div className="content-modal-preview__preview-body__no-content__card__title">
                  {props.unSupportedTitle}
                </div>
                <div className="content-modal-preview__preview-body__no-content__card__header">
                  {'No preview available'}
                </div>
              </div>
            ) : (
              'Please select content'
            )}
          </div>
        ) : (
          <RenderContent {...props} cardState={card} />
        )}
      </div>
      <div
        className="preview__footer-text"
        style={
          props.isVisibleOnBox ? { position: 'initial', marginTop: 10, marginLeft: 'auto', marginRight: 'auto' } : {}
        }
      >
        Actual apperance may vary.
      </div>
    </>
  );
}
