import React, { useEffect, useRef, useState } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
import Skeleton from 'react-loading-skeleton';
import './MediaPreviewModal.scss';

interface MediaPreviewModalProps {
  url: string;
  showModal: boolean;
  isVideo?: boolean;
  closeModal: () => void;
}

export const MediaPreviewModal = (props: MediaPreviewModalProps) => {
  const { url, showModal, isVideo, closeModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef<any>();
  const MAIN_CLASS = 'media-preview-modal';

  const stopVideo = () => {
    let iframe = document.querySelector('iframe');
    const video = document.querySelector('video');

    if (iframe) {
      // This is necessary to be able to stop the video
      let iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
    if (video) {
      video.pause();
    }
  };

  const handleCloseModal = () => {
    closeModal();
    stopVideo();
  };

  const handleClick = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  const handleLoading = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    handleLoading();
    // eslint-disable-next-line
  }, [url === '']);

  return (
    <div className={MAIN_CLASS + (!showModal ? ' hidden' : '')} onClick={handleClick}>
      <div ref={modalRef} className={`${MAIN_CLASS}__content`}>
        {isLoading && isVideo && <Skeleton width={'100%'} height={397} />}
        {isVideo ? (
          <iframe
            title="video-preview-modal-player"
            className={`${MAIN_CLASS}__video` + (isLoading ? ' loading' : '')}
            src={url}
            onLoad={() => {
              setIsLoading(false);
            }}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <div className={`${MAIN_CLASS}__image-container`}>
            <img src={url} alt="preview" className={`${MAIN_CLASS}__image`} />
          </div>
        )}
        <div className={`${MAIN_CLASS}__footer`}>
          <FlightButton theme="link" label="Close" onClick={handleCloseModal} />
        </div>
      </div>
    </div>
  );
};
