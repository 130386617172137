import React, { useState, useEffect } from 'react';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
// icons required
import PushIcon from 'assets/icons/push.svg';
import { ReactComponent as ReturnArrow } from 'assets/icons/return_arrow.svg';
import { ReactComponent as DeviceIcon } from 'assets/icons/device.svg';
import { ReactComponent as WebIcon } from 'assets/icons/icon_web.svg';
import { ReactComponent as InApp } from 'assets/icons/in-app.svg';

import PushEditModal from 'components/Modal/EditModal/PushEditModal/PushEditModal';
import MultiActionPushPreview from 'components/PushMessage/PreviewPush/MultiActionPushPreview';

import { getPushType } from 'helpers/templated-experience.helper';
import MultiActionContainer from 'components/Shared/MultiActionContainer/MultiActionContainer';
import MultiActionArrowIcon from 'assets/icons/multiactionarrow.svg';
import MiniCard from 'components/Shared/MiniCard/MiniCard';
import PushInterval from 'components/PushMessage/PushInterval/PushInterval';
import ContentBox from 'components/FlowBox/ContentBox/ContentBox';

import { MiniCardContent } from 'components/Content/MiniCardContent/MiniCardContent';
import ContentAPI from 'services/api/content.api';
import { Content } from 'interface/content/content.interface';
import './PushBox.scss';
import DelayBox from 'components/FlowBox/DelayBox/DelayBox';

interface ContentPreview {
  content?: Content;
}
export default function PushBox(props: any) {
  const [isPushEditModalVisible, setIsPushEditModalVisible] = useState(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [openContentModal, setOpenContentModal] = useState(false);
  const [content, setContent] = useState<ContentPreview>();
  const contentAPI = new ContentAPI();

  function onCancelHandle() {
    setIsPushEditModalVisible(false);
  }

  function onApplyHandle(form: any, type: any) {
    switch (type) {
      case 'basic':
        reduxTemplatedExperienceAction.setBasicPush(props, form);
        setIsPushEditModalVisible(false);
        break;
      case 'weblink':
        reduxTemplatedExperienceAction.setWebLinkPush(props, form);
        setIsPushEditModalVisible(false);
        break;
      case 'content':
        reduxTemplatedExperienceAction.setContentPush(props, form);
        setTimeout(() => setIsPushEditModalVisible(false), 100);
        break;
      default:
        reduxTemplatedExperienceAction.setCustomPush(props, form);
        setIsPushEditModalVisible(false);
        break;
    }
  }
  const pushType = getPushType(props);
  const RenderPushType = () => {
    switch (pushType) {
      case 'weblink':
        return (
          <span className="multi-push-preview__push-type">
            <WebIcon className="multi-push-preview__push-type__type-svg" /> Weblink{' '}
          </span>
        );
      case 'content':
        return (
          <span className="multi-push-preview__push-type">
            <InApp className="multi-push-preview__push-type__type-svg" />
            <span className="multi-push-preview__push-type__type-svg__type-content">In-App Content</span>
          </span>
        );
      case 'basic':
        return (
          <span className="multi-push-preview__push-type">
            <DeviceIcon className="multi-push-preview__push-type__type-svg" /> App Home Screen{' '}
          </span>
        );
      case 'default':
        return (
          <span className="multi-push-preview__push-type">
            <DeviceIcon className="multi-push-preview__push-type__type-svg" /> App Home Screen{' '}
          </span>
        );
    }
  };

  function MultiPushPreview() {
    const title = props!!.actionBody?.body[0]?.payload?.title?.en;
    const message = props!!.actionBody?.body[0]?.payload?.alert?.en;
    const openContentModalDirectly = (e: any) => {
      if(pushType === 'content'){
        e.stopPropagation();
        setOpenContentModal(true);  
      }
    };

    return (
      <div className="multi-push-preview">
        <div className="multi-push-preview__message">{message}</div>
        <MultiActionPushPreview
          title={title}
          message={message}
          placement="box"
          onClick={() => setIsPushEditModalVisible(true)}
        />
        <PushInterval {...props} type="Push Notification" showDelayOnCanvas={true} />
        {(content || title) && (
          <div className="multi-push-preview__subject">
            <ReturnArrow style={{ marginRight: 5, marginTop: 12 }} />
        <span className="multi-push-preview__subject__leads">Leads to : {''}</span>
            <span className="multi-push-preview__push-type">{RenderPushType()}</span>
            {pushType === 'weblink' ? (
              <div className="multi-push-preview__push-type__weblink" onClick={openContentModalDirectly}>
                {props!!.actionBody?.body[0]?.payload?.data?.url?.en || 'Add Weblink'} 
              </div>
            ) : null}
            {pushType === 'content' && (
              <div className="multi-push-preview__push-type__push-content" onClick={openContentModalDirectly}>
                <MiniCardContent {...content} hideCollapse={true} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function showPushModal() {
    if (!props.isNotEditable) {
      setIsPushEditModalVisible(true);
    }
  }

  const RenderMiniCards = () => {
    return (
        <MiniCard title={'Push Notification'} icon={PushIcon} type="push" onClick={showPushModal}>
          {MultiPushPreview()}
        </MiniCard>
    );
  };

  const onApply = () => {
    setOpenContentModal(false);
  };

  const fetchContentInstance = () => {
    const id = props!!.actionBody?.body[0]?.payload?.data?.contentId?.value;
    if (id) {
      contentAPI.getInstance(id).then((res: any) => {
        setContent({ content: res });
      });
    }
  };

  const handleRequestUpdatedInstance = () => {
    fetchContentInstance();
  };

  useEffect(() => {
    if (props!!.actionBody?.body[0]?.payload?.data?.contentId?.value !== '') {
      fetchContentInstance();
    }
    // fetch instance only if contentId has been set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props!!.actionBody?.body[0]?.payload?.data?.contentId?.value]);

  const handleCancel = () => {
    setTimeout(() => setOpenContentModal(false), 100);
    setIsPushEditModalVisible(true);
  };

  const onTriggerContentModal = (form: any, type: any) => {
    onApplyHandle(form, type);
    setOpenContentModal(true);
  };

  return (
    <React.Fragment>
      {props.actionType === 'push' && !!props.actionBody?.delivery?.delay && (
        <DelayBox {...props} onBoxClick={showPushModal} />
      )}
      <div>
        <MultiActionContainer {...props} title="Actions" icon={MultiActionArrowIcon} hideCollapse>
          <RenderMiniCards />
        </MultiActionContainer>
      </div>
      {isPushEditModalVisible && (
        <PushEditModal
          action={props}
          amplified={false}
          isVisible={isPushEditModalVisible}
          triggerContentModal={onTriggerContentModal}
          onApply={onApplyHandle}
          onCancel={onCancelHandle}
        />
      )}
      <ContentBox
        {...props}
        openModal={openContentModal}
        modalClosed={handleCancel}
        onApply={onApply}
        isCalledFromPush
        contentInstance={{}}
        hideBox
        requestUpdatedInstance={handleRequestUpdatedInstance}
      />
    </React.Fragment>
  );
}
