
import BaseAPI from './base.api';
import { Rule } from 'interface/rule/rule.interface';

export default class RuleAPI extends BaseAPI {

  private ROUTES = {
    all: `/context/rules?sortby=createdAt&sortorder=desc&visibility=public`
  }

  getRules(page:number, limit: number, search: string = ''): Promise<Rule[]> {
    return this.GET(`${this.ROUTES.all}&limit=${limit}&offset=${page*limit}&search=${search}`);
  }
}
