import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Slider from '@material-ui/core/Slider';
import { default as MaterialTooltip } from '@material-ui/core/Tooltip';

import AOApi from 'services/api/ao.api';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';

import { FlightButton, FlightSnackbar } from '@flybits/webapp-design-system-react';
import { ResponsiveContainer, CartesianGrid, AreaChart, Legend, XAxis, YAxis, Tooltip, Area } from 'recharts';
import { ReferenceArea, ReferenceDot, ReferenceLine } from 'recharts';

import './AnalyticsPerformanceOptimizedChart.scss';
import { serializeTriggerData } from 'helpers/templated-experience.helper';
import RightArrowBlue from 'assets/icons/right-arrow-blue.svg';
import { FlightTable } from '@flybits/webapp-design-system-react';
import { startCase } from 'lodash';

interface IProp {
  instance: any;
  data: any;
  onSetData: any;
}

// For now, the content of this graph is hard coded for PNC Demo.
export default function AnalyticsPerformanceOptimizedChart(props: IProp) {
  let { data, onSetData, instance } = props;
  let { optimizationGraph, optimizationTarget, engagementRate } = data;

  const aoAPI = new AOApi();
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);

  const [chartData, setChartData] = useState<any>([]);
  const [sliderValue, setSliderValue] = useState(engagementRate || 0);
  const [prevSliderValue, setPrevSliderValue] = useState<Number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);

  function scrollToCTA() {
    setTimeout(() => {
      document
        .querySelector('.analytics-performance-optimized-chart__control')!!
        .scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  // Custon logic for handling tooltip
  function renderTooltip(props: any) {
    if (!props.label) return;
    const x = props.label;
    const y = chartData[x - 1].x;
    return (
      <div className="custom-tooltip">
        <div className="row highlight">
          <p> Engagement Rate </p>
          <p> {y}% </p>
        </div>
        <div className="row">
          <p> Audience Reach </p>
          <p> {x}% </p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    setSliderValue(reduxTemplatedExperienceState?.instance?.automationBudget?.cutOffPercentage);
    setChartData(generateDataPoints());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSliderChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
  };

  // Let the user click on grap
  function onGraphClickHandler(graphInfo: any) {
    if (isEditing && graphInfo) {
      setPrevSliderValue(sliderValue);
      setSliderValue(graphInfo?.activePayload[0]?.payload?.percentage);
    }
  }

  function updateJourneyMode(journeyMode: 'automation' | 'exploration') {
    if (journeyMode === 'automation') {
      // @arman is not sure why this was in the first place, commenting it out as seedRuleStringRep is not necessary for
      // automation
      // reduxTemplatedExperienceState.instance.journeyMode =
      //   isEmpty(reduxTemplatedExperienceState?.instance?.steps[0]?.seedRuleStringRepresentation)
      //     ? 'manual'
      //     : 'automation';
      reduxTemplatedExperienceState.instance.journeyMode = 'automation';
      //set slider val percent as automation budget
      reduxTemplatedExperienceState.instance.automationBudget = {
        cutOffPercentage: chartData[sliderValue]?.percentage - 1,
      }; //due to an internal bug with recharts subtract one
    } else if (journeyMode === 'exploration') {
      reduxTemplatedExperienceState.instance.journeyMode = 'exploration';
    }
    const serializedSteps = serializeTriggerData(reduxTemplatedExperienceState.instance.steps);
    reduxTemplatedExperienceState.instance.steps = serializedSteps;
    templatedExperienceAPI.updateTemplatedExperienceInstance(reduxTemplatedExperienceState.instance);
  }

  function generateDataPoints() {
    let arr = new Array(100).fill(null).map((numb, idx) => ({
      percentage: idx + 1,
      x: Math.ceil(optimizationGraph[idx] && optimizationGraph[idx].pointY * 100),
    }));
    return arr;
  }

  const isInteger = (value: number) => {
      return Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER;
  } 

  //the graph cannot plot values that are 
 //floats, if we get a floaty value we round off to ceil. 
  const getValue = (graphValue: number) => {
    if (graphValue) {
      const _isInteger = isInteger(graphValue);
      if(_isInteger) return graphValue; 
      return Math.ceil(graphValue)
    }
  };

  const showDetails = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsShowDetails(!isShowDetails);
  }

  const renderBar = (val: number = 0) => {
    return <div style={{width: `${val}%`, height: 20, backgroundColor: '#ffc107'}}></div>
  }

  const importanceTableData = () => {

    let tableRows: any = [];
    interface Features {
      feature: string,
      importance: number
    }
    data.featureMetrics = data.featureMetrics.sort((a:Features, b:Features) => b.importance - a.importance);
    data.featureMetrics.forEach((f: Features) => {
      let attrLength = f.feature.split('.');
      let attrName = attrLength[attrLength.length - 1];
      let categoryName = attrLength[attrLength.length - 2];
      if(categoryName === 'query') {
        categoryName = attrLength[attrLength.length - 3];
      }

      tableRows.push({
        key: `${f.feature}`,
        attribute: <span className="analytics-performance-optimized-chart__table__attribute">
          {startCase(categoryName)} / <strong>{startCase(attrName)}</strong>
        </span>,
        importance: <div>{renderBar(Math.round(f.importance * 100))}</div>,
      })
    })
    return tableRows;
  }


  return (
    <div className="analytics-performance-optimized-chart">
      <div className="analytics-performance-optimized-chart__main">
        <div className="top">
          <p className="headline">Projected Optimized Audience Performance</p>
          <div>
            {instance?.journeyMode !== 'automation' && !isEditing && (
              <FlightButton
                theme="minor"
                iconRight="baselineArrowRight"
                label={'Optimize my audience'}
                className="graph-button"
                onClick={() => {
                  if (sliderValue === 0) {
                    setSliderValue(50);
                  }
                  setIsEditing(true);
                  scrollToCTA();
                }}
              />
            )}
          </div>
        </div>
        <div className="status">
          {/* If audience optimization has a value */}
          {engagementRate > 0 && (
            <FlightSnackbar
              isVisible={true}
              isFloating={false}
              type="info"
              content={`Actively optimizing audience targeting to reach a ${
                chartData[engagementRate - 1]?.x
              }% engagement rate`}
              isAutoDismiss={true}
              actionName="Modify"
              action={() => {
                setIsEditing(true);
                scrollToCTA();
              }}
            />
          )}
        </div>
        <div className="chart">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={chartData}
              margin={{ top: 50, left: 10, bottom: 20 }}
              onClick={(graphInfo: any) => onGraphClickHandler(graphInfo)}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={renderTooltip} />
              <XAxis
                label={{ value: 'Audience Reach', position: 'bottom', className: 'label' }}
                dataKey="percentage"
                tickLine={false}
                ticks={[0, 100]}
                tickFormatter={(e: any) => e + '%'}
                dx={-5}
              />
              <YAxis
                label={{ value: 'Engagement Rate', position: 'insideLeft', angle: -90, className: 'label' }}
                ticks={[0, 100]}
                width={80}
                dataKey="x"
                tickFormatter={(e: any) => e + '%'}
                tickLine={false}
              />
              <Legend
                align="left"
                verticalAlign="top"
                height={70}
                margin={{ top: 0 }}
                payload={[
                  { value: 'Flybits Optimized Engagement', type: 'square', color: '#5123B3', id: 'legend1' },
                  { value: 'Preferred Audience Engagement', type: 'circle', color: '#2371F1', id: 'legend2' },
                ]}
              />
              <Area type="monotone" dataKey="x" stroke="#5123B3" fillOpacity={0} className="line" />
              {/* Current Audience Target Horizontal Guideline */}
              <ReferenceLine
                y={getValue(optimizationTarget && optimizationTarget[1])}
                stroke="blue"
                strokeDasharray="2 2"
                className="reference-line"
              />
              <ReferenceLine
                x={getValue(optimizationTarget && optimizationTarget[0])}
                stroke="blue"
                strokeDasharray="2 2"
                className="reference-line"
              />
              {/* Current Audience Target Point */}
              <ReferenceDot
                x={getValue(optimizationTarget && optimizationTarget[0])}
                y={getValue(optimizationTarget && optimizationTarget[1])}
                r={8}
                fill="#2371F1"
                fillOpacity={0.8}
                stroke="none"
              />
              <ReferenceArea
                x1={1}
                x2={sliderValue}
                y1={0}
                y2={100}
                className="reference-area"
                stroke="black"
                fill="#00AB50"
                fillOpacity={0.1}
                strokeOpacity={0.1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="analytics-performance-optimized-chart__control">
        {isEditing && (
          <div className="range-slider">
          </div>
        )}
        {isEditing && (
          <div className="button-actions">
            <FlightButton
              theme="secondary"
              label={'Cancel'}
              onClick={() => {
                setIsEditing(false);
                setSliderValue(engagementRate || prevSliderValue);
              }}
            />
            <FlightButton
              label={`Optimize for ${chartData[sliderValue - 1]?.x}% Engagement Rate`}
              onClick={() => {
                setIsEditing(false);
                setPrevSliderValue(sliderValue);
                updateJourneyMode('automation');
              }}
            />
          </div>
        )}
        {instance?.journeyMode === 'automation' ? (
          <div className="button-actions">
            <FlightButton
              type="warning"
              label={'Stop Optimized Targeting'}
              className="remove-button"
              onClick={() => {
                aoAPI.postMetrics({ ...data, engagementRate: 0 }).then(() => {
                  onSetData({ ...data, engagementRate: 0 });
                });
                updateJourneyMode('exploration');
                setIsEditing(false);
                setSliderValue(0);
                setPrevSliderValue(0);
              }}
            />{' '}
          </div>
        ) : null}
      </div>
      <a href="/"
        className="analytics-performance-optimized-chart__targeting-details"
        onClick={showDetails}
      >
        <img
          src={RightArrowBlue}
          className={isShowDetails ? 'arrow-right active' : 'arrow-right'}
          tabIndex={0}
          alt="dropdownArrow"
        />
         Show targeting details
      </a>
      {isShowDetails && <div>
        <div className="analytics-performance-optimized-chart__details-description">
          What factors are the smart targeting model taking into account
          when positioning this experience to within the <a href="/" onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>restricted audience</a>?
        </div>
        <div className="analytics-performance-optimized-chart__suggested-model">
          <div className="analytics-performance-optimized-chart__suggested-model__marker"></div>
          Suggested Smart Targeting Model
        </div>
        <div className="analytics-performance-optimized-chart__table-container">
          <FlightTable
            className="analytics-performance-optimized-chart__table"
            tableHeaders={[{
              key: 'attribute',
              name: 'Context attributes',
              isVisible: true,
              isSortable: true
            }, {
              key: 'importance',
              name: 'Importance factor (%)',
              isVisible: true,
              isSortable: true
            }]}
            tableData={importanceTableData()}
            isLoading={false}
            loadingRowNumber={2}
            hasError={false}
            hasPaginationAfterTable={false}
            hasPaginationBeforeTable={false}
          />
          <div className="analytics-performance-optimized-chart__attr-info-section">
            <div className="analytics-performance-optimized-chart__attr-info">
              <span><span className="analytics-performance-optimized-chart__attr-amount">{data?.featureMetrics?.length}</span> Context Attributes</span>
            </div>
            <div className="analytics-performance-optimized-chart__attr-descr">
              <div>
                Were taking into account when determining what types of customers
                are most likely to engage with your experience.
              </div>
              <div>
                You can omit attributes you do not factored in <a href="/">here</a>.
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}

function SliderLabelComponent(props: any) {
  const { children, value } = props;
  return (
    <MaterialTooltip open={false} enterTouchDelay={0} placement="bottom" title={value + '% Audience Reach'}>
      {children}
    </MaterialTooltip>
  );
}
