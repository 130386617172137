import { Plugin } from 'interface/plugin/plugin.interface';
import { startCase, flatten, capitalize } from 'lodash';
import { parse } from 'flatted';

import LocationAPI from 'services/api/location.api';
import PluginAPI from 'services/api/plugin.api';
const locationAPI = new LocationAPI();
const pluginAPI = new PluginAPI();

export function findPluginByUID(plugins: Plugin[], uid: string) {
  return plugins.filter((plugin) => plugin.uid === uid);
}

// List of all predicates
const PREDICATES: {[key: string]: string} = {
  'boolEq': 'is equal to',
  'boolNeq': 'Is not',
  'has_boolEq': 'Has been',
  'has_not_boolEq': 'Has not been',
  'notEq': 'is not equal to',
  'eq': 'is equal to',
  'neq': 'is not',
  'ls': 'is less than',
  'lseq': 'is less than or equal to',
  'gr': 'is greater than',
  'greq': 'is greater than or equal to',
  'stringFoldEq': 'is',
  'stringFoldNeq': 'is not',
  'stringFoldContains': 'contains',
  'stringEq': 'Is',
  'stringNeq': 'Is not',
  'stringContains': 'Contains',
  'has_stringEq': 'Has been',
  'has_eq': 'Has been',
  'has_not_eq': 'Has not been',
  'has_gr': 'Has been greater than',
  'has_not_gr': 'Has not been greater than',
  'has_ls': 'Has been less than',
  'has_not_ls': 'Has not been less than'
};

function _predicateToString(predicate: string) {
  return PREDICATES[predicate];
}

function _ctxToString(context: string) {
  let _context = context.split(".").slice(2).map(word => startCase(word));
  return `${_context[1]} (${_context[0]})`;
}

function getHistoricalPluginText({historicalQueryRecord, ruleBody, stringRepArr}:any) {

  let rule = parse(unescape(ruleBody));
  let historicalPluginText = '';
  const isNegated = rule?.body?.isNegated;
  let predicateName = stringRepArr?.[0];
  const predicateValue = stringRepArr?.[2];
  const where = historicalQueryRecord?.where?.[0];
  let attributeName = historicalQueryRecord?.plugin?.attribute;
  let pluginName = historicalQueryRecord?.plugin?.id?.split('.').pop();
  let whereOperator = '';

  if (predicateName === 'greq') {
    predicateName = 'at least';
  } else if (predicateName === 'lseq') {
    predicateName = 'at most';
  } else if (predicateName === 'eq') {
    predicateName = 'exactly';
  } else if (predicateName === 'ls') {
    predicateName = 'less than';
  } else if (predicateName === 'gr') {
    predicateName = 'more than';
  } else {
    predicateName = 'somewhat';
  }

  if (where?.operator === '=') {
    whereOperator = 'has been';
  } else if (where?.operator === '>') {
    whereOperator = 'has been greater than';
  } else if (where?.operator === '<') {
    whereOperator = 'has been less than';
  }

  if (isNegated) {
    whereOperator = whereOperator.replace('has been', 'has not been');
  }

  historicalPluginText += `${attributeName} (${pluginName})`;
  historicalPluginText += ` ${whereOperator} ${where.value}`;
  historicalPluginText += ` ${predicateName} ${predicateValue} time(s)`;

  if (historicalQueryRecord.timeRange && historicalQueryRecord.timeRange.length) {
    const timeRange = historicalQueryRecord.timeRange[0];

    if (timeRange.from > 0 && timeRange.to > 0 && timeRange.duration <= 0) {
      historicalPluginText += ' between ';
      historicalPluginText += window.moment.unix(timeRange.from).format('ddd MMM DD YYYY h:mma');
      historicalPluginText += ' and ';
      historicalPluginText += window.moment.unix(timeRange.to).format('ddd MMM DD YYYY h:mma');
    } else if (timeRange.from > 0 && timeRange.to <= -1 && timeRange.duration <= 0) {
      historicalPluginText += ' after ';
      historicalPluginText += window.moment.unix(timeRange.from).format('ddd MMM DD YYYY h:mma');
    } else if (timeRange.to > 0 && timeRange.from <= -1 && timeRange.duration <= 0) {
      historicalPluginText += ' before ';
      historicalPluginText += window.moment.unix(timeRange.to).format('ddd MMM DD YYYY h:mma');
    } else if (timeRange.from === 0 && timeRange.to === -1 && timeRange.duration > 0) {
      historicalPluginText += ' in the past ';

      let recencyInThePastUnit = 'minute(s)';
      let recencyInThePastValue = null;

      if (timeRange.duration >= 86400) {
        recencyInThePastValue = timeRange.duration / 86400;
        recencyInThePastUnit = 'day(s)';
      } else if (timeRange.duration >= 3600) {
        recencyInThePastValue = timeRange.duration / 3600;
        recencyInThePastUnit = 'hour(s)';
      } else {
        recencyInThePastValue = timeRange.duration / 60;
        recencyInThePastUnit = 'minute(s)';
      }

      historicalPluginText += `${recencyInThePastValue} ${recencyInThePastUnit}`;
    }
  }

  return historicalPluginText;
}


function getUIDAsText(uid:string) {
  const spacify = (str:string) => capitalize(str.replace(/([A-Z])/g, ' $1').trim());
  let text = '';
  const tokens = uid.split('.');

  text += `${spacify(tokens[tokens.length - 1])} `;
  text += `(${spacify(tokens[tokens.length - 2])})`;

  return text;
}


function getMathOperatorsAsText(predicateName: string) {
  const predicates:any = {
    'add': 'plus',
    'div': 'divided by',
    'mod': 'modulo',
    'multiply': 'multiplied by',
    'power': 'to the power',
    'subtract': 'minus',
  };
  return predicates[predicateName];
}

/**
 * returns a refined object of stringRep array separated by predicate (1st element) and arguments (2nd and 3rd element)
 * @param stringRepArr raw array format of stringRep example) ["is less than", "Percentage (Battery)", "30"]
 */
function _convertToPredicateObject(stringRepArr: string[], ruleBody:any) {
  return new Promise((resolve) => {
    const inArea = stringRepArr[1].indexOf('inArea') > -1 || stringRepArr[1].indexOf('approximateArea') > -1;
    const inLocationWithLabel = stringRepArr[1].indexOf('inLocationWithLabel') > -1;
    const isHistorical = stringRepArr[1].indexOf('ctx.flybits.ctxhistory') > -1;
    if (inArea) {
      const locationID = stringRepArr[1].split(".").pop() || '';
      locationAPI.getLocation(locationID).then((location: any) => {
        resolve({
          type: 'location:map',
          location: location,
          predicate: _predicateToString(stringRepArr[0]),
          arguments: [
            _ctxToString(stringRepArr[1]),
            stringRepArr[2]
          ]
        });
      })
    } else if (inLocationWithLabel) {
      resolve({
        type: 'location:label',
        predicate: _predicateToString(stringRepArr[0]),
        label: stringRepArr[1].split('.').pop(),
        arguments: [
          _ctxToString(stringRepArr[1]),
          stringRepArr[2]
        ]
      })
    } else if (isHistorical) {
      const splitedStringRep = stringRepArr?.[1]?.split(" ");
      const recordId = splitedStringRep?.[0].split('ctx.flybits.ctxhistory.query.result.')[1];
      pluginAPI.getHistoricalPlugins(recordId).then((historyPlugin: any) => {
        if (historyPlugin) {
          let historicalPluginText = getHistoricalPluginText({historicalQueryRecord: historyPlugin, ruleBody, stringRepArr})
          resolve({
            type: 'general',
            predicate: capitalize(historicalPluginText),
            arguments: []
          });
        }
      });
    } else {
      let predicate = _predicateToString(stringRepArr?.[0]);
      let args = [
        _ctxToString(stringRepArr?.[1]),
        stringRepArr?.[2]
      ];
      let description = '';
      const comparisonOperator = _predicateToString(stringRepArr?.[0]);
      let argOne = stringRepArr?.[1];
      let argTwo = stringRepArr?.[2];
      let argThree = stringRepArr?.[3];
      let hasQuery = false;
      let parameters:any = [];

      if (argOne && typeof argOne === 'string' && argOne.indexOf('ctx.') >= 0) {
        if (argOne.indexOf('query') >= 0) {
          hasQuery = true;
          parameters = argOne.split('.query.');
          parameters[1] = parameters?.[1].split('.');
          parameters[0] += `.${parameters?.[1].shift()}`;
          argOne = parameters?.[0];
          parameters = parameters?.[1];
        }
        argOne = getUIDAsText(argOne);
      }

      if (argTwo && typeof argTwo === 'string' && argTwo.indexOf('ctx.') >= 0) {
        argTwo = getUIDAsText(argTwo);
      }

      if (argThree && typeof argThree === 'string' && argThree.indexOf('ctx.') >= 0) {
        argThree = getUIDAsText(argThree);
      }

      if (stringRepArr?.length === 4) {
        const mathOperator = getMathOperatorsAsText(stringRepArr[0]);
        description = `${argOne} ${mathOperator} ${argTwo} is equal to ${argThree}`;
      } else {
        description = `${argOne} ${comparisonOperator} ${argTwo}`;
      }

      if (hasQuery) {
        description += ` (with parameters: ${parameters.join(', ')})`;
        predicate = '';
        args = [description];
      }

      resolve({
        type: 'general',
        predicate,
        arguments: args
      });
    }
  })
}

/**
 * convert a stringRepresentation into an array of human readable elements
 * @param stringRep stringRepresentation of the rule
 * @returns {operator: string, data: array}
 * the first returned value represents a string either "And" or "Or",
 * the second returned value is an array of array, where each nested array is a 3 element array containing transpiled information of the rule.
 * ["is equal to", "Percentage (Battery)", "30"]
 */
export function ruleToString(stringRep: string, ruleBody:any) {

  const _stringRep = stringRep.split(" ");
  const ruleStringArr = _stringRep.map((e: any) => {
    if (['and', 'or'].includes(e.toLowerCase())) {
      return e;
    } else {
      const regex = /[(),]/g
      const attributes = e.replace(regex, ' ').trim().split(" "); // ["eq", "ctx.sdk.battery.percentage", "30"]
      return attributes.length === 1
        ? [attributes] // ["customRule"]
        : _convertToPredicateObject(attributes, ruleBody); // ["is equal to", "Percentage (Battery)", "30"]
    }
  });

  return Promise.all(ruleStringArr);
}

function stringRepParser(stringRep: string) {
  // remove the closing parenthesis wrapper;
  if (!stringRep) return [];
  stringRep = stringRep.substring(1, stringRep.length - 1);
  let section: string = '';
  let isOpen: boolean = false;
  let result = [];

  for (var i = 0; i < stringRep.length; i++) {
    if (stringRep[i] === '(') {
      if (i === 0 || stringRep[i-1] === ' ') {
        isOpen = true;
      } else {
        section += stringRep[i];
      }
    } else if (stringRep[i] === ')') {
      if (i === stringRep.length - 1) {
        result.push(section);
        break;
      } else if (stringRep[i-1] === ')') {
        isOpen = false;
        result.push(section);
        section = '';
      } else {
        section += stringRep[i];
      }
    } else {
      section += stringRep[i];
      if (!isOpen) {
        if ([' And ', ' Or '].includes(section)) {
          result.push(section.trim());
          section = '';
        }
      }
    }
  }
  return result;
}

export function stringRepObject(stringRep: string, ruleBody:any) {
  const parsedStringRep = stringRepParser(stringRep);
  const arr = parsedStringRep.map((elem: any) => {
    if (!['And', 'Or'].includes(elem)) {
      const res = ruleToString(elem, ruleBody);
      return res;
    } else {
      return elem;
    }
  });
  return Promise.all(arr);
}

export async function stringRepFinal(stringRep: string, ruleBody:any) {
  const obj = await stringRepObject(stringRep, ruleBody);
  let str = '';
  let idx = 1;
  for (let i = 0; i < obj.length; i++) {
    if(!Array.isArray(obj[i][0][0])) { //check if is not an empty array
      if (Array.isArray(obj[i])) {
        str += '( ';
        for (let j = 0; j < obj[i].length; j++) {
          if (typeof obj[i][j] === 'object') {
            str += `${idx++} `;
          } else {
            str += obj[i][j].toLowerCase() + ' ';
          }
        }
        str += ')';
      } else {
        str += ' ' + obj[i].toLowerCase() + ' ';
      }
    }
  }

  let arr = obj.filter((elem: any) => Array.isArray(elem));
  arr = flatten(arr).filter((elem: any) => typeof elem === 'object');

  let type = '';
  if (str.indexOf('and') > -1) {
    if (str.indexOf('or') > -1) {
      type = 'custom';
    } else {
      type = 'and';
    }
  } else if (str.indexOf('or') > -1) {
    if (str.indexOf('and') > -1) {
      type = 'custom'
    } else {
      type = 'or';
    }
  } else {
    type = 'or';
  }

  return Promise.resolve({
    toString: str,
    predicates: arr,
    type,
  });
}

// AO Demo Related Helpers
export function ruleToArr(stringRep: string) {
  const isOr: any = stringRep.indexOf("Or") > -1;
  stringRep = stringRep.replace(isOr ? /Or/g : /And/g, ''); // remove AND / OR
  const stringRepArr: string[] = stringRep.substring(1, stringRep.length - 1).split(" ").filter(a => !!a);
  const regex = /[(),]/g
  const arr = stringRepArr.map((_stringRep: any) => ({
    ruleStringRepresentation: _stringRep, // boolEq(ctx.sdk.activity.cycling,true)
    delimittedRuleStringRepresentation: _stringRep.replace(regex, ' ').trim().split(" ") // ["boolEq", "ctx.sdk.activity.cycling", "true"]
  }));
  return {
    operator: isOr ? 'Or' : 'And',
    data: arr,
  }
}
