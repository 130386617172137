import React from 'react';
import Defaultbackground from 'assets/images/default-lib-pattern.jpg';
import './TemplateCard.scss';

interface TemplateCardProps {
  library?: string;
  renderInColumn?: boolean;
  name?: string;
  className?: string;
  libColor?: string;
  headerImage?: string;
  onClick: () => void; // Handle selection of template
}

export const TemplateCard = (props: TemplateCardProps) => {
  const { library, renderInColumn, name, className, headerImage, libColor, onClick } = props;
  const MAIN_CLASS = 'template-card';

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      className={`${MAIN_CLASS} ${className} ${!renderInColumn && "hover-shadow"}`}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <div className="inner-wrap">
        <div
          style={{backgroundImage:'url('+headerImage+')' || 'url('+Defaultbackground+')'}}
          className={`${MAIN_CLASS}__img` + (renderInColumn ? ' fade' : '') + (!name ? ' blur' : '')}
        ></div>
        {library && (
          <span className={`${MAIN_CLASS}__library`} style={{ backgroundColor: libColor }}>
            {library}
          </span>
        )}
        {name ? (
          <div className={`${MAIN_CLASS}__name`}>
            <span>{name}</span>
          </div>
        ) : (
          <span className={`${MAIN_CLASS}__no-template`}>No Templates Available</span>
        )}
      </div>
    </div>
  );
};
