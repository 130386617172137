import React, { useEffect, useState } from 'react';
import './ControlBar.scss';
import { FlightButton, FlightTag, FlightTextInput } from '@flybits/webapp-design-system-react';
import { defineStatusStyle } from 'helpers/templated-experience.helper';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import ScheduleInline from 'components/ScheduleInline/ScheduleInline';
import IncompleteCounter from 'components/IncompleteCounter/IncompleteCounter';
import { useSelector } from 'react-redux';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import { trackEvent } from 'helpers/analytics.helper';
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
import SearchBarPage from 'components/Shared/SearchBarPage/SearchBarPage';

interface IProps {
  name?: string;
  status?: string;
  onLaunch?: () => void;
  onLaunchDisabled?: boolean;
  showSearchBar?: boolean;
  handleSearchInput?: (value: string) => void;
  onSave?: () => void;
  onSaveDisabled?: boolean;
  onCancel?: () => void;
  onDraft?: (schedule: TExperienceInstance['schedule']) => void;
  onDraftDisabled?: boolean;
  onDeactivate?: () => void | undefined;
  onEdit?: (id: string) => void;
  onNameEdit?: (name: string) => void;
  showScheduleModal?: () => void | undefined;
  experience?: TExperienceInstance | undefined;
  template?: TExperienceInstance | undefined;
  count?: () => void | undefined;
  onIncompleteHandle?: () => void | undefined;
  actionTab?: any;
  performanceTab?: any;
  children?: any;
  nameHasError?: string;
}

export default function ControlBar(props: IProps) {
  const [selectedTab, setSelectedTab] = useState('first');
  const hasTab = !!props.performanceTab;
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const confirmModalProps = {
    isVisible: false,
    icon: 'warning.svg',
    title: 'You have unsaved changes',
    content: 'If you leave this tab, all unsaved changes made will be lost',
    primaryButton: {
      name: 'Save changes',
      onClick: () => {},
      disabled: false,
    },
    cancelButton: {
      name: 'Discard changes',
      onClick: () => {},
    },
  };
  const [modalProps, setModalProps] = useState<any>(confirmModalProps);

  useEffect(() => {
    const input = document.querySelector('input');
    if ((props.template && (props.status === 'Draft' || props.status === '')) || props.nameHasError) {
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status, props.nameHasError, props.onDraft, props.onSave, props.onLaunch]);

  function onModalConfirm() {
    let status = reduxTemplatedExperienceState?.instance?.status;
    let schedule = reduxTemplatedExperienceState?.instance?.schedule!;
    if (status === 'Active') {
      reduxTemplatedExperienceAction.updateInstanceSchedule({ schedule }).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Change schedule on Active and Save',
        });
        setModalProps({ ...modalProps, isVisible: false });
        setSelectedTab('second');
      });
    } else {
      reduxTemplatedExperienceAction.updateTemplatedInstance({ status }).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Edit and Save',
        });
        setModalProps({ ...modalProps, isVisible: false });
        setSelectedTab('second');
      });
    }
  }

  function onModalDiscard() {
    reduxTemplatedExperienceState.isModified = false;
    reduxTemplatedExperienceAction.setInstance({ instance: { ...reduxTemplatedExperienceState.temp.instance } }); // update the error state
    setModalProps({ ...modalProps, isVisible: false });
    setSelectedTab('second');
  }

  function onChangeTab(tab: string) {
    if (reduxTemplatedExperienceState.isModified && tab === 'second') {
      setModalProps({
        ...modalProps,
        isVisible: true,
        primaryButton: {
          ...modalProps.primaryButton,
          onClick: () => onModalConfirm(),
        },
        cancelButton: {
          ...modalProps.cancelButton,
          onClick: () => onModalDiscard(),
        },
      });
    } else {
      setSelectedTab(tab);
    }
  }

  return (
    <div className="control-bar">
      <div className="control-bar__header">
        {props.showSearchBar && props.handleSearchInput && (
          <div className="control-bar__header-left__search">
            <SearchBarPage
              className="control-bar__header-left__search__name"
              handleSearchTermChange={props.handleSearchInput}
              placeholder="Search All Libraries"
              width={'503px'}
            />
          </div>
        )}
        <div className="control-bar__header-left">
          {props.onNameEdit && (
            <FlightTextInput
              type="text"
              hasError={!!props.nameHasError}
              errorMessage={props.nameHasError}
              value={props.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onNameEdit!!(e.target.value)}
              placeholderText="Untitled"
              className="control-bar__header-left__name"
            />
          )}
        </div>
        <div className="control-bar__header-right">
          {props.status && (
            <p className="control-bar__status">
              Status:{' '}
              <FlightTag
                type={defineStatusStyle(props.status)}
                label={props.status}
                className="control-bar__status__text"
              />
            </p>
          )}
          {props.onCancel && (
            <FlightButton
              label="Cancel"
              theme="secondary"
              size="large"
              onClick={() => {
                props.onCancel!!();
              }}
            />
          )}
          {props.onDraft && props.status === 'Draft' && (
            <FlightButton
              label="Save as Draft"
              type="secondary"
              size="large"
              disabled={props.onDraftDisabled}
              onClick={(schedule) => props.onDraft!!(schedule)}
            />
          )}
          {props.onSave && selectedTab === 'first' && (
            <FlightButton label="Save" size="large" disabled={props.onSaveDisabled} onClick={() => props.onSave!!()} />
          )}
          {props.onDeactivate && (
            <FlightButton label="Deactivate" size="large" onClick={() => props.onDeactivate!!()} />
          )}
          {props.onLaunch && (
            <FlightButton
              label="Launch"
              size="large"
              disabled={props.onLaunchDisabled}
              onClick={() => props.onLaunch!!()}
            />
          )}
          {props.onEdit && <FlightButton label="Edit" size="large" onClick={props.onEdit} />}
        </div>
      </div>
      {props.performanceTab && (
        <div className="control-bar__tab">
          <div className="tab border">
            <div className="tab__content">
              <div className="tab__content-link">
                <a href="# " className={selectedTab === 'first' ? 'selected' : ''} onClick={() => onChangeTab('first')}>
                  Actions
                </a>
                <a
                  href="# "
                  className={selectedTab === 'second' ? 'selected' : ''}
                  onClick={() => onChangeTab('second')}
                >
                  Performance
                </a>
              </div>
              <div className={selectedTab === 'first' ? 'underline left' : 'underline right'}> </div>
            </div>
          </div>
        </div>
      )}
      <div className={`control-bar__main ${hasTab ? 'spacing-xl' : ''}`}>
        {props.children ? (
          props.children
        ) : selectedTab === 'first' ? (
          <>
            {(props.experience || props.template) && props.showScheduleModal && (
              <>
                <ScheduleInline
                  showScheduleModal={props.showScheduleModal}
                  experience={props.experience!! || props.template!!}
                />
                <IncompleteCounter count={props.count} onClickHandle={props.onIncompleteHandle} />
              </>
            )}
            {props.actionTab}
          </>
        ) : (
          props.performanceTab
        )}
      </div>
      <ConfirmModal {...modalProps} setModalProps={setModalProps} />
    </div>
  );
}
