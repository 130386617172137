import React, { useState, useEffect } from 'react';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import FlowBox from 'components/FlowBox/FlowBox';
import TriggeredIcon from 'assets/icons/triggered-icon.svg';
import TriggerBroadcast from 'assets/icons/trigger-clock.svg';
import TriggerEditModal from 'components/Modal/EditModal/TriggerEditModal/TriggerEditModal';
import './TriggerBox.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { ReactComponent as Arrow } from 'assets/icons/arrow__new.svg';

import isInAppContentTemplate from 'helpers/is-in-appcontent.helper';

export default function TriggerBox(props: any) {
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [isTriggerModalVisible, setIsTriggerModalVisible] = useState(false);
  let [boxData, setBoxData] = useState({
    icon: TriggeredIcon,
    title: 'Triggered Delivery',
    summary: false,
    description: 'Set when your Experience will be visible to each member of your target audience.',
    configButton: false,
  });
  const plugins = useSelector((state: any) => state.plugin.plugins);

  function body() {
    if (props.trigger.type === 'broadcast' || props.trigger.type === 'list-broadcast') {
      return (
        <div className="trigger-box">
        {boxData.description && <div className="trigger-box__description">{boxData.description}.</div>}
      </div>

      )
    }

    if (props?.trigger?.trigger?.key === 'onEvent') {
      let dayparting = '.';
      let dayparting_list = null;
      if (props?.trigger?.repeats.length) {
        dayparting = ' only on the following days: ';
        dayparting_list = props?.trigger?.repeats?.map((repeat: any, idx: number) => (
          <div key={`repeat-${idx}`} className="trigger-box__dayparting">
            Every {repeat.weekday.name} {moment(repeat.from).format('hh:mmA')} - {moment(repeat.to).format('hh:mmA')}
          </div>
        ));
      }

      return (
        <div className="trigger-box">
          <div className="trigger-box__description">
            Trigger experience immediately when a user joins the target audience{dayparting}
          </div>
          {dayparting_list}
        </div>
      );
    }
    if (props?.trigger?.trigger?.key === 'before' || props?.trigger?.trigger?.key === 'after') {
      let templateType = props?.trigger?.type;
      let eventPoint = props?.trigger?.trigger?.key;
      let eventName = '';
      plugins?.forEach((plugin: any) => {
        for (let p in plugin.values) {
          if (plugin.values[p].isTimeContext && plugin.values[p].uid === props?.trigger?.context?.plugin?.key) {
            eventName = plugin.values[p].name;
          }
        }
      });
      let periodName = props?.trigger?.context?.period?.key;
      periodName = parseInt(props?.trigger?.context?.value) > 1 ? `${periodName}s` : periodName;
      let eventPeriod = `${props?.trigger?.context?.value} ${periodName}`;
      let endPeriod = '';
      if (templateType === '') {
        // if In-App content template selected
        endPeriod = ` and end experience on ${eventName}`;
        if (eventPoint === 'after') {
          eventPeriod = '';
          eventPoint = ' on ';
          endPeriod = ` and end experience ${eventPeriod} after ${eventName}`;
        }
      }
      return (
        <div className="trigger-box">
          {boxData.description && (
            <div className="trigger-box__description">
              Trigger experience {eventPeriod} {eventPoint} user’s {eventName}
              {endPeriod}.
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="trigger-box">
        {boxData.description && <div className="trigger-box__description">{boxData.description}.</div>}
      </div>
    );
  }

  function onApplyHandle(data: any) {
    reduxTemplatedExperienceAction.setStepTrigger({ stepId: props.id, payload: data });
    setIsTriggerModalVisible(false);
  }

  function showContentModal() {
    if ((props.trigger?.type === 'triggered' || props.trigger?.type === '') &&
      !props.experience?.activation?.manualActivationAt &&
      !props.experience?.activation?.automaticActivationAt) {
      setIsTriggerModalVisible(true);
    }
  }

  const handleCancel = () => {
    setIsTriggerModalVisible(false);
  };

  function defineTemplateType() {
    if (props.trigger?.type === 'reminder') {
      setBoxData({
        ...boxData,
        icon: TriggeredIcon,
        title: 'Triggered Delivery',
        summary: true,
        description: 'Trigger the Experience immediately when a user joins the target audience',
      });
    }
    if (props.trigger?.type === 'triggered' || props.trigger?.type === '') {
      setBoxData({
        ...boxData,
        icon: TriggeredIcon,
        title: 'Triggered Delivery',
        description: 'Trigger the Experience immediately when a user joins the target audience',
        configButton: true,
      });
    }
    if (props.trigger?.type === 'broadcast' || props.trigger?.type === 'list-broadcast') {
      setBoxData({
        ...boxData,
        icon: TriggerBroadcast,
        title: 'Scheduled Delivery',
        description: 'Send one push notification to the target audience all at the same time',
      });
    }
  }

  useEffect(() => {
    defineTemplateType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trigger.type]);

  return (
    <React.Fragment>
      <FlowBox
        {...props}
        type={boxData.title}
        icon={boxData.icon}
        body={body()}
        placeholder={body()}
        onBoxClick={showContentModal}
        hideArrow={isInAppContentTemplate(props)? true : false}
        disabled={props.trigger.type === 'reminder'!! || props.trigger.type === 'broadcast'!!}
      />

      {isInAppContentTemplate(props) ? (
        <div style={{ marginTop: '80px', marginLeft: '-20px' }}>
           <Arrow />
        </div>
      ) : null}

      <TriggerEditModal
        size="medium"
        icon="triggered-icon.svg"
        title="Triggered Delivery"
        className=""
        body={props}
        isVisible={isTriggerModalVisible}
        primaryButton={{
          name: 'Apply',
          onClick: (data: any) => onApplyHandle(data),
          disabled: false,
        }}
        cancelButton={{
          name: 'Cancel',
          onClick: () => handleCancel(),
        }}
      />
    </React.Fragment>
  );
}
