
import React, { useState } from 'react'

import { Location } from 'interface/location/location.interface';
import GoogleMapSnapshot from 'components/GoogleMapSnapshot/GoogleMapSnapshot';
import { FlightTag } from '@flybits/webapp-design-system-react';
import moment from 'moment';

import './LocationPreview.scss'

interface IProps {
  location: Location;
  fullScreen?: boolean;
  stringRepresentation?: string | undefined;
}

function LocationPreviewBody({location, fullScreen, stringRepresentation}: IProps) {
  return (
    <div className={`location-preview__body ${fullScreen ? 'full-screen' : ''}`}>
      <div className="info">
        <div className="name"> <b> {location.name} </b> </div>
        <div className="address"> {location.address} </div>
        <div className="label">
          {location.labels.map((label: string, idx: number) => (
            <FlightTag
              className="label-tag"
              label={label}
              key={`label_${idx}`}/>
          ))}
        </div>
        <div className="created_at"> Created At {moment(location.createdAt * 1000).format('YYYY-MM-DD hh:mm:ss A')} </div>
      </div>
      <div className="map">
        <GoogleMapSnapshot location={location} stringRepresentation={stringRepresentation}/>
      </div>
    </div>
  )
}

export default function LocationPreview(props: IProps) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="location-preview">
      <span
        className="location-preview__trigger"
        onMouseMove={() => !isActive ? setIsActive(true) : ''}
        onMouseLeave={() => isActive ? setIsActive(false) : ''}
      > <u> {props.location.name} </u> </span>
      {isActive && (
        <LocationPreviewBody
          fullScreen={props.fullScreen}
          location={props.location}
          stringRepresentation={props.stringRepresentation || undefined}
        />
      )}
    </div>
  )
}
