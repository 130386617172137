import validator from 'validator'; // https://github.com/validatorjs/validator.js
import { getPushType, getPushPayload } from 'helpers/templated-experience.helper';

// Perform validation based on the schema above.
export function isPushValid(action: any) {
  const pushType = getPushType(action);
  switch (pushType) {
    case 'weblink':
      return validateWeblinkForm(action);
    case 'basic':
      return validateBasicForm(action);
    case 'content':
      return validateContentForm(action);
    default:
      return validateCustomForm(action);
  }
}

export function validateBasicForm(action: any) {
  const payload = getPushPayload(action);
  const data = {
    title: { value: payload.title.en },
    alert: { value: payload.alert.en },
  };
  return isValid(basicFormSchema(data));
}

// get languages and interate through all of them for url
export function validateWeblinkForm(action: any) {
  const payload = getPushPayload(action);
  const data = {
    title: { value: payload.title.en },
    alert: { value: payload.title.en },
    url: { value: payload.data.url.en },
  };
  return isValid(weblinkFormSchema(data));
}

export function validateContentForm(action: any) {
  const payload = getPushPayload(action);
  const data = {
    title: { value: payload.title.en },
    alert: { value: payload.title.en },
    contentId: { value : payload.data.contentId.value },
  };
  return isValid(contentFormSchema(data));
}

export function validateCustomForm(action: any) {
  const payload = getPushPayload(action);
  let keys = Object.keys(payload.data);
  let newObj:any = {}
  keys.forEach(key => newObj[key] = {value: payload.data[key]});
  const data = {...newObj};
  return isValid(customFormSchema(data));
}

export function basicFormSchema(data: any) {
  return {
    title: () => [[validator.isLength(data.title.value, { min: 1, max: 170 }), 'Required Field']],
    alert: () => [[validator.isLength(data.alert.value, { min: 1, max: 170 }), 'Required Field']],
  };
}

export function basicFormSchemaDraft(data: any) {
  return {
    title: () => [[validator.isLength(data.title.value, { min: 0, max: 170 }), 'Cannot exceed 170 characters']],
    alert: () => [[validator.isLength(data.alert.value, { min: 0, max: 170 }), 'Cannot exceed 170 characters']],
  };
}

export function customFormSchema(data: any) {
  let keys = Object.keys(data);
  let dataObj:any = {};
  keys.forEach(key => dataObj[key] = () => [[!validator.isEmpty(data[key]?.value), 'Required field']])
  return {
    ...dataObj
  };
}

export function customFormSchemaDraft(data: any) {
  let keys = Object.keys(data);
  let dataObj:any = {};
  keys.forEach(key => dataObj[key] = () => [[!validator.isEmpty(data[key]?.value), 'Required field']])
  return {
    ...dataObj
  };
}

export function weblinkFormSchema(data: any) {
  return {
    ...basicFormSchema(data),
    url: () => [
      [validator.isLength(data.url.value, { min: 1, max: 170 }), 'Required Field'],
      [validator.isURL(data.url.value), 'Must be in URL Format'],
    ],
  };
}

export function weblinkFormSchemaDraft(data: any) {

  let fixed: any = {
    ...basicFormSchemaDraft(data),
    url: () => [[validator.isLength(data.url.value, { min: 0, max: 170 }), 'Cannot exceed 170 characters']],
  };

  if (data.url.value) {
    fixed.url = () => [
      [validator.isLength(data.url.value, { min: 0, max: 170 }), 'Cannot exceed 170 characters'],
      [validator.isURL(data.url.value), 'Must be in URL Format'],
    ];
  }

  return fixed;
}

export function contentFormSchema(data: any) {
  return {
    ...basicFormSchema(data),
    contentId: () => [[validator.isLength(data?.contentId?.value, {min: 1}), 'Required Field']]
  };
}

export function contentFormSchemaDraft(data: any) {
  return {
    ...basicFormSchemaDraft(data),
    contentId: () => []
  }
}

function isValid(validationArr: any) {
  return !Object.values(validationArr)
    .map((fn: any) => fn())
    .map((arr: any) => arr.filter((res: any) => res[0] === false).length)
    .some((err: number) => err > 0);
}
