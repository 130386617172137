
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSetting from 'hooks/useSetting';

import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { getPushType } from 'helpers/templated-experience.helper';
import { isEmpty } from 'lodash';
import { fetchPushTypesThunk } from 'redux/templated-experience/templated-experience.thunk';
export const PushFormContext = React.createContext({});

// This Provider must be updated and maintained when new fields are added to push form.
// Form data and functions are accessible via useContext
export default function PushFormProvider(props: any) {

  const { languages, isInitialized } = useSetting();
  const dispatch = useDispatch();
  const location = useLocation();

  const [notificationOptions, setNotificationOptions] = useState([
    { key: 'basic', name: 'App Home Screen' },
    { key: 'weblink', name: 'Weblink' },
    { key: 'content', name: 'In-App Content' },
  ]);
  const [customPushType, setCustomPushType] = useState<any>(null);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [selectedNotificationType, setSelectedNotificationType] = useState<any>(null);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const templatedExperience = location.pathname.indexOf('edit') > -1
    ? reduxTemplatedExperienceState.instance
    : reduxTemplatedExperienceState.template;

  // Form
  const isDraft = ['Draft', '', undefined].includes(templatedExperience?.status);
  const validateOnLoad = templatedExperience?.showError && templatedExperience?.incompleteCount > 0;

  const [activeTabKey, setActiveTabKey] = useState(0);
  const [formFields, setFormFields] = useState<any>({});
  const [validators, setValidators] = useState<any>({});
  const [selectedLang, setSelectedLang] = useState<any>();

  // List of all fields that user can possibly enter in push message (For now this is static - not handling custom / dynamically loaded)
  const multiLanguageFields = ['title', 'alert', 'url'];
  const singleLanguageFields = ['contentId'];

  // Delivery
  const [deliveryData, setDeliveryData] = useState<any>({});

  useEffect (()=> {
    async function getPushTypes() {
      let customPushOptions:any = await dispatch(fetchPushTypesThunk());
      if(customPushOptions) {
        const filteredPushTypes:any = [];
        customPushOptions.forEach((customPushType:any) => {
          if(customPushType.name !== 'Content' && customPushType.name !== 'Web Link') {
            filteredPushTypes.push({key: customPushType.id, name: customPushType.name});
          }
        });
        setNotificationOptions([...notificationOptions, ...filteredPushTypes]);
      }
    }
    getPushTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pushType = getPushType(props.action);
    setSelectedNotificationType(notificationOptions.find((option) => option.key === pushType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationOptions]);

  useEffect(() => {
    let isCustomTypeSelected = reduxTemplatedExperienceState.pushTypes?.find((customPushType:any) => customPushType.id === selectedNotificationType?.key)
    if(isCustomTypeSelected) {
      const payload = props.action?.actionBody?.body[0]?.payload;
      let keys = Object.keys(isCustomTypeSelected?.schema);
      let dataObj:any = {};
      keys.forEach(key => dataObj[key] = payload.data?.[key] || '');
      setFormFields({...formFields, data: {pushPayloadTypeId: isCustomTypeSelected.id, ...dataObj}});
    } else {
      if(formFields.data?.pushPayloadTypeId) {
        delete formFields.data
        setFormFields({...formFields});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[notificationOptions, selectedNotificationType?.key]);

  useEffect(() => {
    setSelectedLang(languages[activeTabKey]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabKey]);

  useEffect(() => {
    if (!isInitialized) return;
    setSelectedLang(languages[activeTabKey]?.id);
    let payload = props?.action?.actionBody?.body[0]?.payload;
    if(!payload) {
      const _amplifiedPush = props?.action?.find((item : any) => item.actionType === "push");
      payload = _amplifiedPush?.actionBody?.body[0]?.payload
    }
    const langs = languages.map((language: any) => language.id); // en, fr, es

    // Initialize multi-language fields
    langs.forEach((lng: string) => {
      multiLanguageFields.forEach((field: string) => {
        if (!formFields[field]) formFields[field] = {};
        if (field === 'title') {
          formFields[field][lng] = payload?.title?.[lng] || '';
        } else if (field === 'alert') {
          formFields[field][lng] = payload?.alert?.[lng] || '';
        } else if (field === 'url') {
          formFields[field][lng] = payload?.data?.url?.[lng] || '';
        }
      });
    });

    // Initialize single language fields
    singleLanguageFields.forEach((field: string) => {
      if (field === 'contentId') {
        formFields[field] = payload?.data?.contentId?.value || '';
      }
    });

    // If custom field
    if(payload?.data?.pushPayloadTypeId) {
      formFields['data'] = payload.data;
    }

    setFormFields({...formFields});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  // Register validator for each corresponding language form
  function registerValidator (language: any, formValidator: any) {
    setValidators((prevState: any) => ({...prevState, [language]: formValidator}));
  }

  function validateForm(cb: any) {
    return () => {
      const langs = languages.map((language: any) => language.id); // get language codes
      // const keys = Object.keys(validators); // get language codes
      const promises = langs.map((lang: string) => validators[lang]().then((r: any) => r)); // run form validators for each language
      // const promises = keys.map((lang: string) => validators[lang]().then((r: any) => r)); // run form validators for each language
      Promise.all(promises).then(async (arr: any) => {
        if (arr.every((bool: boolean) => bool === true)) { // all forms are valid
          if (!isEmpty(deliveryData)) {
            await reduxTemplatedExperienceAction.setTempDelivery({
              ...deliveryData
            });
          }
          cb(formFields, selectedNotificationType?.key);
        } else { // there is an invalid form
          const errorTabIndex = arr.indexOf(false);
          setActiveTabKey(errorTabIndex);
        }
      });
    }
  }

  function updateFormField(key: string, value: string) {
    setFormFields({...formFields,
      [key]: {
        ...formFields[key],
        [selectedLang]: value,
      }
    })
  }


  function updateCustomField(key: string, value: object) {
    setFormFields({...formFields,
      [key]: {
        ...formFields[key],
        ...value
      }
    })
  }

  // Amplification
  const [isAmplified, setIsAmplified] = useState(false);
  useEffect(() => {
    if(props?.amplified) {
      setIsAmplified(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.amplified])

  return (
      <PushFormContext.Provider value={{
        isInitialized,
        isDraft,
        validateOnLoad,
        activeTabKey,
        languages,
        selectedLang,
        formFields,
        notificationOptions,
        setNotificationOptions,
        selectedNotificationType,
        setSelectedNotificationType,
        updateFormField,
        updateCustomField,
        setActiveTabKey,
        registerValidator,
        validateForm,
        // Delivery
        action: props.action,
        deliveryData,
        setDeliveryData,
        customPushType,
        setCustomPushType,
        // Amplification
        isAmplified,
      }}>
        {!isEmpty(formFields) && props.children}
      </PushFormContext.Provider>
  );
}
