import React, { useEffect, useState } from 'react';
import { FlightButton } from '@flybits/webapp-design-system-react';
import { times, uniqBy } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@material-ui/core';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import { Content, ContentPreview } from 'interface/content/content.interface';
import StarterContentPreview from 'components/Modal/ContentModal/ContentModalPreview/StarterContentPreview';
import ContentAPI from 'services/api/content.api';
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';
import { ReactComponent as Shadow } from 'assets/icons/ellipse.svg';
import { ReactComponent as FlybitsIcon } from 'assets/icons/icon_flybits.svg';
import { ReactComponent as ButtonLinkIcon } from 'assets/icons/buttonlink.svg';
import { ReactComponent as TemplateIcon } from 'assets/icons/template.svg';
import { ReactComponent as TextLinkIcon } from 'assets/icons/textlink.svg';
import headerImg from 'assets/images/button-preview-ios.svg';
import ContentEditorModal from 'components/Modal/ContentModal/ContentEditorModal/ContentEditorModal';
import './ContentActionEdit.scss';
import FullPageOverlay from 'components/FullPageOverlay/FullPageOverlay';
import ContentIframeParent from 'components/Content/ContentIframeParent/ContentIframeParent';

interface IProps {
  onApply: (formData: any) => void;
  step: any;
  selectedActionIndex: number;
  isCalledFromPush?: boolean;
  getContentIdOnClick?: (formData: any) => void;
  initialSearchTerm?: string;
}

interface PaginatedContent {
  total: 0;
  content: Content[];
}

interface IframeState {
  status: boolean;
  id: string;
}

export default function ContentActionEdit({
  onApply,
  step,
  selectedActionIndex,
  isCalledFromPush,
  getContentIdOnClick,
  initialSearchTerm,
}: IProps) {
  const contentAPI = new ContentAPI();
  const [content, setContent] = useState<PaginatedContent>({ total: 0, content: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
  const [showTemplates, setShowtemplates] = useState(false);
  const [selectedContent, setSelectedContent] = useState<Content | null>(null);
  const [contentForPreview, setContentForPreview] = useState<Content | null>(null);
  const [previewContent, setPreviewContent] = useState<ContentPreview>();
  const [formData, setFormData] = useState<any>();
  const [isContentIframeVisible, setIsContentIframeVisible] = useState(false);
  const [contentForIframe, setContentForIframe] = useState({
    id: '',
    isEdit: false,
    payload: { name: '', description: '', iconUrl: '', labels: [], isContextLocked: false },
  });
  const [isIframeSuccess, setIsFrameSuccess] = useState<IframeState | null>(null);
  const [isEditorModalVisible, setEditorModal] = useState(false);
  const [templateContent, setTemplateContent] = useState<Content | null>();

  const defaultData = {
    contentId: '',
  };

  useEffect(() => {
    if (isCalledFromPush && getContentIdOnClick) {
      getContentIdOnClick(selectedContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContent]);

  useEffect(() => {
    if (selectedActionIndex === -1) {
      setFormData(defaultData);
    } else setFormData(step?.actions[selectedActionIndex]?.data?.contentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionIndex]);

  useEffect(() => {
    let langCode = '';
    if (!contentForPreview?.content) {
      return;
    }
    if (contentForPreview?.content?.data[0].localizations) {
      const langKeys = Object.keys(contentForPreview?.content?.data[0].localizations);
      // use english lang index type (if it exists, else default to first)
      langKeys.find((lang) => lang === 'en') ? (langCode = 'en') : (langCode = langKeys[0]);
    }

    setPreviewContent({
      headerImg: contentForPreview?.content?.data[0].localizations?.[langCode]?.image,
      header: contentForPreview?.content?.data[0]?.localizations?.[langCode]?.header,
      title: contentForPreview?.content?.data[0]?.localizations?.[langCode]?.title,
      body: contentForPreview?.content?.data[0]?.localizations?.[langCode]?.description,
      type: contentForPreview?.templateType,
      isContentInstance: !showTemplates,
      isStarter: contentForPreview?.labels?.includes('starter') ? true : false,
      primaryButtonLabel:
        contentForPreview?.templateType === 'concierge-card-buttons' &&
        contentForPreview?.content?.data[0]?.buttons.length !== 0
          ? contentForPreview?.content?.data[0]?.buttons[0]?.localizations?.[langCode]?.text
          : '',
      secondaryButtonLabel:
        contentForPreview?.templateType === 'concierge-card-buttons' &&
        contentForPreview?.content?.data[0]?.buttons.length !== 0
          ? contentForPreview?.content?.data[0]?.buttons[1]?.localizations?.[langCode]?.text
          : '',
      details:
        contentForPreview?.templateType === 'concierge-card-buttons'
          ? contentForPreview?.content?.data[0]?.details?.localizations?.[langCode]?.body
          : '',
      linkLabel:
        contentForPreview?.templateType === 'concierge-card-link'
          ? contentForPreview?.content?.data[0]?.link?.localizations?.[langCode]?.text
          : '',
      unSupportedTitle: contentForPreview?.labels?.includes('starter') ? '' : contentForPreview?.localizations?.en.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentForPreview]);

  useEffect(() => {
    //populate default template preview on OOTB starter templates
    if (showTemplates) {
      setPreviewContent({
        headerImg: headerImg,
        header: 'Inform Customers',
        title: selectedContent?.localizations?.en?.name,
        body: selectedContent?.localizations?.en?.description,
        type: selectedContent?.type,
        isContentInstance: showTemplates,
        isStarter: selectedContent?.labels?.includes('starter') ? true : false,
        primaryButtonLabel: showTemplates ? '' : 'Activate',
        secondaryButtonLabel: showTemplates ? '' : 'Learn More',
        linkLabel: showTemplates ? '' : 'Learn More',
      });
    } else {
      setPreviewContent({
        isContentInstance: true,
        isStarter: selectedContent?.labels?.includes('starter') ? true : false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContent]);

  const handleSearchInput = (searchTerm: any) => {
    setSearchTerm(searchTerm);
  };

  function renderCardIcon(item: Content) {
    let className = 'multistep_content-list__content__left__container__item__header-icon';
    switch (item.type) {
      case 'concierge-card-link':
        return <TextLinkIcon className={className} />;
      case 'concierge-card-buttons':
        return <ButtonLinkIcon className={className} />;
      default:
        return <TemplateIcon className={className} />;
    }
  }

  function onApplyHandle(content: any) {
    setShowtemplates(false);
    setContentForIframe({
      ...contentForIframe,
      id: content[0].id,
      isEdit: !showTemplates ? true : false,
      payload: content[0],
    });
    setIsContentIframeVisible(true);
    setIsFrameSuccess(null);
  }

  const fetchContentInstance = async (id: string) => {
    if (id !== '') {
      await contentAPI.getInstance(id).then((res: any) => {
        setContentForPreview(res);
      });
    }
  };

  function ListItem(item: Content, index: number, isContentInstance: boolean) {
    let className = 'multistep_content-list__content__left__container__item';

    const handleEditInstance = (e: any, editContentInstance: Content) => {
      e.stopPropagation();
      setEditorModal(true);
      setTemplateContent(editContentInstance);
      setSelectedContent(null);
    };

    const handleSelection = async (item: Content | null, isCalledByClick?: boolean) => {
      if (!showTemplates) {
        //early return prevent repeated api calls on redundant clicks
        const fetchForSameId = selectedContent?.id === item?.id;
        if (fetchForSameId && isCalledByClick) {
          return;
        } else if (item?.id) {
          fetchContentInstance(item?.id);
        }
      }
      setSelectedContent(item);
    };

    return !isContentInstance ? (
      <div
        tabIndex={0}
        className={selectedContent?.id === item.id ? className + '--selected' : className}
        onClick={() => handleSelection(item, true)}
        key={`modal__item-${index}`}
      >
        <div className={className + '__title'}>
          {renderCardIcon(item)}
          {item?.localizations?.en?.name}
        </div>
        <div className={className + '__body'}>{item.localizations.en.description}</div>
        {item.labels.includes('starter') ? <FlybitsIcon className={className + '__icon'} /> : null}
      </div>
    ) : (
      <div key={`modal__item-${index}`}>
        <div
          tabIndex={0}
          className={selectedContent?.id === item.id ? className + '--selected' : className}
          onClick={() => handleSelection(item, true)}
        >
          <span>
            {item?.localizations?.en?.name}
            <div className={className + `--edit`} onClick={(e) => handleEditInstance(e, item)}>
              <Shadow className={className + `--edit__shadow`} />
              <EditPen className={className + `--edit__pen`} />
            </div>
          </span>
        </div>
      </div>
    );
  }

  let limit = 7;
  const getHasMore = () => {
    return content?.total > content?.content?.length ? true : false;
  };

  const handleFetchMoreData = async () => {
    if (getHasMore()) {
      const incrementPage = page + 7;
      setContent({ content: content?.content, total: content?.total });
      setTimeout(async () => {
        await fetchContent(incrementPage);
        setPage(incrementPage);
      }, 600);
    }
  };

  const setContentInstances = (res: any) => {
    if ((content.content && content.content.length !== 0 && !content.content[0]?.templateId) || searchTerm !== '') {
      return res.data;
    } else {
      return uniqBy(content?.content ? [...res.data, ...content.content] : res.data, 'id');
    }
  };

  const setContentTemplates = (res: any) => {
    if (content?.content[0]?.templateId || searchTerm !== '') {
      return res.data;
    } else {
      return uniqBy(content?.content ? [...content.content, ...res.data] : res.data, 'id');
    }
  };

  const fetchContent = async (idx: number) => {
    if (!showTemplates) {
      setIsLoading(true);
      let params = { offset: idx, limit: limit, search: searchTerm };
      if (searchTerm !== '') {
        //skip pagination for searchable queries
        params = { offset: 0, limit: 10000, search: searchTerm };
      }
      await contentAPI.getContentInstances(params).then((res: any) => {
        if (res.data.length !== 0) {
          setContent(() => {
            return {
              content: setContentInstances(res),
              total: res.pagination.totalRecords,
            };
          });
        } else {
          setContent({ total: 0, content: [] });
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
      const params = { offset: idx, limit: limit, search: searchTerm };
      await contentAPI.getContentTemplates(params).then((res: any) => {
        if (res.data.length !== 0) {
          setContent(() => {
            return {
              content: setContentTemplates(res),
              total: res.pagination.totalRecords,
            };
          });
        }
        setIsLoading(false);
      });
    }
  };

  const resetDeps = () => {
    setContent({ total: 0, content: [] });
    setPage(0);
    setContentForPreview(null);
    setPreviewContent(undefined);
    setSelectedContent(null);
  };

  const onIframeCancel = () => {
    setIsContentIframeVisible(false);
    setIsFrameSuccess(null);
  };

  const onIframeSuccess = (id: string) => {
    setIsFrameSuccess({ status: true, id: id });
    setIsContentIframeVisible(false);
    if (isIframeSuccess) {
      fetchContent(page);
    }
  };

  useEffect(() => {
    resetDeps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTemplates]);

  useEffect(() => {
    fetchContent(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTemplates, searchTerm, isIframeSuccess]);

  const handleEditorClose = () => {
    setEditorModal(false);
  };

  const handleEditorOnNext = (args: any) => {
    setEditorModal(false);
    onApplyHandle(args);
  };

  const handleCreateContent = (editContentInstance: Content | null) => {
    setEditorModal(true);
    setTemplateContent(editContentInstance);
    setSelectedContent(null);
  };

  const handleOnApply = () => {
    if (!showTemplates) {
      onApply({ contentId: selectedContent?.id });
      setFormData(defaultData);
    } else {
      handleCreateContent(selectedContent);
    }
  };

  useEffect(() => {
    if (formData) {
      const getSelectedContent = content.content.find((template) => template.id === formData) || null;
      fetchContentInstance(formData);
      setSelectedContent(getSelectedContent);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.content.length]);

  return (
    <>
      <div className="multistep_content-list__content">
        <div className="multistep_content-list__content__left">
          <div className="multistep_content-list__content__left__container">
            <InfiniteScroll
              pageStart={page}
              initialLoad={false}
              loadMore={handleFetchMoreData}
              hasMore={getHasMore()}
              threshold={20}
              element={'div'}
              useWindow={false}
              loader={
                <div className="multistep_content-list__content__left__container__item__loader" key={0}>
                  <CircularProgress size={20} />
                  <span className="multistep_content-list__content__left__container__item__loader-text">
                    Loading...
                  </span>
                </div>
              }
            >
              {!initialSearchTerm ? (
                <div className="multistep_content-list__content__left__container__item__search">
                  <SearchBar searchText={(e) => handleSearchInput(e)} />
                </div>
              ) : null}
              <div>
                {content.content &&
                  content.content.map((data: Content, idx: number) => {
                    return ListItem(data, idx, !showTemplates);
                  })}
              </div>
              {/* {Loading states for progressive rendering of paginatedlist} */}
              {isLoading && (
                <>
                  {times(3, (i) => (
                    <div key={i} style={{ marginLeft: 15 }}>
                      <Skeleton width={'100%'} height={50} />
                    </div>
                  ))}
                </>
              )}
            </InfiniteScroll>
            {content.content && content.content.length === 0 && !isLoading ? <div>No results to display</div> : null}
          </div>
        </div>
        <div className="multistep_content-list__content__right">
          <StarterContentPreview
            isContentInstance={!showTemplates || false}
            headerImg={previewContent?.headerImg}
            header={previewContent?.header}
            title={previewContent?.title}
            body={previewContent?.body}
            primaryButtonLabel={previewContent?.primaryButtonLabel}
            secondaryButtonLabel={previewContent?.secondaryButtonLabel}
            details={previewContent?.details}
            type={previewContent?.type}
            isStarter={previewContent?.isStarter || false}
            unSupportedTitle={previewContent?.unSupportedTitle || ''}
            linkLabel={previewContent?.linkLabel}
          />
        </div>
        <div className="multistep_content-list__footer">
          <FlightButton
            label={!showTemplates ? 'Create Content' : 'Back'}
            onClick={() => setShowtemplates((showTemplates) => !showTemplates)}
          />
          {isCalledFromPush ? (
            showTemplates ? (
              <FlightButton label={'Next'} disabled={!selectedContent?.id ? true : false} onClick={handleOnApply} />
            ) : (
              ''
            )
          ) : (
            <FlightButton
              label={!showTemplates ? 'Apply' : 'Next'}
              disabled={!selectedContent?.id ? true : false}
              onClick={handleOnApply}
            />
          )}
        </div>
        <ContentEditorModal
          isVisible={isEditorModalVisible}
          title={templateContent?.templateType ? 'Edit Content' : 'New Content'}
          onCancel={handleEditorClose}
          content={templateContent || undefined}
          onNext={(e: any) => handleEditorOnNext(e)}
        />
        <FullPageOverlay isVisible={isContentIframeVisible}>
          <ContentIframeParent
            onSubmit={(id) => onIframeSuccess(id)}
            onCancel={() => onIframeCancel()}
            id={contentForIframe.id}
            payload={contentForIframe.payload}
            isEdit={contentForIframe.isEdit}
          />
        </FullPageOverlay>
      </div>
    </>
  );
}
