import React, { useState, useContext } from 'react';
import MultiActionPushPreview from 'components/PushMessage/PreviewPush/MultiActionPushPreview';
import { ReactComponent as PushIcon } from 'assets/icons/push.svg';
import './AmplifiedPush.scss';
import PushEditModal from 'components/Modal/EditModal/PushEditModal/PushEditModal';
import { useSelector } from 'react-redux';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
import { ContentContext } from 'components/FlowBox/ContentBox/ContentBox';
import PushInterval from 'components/PushMessage/PushInterval/PushInterval';
import useActions from 'hooks/useActions';
import { AmplificationBar } from './AmplificationBar';

interface PushPreviewProps {
  hidePreview?: boolean;
  contentId?: string;
}

export const AmplifiedPushPreview = (props: PushPreviewProps) => {
  let pushState;
  let title;
  let message;
  const [openPushModal, setOpenPushModal] = useState(false);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const context: any = useContext(ContentContext);
  const getInstanceOrTemplate =
    (reduxTemplatedExperienceState?.instance?.steps?.[0]) ||
    (reduxTemplatedExperienceState?.template?.steps?.[0]);

  const getAmplifiedPushFromRedux = getInstanceOrTemplate?.actions?.find((item: any) => item?.actionType === 'push');
  const isPushAttachedToInstance =
    getAmplifiedPushFromRedux?.actionBody?.body?.[0]?.payload?.data?.contentId?.value === props?.contentId;

  const amplifiedContentExists = getInstanceOrTemplate?.actions.length > 1;

  if (amplifiedContentExists) {
    pushState = getInstanceOrTemplate?.actions?.find((item: any) => item?.actionType === 'push');
    title = pushState?.actionBody?.body?.[0]?.payload?.title?.en;
    message = pushState?.actionBody?.body?.[0]?.payload?.alert?.en;
  }

  const pushActionBody = {
    actionID: getAmplifiedPushFromRedux?.actionID || '00000000-0000-0000-0000-000000000000',
    actionType: 'push',
    actionBody: {
      body: [
        {
          weight: 100,
          payload: {
            alert: {},
            title: {},
            data: {
              contentId: {
                value: context.selectedContent,
              },
            },
            action: 'custom',
            entity: '',
            category: '',
          },
        },
      ],
      name: 'Triggered Push In-App Content',
      tags: [],
      labels: [],
      delivery: {
        delay: 0,
        limit: 0,
        remain: false,
        frequency: {
          total: 0,
          value: 0,
        },
      },
      keywords: [],
      userScope: false,
      trigger: {type: "triggered"}
    },
  };

  const onApplyHandle = (form: any) => {
    reduxTemplatedExperienceAction.setAmplifiedPush(pushActionBody, form);
    setOpenPushModal(false);
  };

  const handleOpenPushModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    context.setSelectedContent(getAmplifiedPushFromRedux?.actionBody?.body[0]?.payload?.data?.contentId?.value || props?.contentId);
    setOpenPushModal(true);
  };

  const pushModalProps = {
    action: getAmplifiedPushFromRedux,
    isVisible: openPushModal,
    amplified: true,
    onApply: onApplyHandle,
    onCancel: () => setOpenPushModal(false),
  };

  const handleKeyDown = (event: any) => {
    if(event.key === "Enter") {
      handleOpenPushModal(event);
    }
  }

  return (
    <>
      {isPushAttachedToInstance ? (
        <>
          <AmplificationBar />
          <div className="amplified-push-preview__push-preview" onClick={handleOpenPushModal}>
            <PushEditModal {...pushModalProps} />
            <div className="amplified-push-preview__push-preview__hoverable" />
            <PushIcon className="amplified-push-preview__push-preview__icon" />{' '}
            <span className="amplified-push-preview__push-preview__title">Push notification</span>
            <div className="amplified-push-preview__push-preview__message">{message}</div>
            {!props.hidePreview ? (
              <>
              <MultiActionPushPreview title={title} message={message} placement="box" onClick={() => undefined} />
              <PushInterval {...getAmplifiedPushFromRedux} type="Push Notification" />
              </>
            ) : null}
          </div>{' '}
        </>
      ) : null}
      {!amplifiedContentExists ? (
        <div onClick={handleOpenPushModal}>
          <PushEditModal {...pushModalProps} />
          <div className="amplified-push-preview__push-preview__add-amplification" tabIndex={0} onKeyDown={handleKeyDown}>        
            + Amplify with push notification
          </div>
        </div>
      ) : null}
    </>
  );
};
