import React from 'react';
import './LibraryBanner.scss';

interface LibraryBannerProps {
  name: string;
  description: string;
  className?: string;
}

export const LibraryBanner = (props: LibraryBannerProps) => {
  const { name, description, className } = props;
  const MAIN_CLASS = 'library-banner';
  return (
    <div className={MAIN_CLASS + (className ? ` ${className}` : '')}>
      <div className={`${MAIN_CLASS}__name`}>{name}</div>
    </div>
  );
};
