import React, { useState, createContext, useEffect } from 'react';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import MultiActionArrowIcon from 'assets/icons/multiactionarrow.svg';

import ContentModal from 'components/Modal/ContentModal/ContentModal';
import FullPageOverlay from 'components/FullPageOverlay/FullPageOverlay';
import ContentIframeParent from 'components/Content/ContentIframeParent/ContentIframeParent';
import InAppContentIcon from 'assets/icons/in-app.svg';

import MultiActionContainer from 'components/Shared/MultiActionContainer/MultiActionContainer';
import { merge } from 'lodash';
import MiniCard from 'components/Shared/MiniCard/MiniCard';
import { isContentValid } from 'validator/content.validator';
import { MiniCardContent } from 'components/Content/MiniCardContent/MiniCardContent';
import { Content } from 'interface/content/content.interface';

interface IframeState {
  status: boolean;
  id: string;
}

interface ContentContextProps {
  contents: [];
  onClick: any;
  selectedContent: string;
  setSelectedContent: React.Dispatch<React.SetStateAction<string>>;
  hasError: boolean;
}

export const ContentContext = createContext({
  contents: [],
  onClick: (selectedContent?: any) => {
    return selectedContent;
  },
  selectedContent: '',
  setSelectedContent: () => {},
  hasError: false,
  userClickedOnPush: false,
  setUserClickedOnPush: () => {},
} as ContentContextProps);

const RenderMiniCards = () => {
  return (
    <ContentContext.Consumer>
      {(valuesFromContext) =>
        valuesFromContext.contents ? (
          //render multi content instances if they exist.
          valuesFromContext?.contents?.map((content: Content, index) => {
            return (
              <div key={`multi-content-instance-${index}`} >
                <MiniCard title={'In-app Content'} icon={InAppContentIcon} type="content" id={content.id} onClick={() => valuesFromContext.onClick(content)}>
                  <MiniCardContent content={content} hideCollapse={valuesFromContext?.contents?.length <= 2} />
                </MiniCard>
              </div>
            );
          })
        ) : (
          //else render 'add content' instance
          <MiniCard title={'In-app content'} icon={InAppContentIcon} onClick={valuesFromContext.onClick} type="content">
            <MiniCardContent hideCollapse={true} />
          </MiniCard>
        )
      }
    </ContentContext.Consumer>
  );
};

export default function ContentBox(props: any) {
  const [showContentInstances, setShowContentInstances] = useState(false);
  const [showContentTemplates, setShowContentTemplates] = useState(false);
  const [isContentIframeVisible, setIsContentIframeVisible] = useState(false);
  const [selectedContent, setSelectedContent] = useState('');

  const [contentForIframe, setContentForIframe] = useState({
    id: '',
    isEdit: false,
    payload: { name: '', description: '', iconUrl: '', labels: [], isContextLocked: false },
  });
  const [isIframeSuccess, setIsFrameSuccess] = useState<IframeState | null>(null);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);

  const handleCreateContent = () => {
    setShowContentTemplates((showContentTemplates) => !showContentTemplates);
  };

  const triggerReduxAction = (content: any, deselect?: boolean) => {
    if (props!!.isCalledFromPush) {
      let _content = {
        value: deselect ? '' : content.id,
        entity: 'content',
      };
      merge(props.actionBody?.body[0]?.payload?.data?.contentId, _content);
      reduxTemplatedExperienceAction.setContentPush(props);
    } else {
      reduxTemplatedExperienceAction.setContent(props.actionID, content);
    }
  };

  const onApplyHandle = (content: any) => {
    setShowContentInstances(false);
    setShowContentTemplates(false);
    if (props?.isCalledFromPush) {
      props?.onApply();
    }
    if (content?.templateId || content[0]?.templateId) {
      triggerReduxAction(content);
    } else {
      setContentForIframe({ ...contentForIframe, id: content[0].id, isEdit: false, payload: content[0] });
      setIsContentIframeVisible(true);
      setIsFrameSuccess(null);
    }
  };

  const onEditInstance = (content: any) => {
    setShowContentInstances(false);
    setShowContentTemplates(false);
    setContentForIframe({ ...contentForIframe, id: content[0]?.id, isEdit: true, payload: content[0] });
    setIsContentIframeVisible(true);
    setIsFrameSuccess(null);
  };

  const handleCancel = () => {
    if (props?.isCalledFromPush) {
      props?.modalClosed();
    }
    setShowContentInstances(false);
    setShowContentTemplates(false);
    setIsFrameSuccess(null);
  };

  const handleBack = () => {
    setIsFrameSuccess(null);
    setShowContentTemplates(false);
  };

  const onIframeCancel = () => {
    setIsContentIframeVisible(false);
    setIsFrameSuccess(null);
  };

  const onIframeSuccess = (id: string) => {
    setShowContentInstances(true);
    setIsFrameSuccess({ status: true, id: id });
    props?.requestUpdatedInstance();
    setIsContentIframeVisible(false);
  };

  const openContentModal = (content: Content) => {
    if (!props.isNotEditable) {
      setSelectedContent(content?.id);
      setShowContentInstances(true);
    }
  };

  const contentProps = {
    ...props,
    isVisible: showContentInstances,
    showTemplates: showContentTemplates,
    selectedId:
      (props!!.actionBody?.body?.[0]?.payload?.data?.contentId?.value) ||
      props!!.contentInstance?.id ||
      isIframeSuccess?.id ||
      selectedContent,
    editedInstanceId: isIframeSuccess?.id,
    onCancel: handleCancel,
    isAfterCreate: isIframeSuccess?.status || false,
    onBack: handleBack,
    onApply: (content: any) => onApplyHandle(content),
    onEditInstance: (content: any) => onEditInstance(content),
    onCreateContent: handleCreateContent,
  };

  const contentIframeProps = {
    onSubmit: (id: string) => onIframeSuccess(id),
    onCancel: onIframeCancel,
    id: contentForIframe.id,
    payload: contentForIframe.payload,
    isEdit: contentForIframe.isEdit,
  };

  useEffect(() => {
    if (props.openModal) {
      setShowContentInstances(true);
    }
  }, [props.openModal]);

  const getCollapseState = () => {
    return props?.contents?.length > 2 ? false : true
  }

  return (
    <>
      {!props.hideBox ? (
        <ContentContext.Provider
          value={{
            contents: props.contents,
            onClick: openContentModal,
            selectedContent,
            setSelectedContent,
            hasError: isContentValid(props),
          }}
        >
          <MultiActionContainer {...props} title="Actions" icon={MultiActionArrowIcon} hideCollapse={getCollapseState()}>
            <RenderMiniCards />
          </MultiActionContainer>
        </ContentContext.Provider>
      ) : null}
      <ContentModal {...contentProps} />
      <FullPageOverlay isVisible={isContentIframeVisible}>
        <ContentIframeParent {...contentIframeProps} />
      </FullPageOverlay>
    </>
  );
}
