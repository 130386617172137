
import React, { useState, useEffect, useRef } from 'react';
import { parse, stringify } from 'flatted';
import useFeatureFlag from 'hooks/useFeatureFlag';

import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import AudienceIframe from 'components/Audience/AudienceIframe/AudienceIframe';
import { FlightButton, FlightCheckbox, getIcon } from '@flybits/webapp-design-system-react';
import './AudienceIframeParent.scss'

export default function AudienceIframeParent(props: any) {

  const { flags, traits, setTrait } = useFeatureFlag();
  const [selectedTab, setSelectedTab] = useState("first");
  const [hasRestrictionError, setHasRestrictionError] = useState(false);
  const [hasPreferrredError, setHasPreferredError] = useState(false);
  const [preferredAudienceFirstTimeModalProps, setPreferredAudienceFirstTimeModalProps] = useState({
    isVisible: false,
    cb: () => {}
  });

  interface IFrame {
    iframe: any;
    stringRepresentation: string;
    ruleBody: object;
    hasReceived: boolean;
  }

  // value used by FlightCheckbox component
  const [dontShowPreferredAudienceFirstTime, setDontShowPreferredAudienceFirstTime] = useState('UNSELECTED');

  const [restrictionData, _setRestrictionData] = useState<IFrame>({
    iframe: undefined,
    stringRepresentation: '',
    ruleBody: {},
    hasReceived: false
  });

  const [preferredData, _setPreferredData] = useState<IFrame>({
    iframe: undefined,
    stringRepresentation: '',
    ruleBody: {},
    hasReceived: false
  });

  const restrictionDataRef = useRef(restrictionData);
  const setRestrictionData = (data: any) => {
    restrictionDataRef.current = data;
    _setRestrictionData(data);
  }

  const preferredDataRef = useRef(preferredData);
  const setPreferredData = (data: any) => {
    preferredDataRef.current = data;
    _setPreferredData(data);
  }

  const filterStringRep = (rep: string) => {
    return rep === '()' ? '' : rep;
  }

  const messageListener = (e: any) => {
    switch (e.data.command) {
      case 'setRule:restriction':
        setRestrictionData({...restrictionDataRef.current, ruleBody: parse(unescape(e.data.rule)), stringRepresentation: filterStringRep(e.data.stringRepresentation), hasReceived: true});
        setHasRestrictionError(false);
        break;
      case 'setRule:preferred':
        setPreferredData({...preferredDataRef.current, ruleBody: parse(unescape(e.data.rule)), stringRepresentation: filterStringRep(e.data.stringRepresentation), hasReceived: true});
        setHasPreferredError(false);
        break;
      case 'setError:restriction':
        setHasRestrictionError(true);
        setSelectedTab("first");
        break;
      case 'setError:preferred':
        setHasPreferredError(true);
        setSelectedTab("second");
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    window.addEventListener("message", messageListener, false);
    return () => window.removeEventListener("message", messageListener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!restrictionData.iframe || (flags['tx_ao_enabled'] && !preferredData.iframe)) return;
    if (!restrictionData.hasReceived || (flags['tx_ao_enabled'] && !preferredData.hasReceived)) return;
    if (hasRestrictionError || hasPreferrredError) return;
    const cb = () => {
      props.onSubmit({
        ruleStringRepresentation: restrictionData.stringRepresentation,
        ruleBody: stringify(restrictionData.ruleBody) || '',
        seedRuleStringRepresentation: preferredData.stringRepresentation,
        seedRuleBody: stringify(preferredData.ruleBody) || '',
      });
    }
    if (!traits['tx_never_show_preferred_save_modal'] && preferredData.stringRepresentation) {
      setPreferredAudienceFirstTimeModalProps({
        isVisible: true,
        cb,
      });
    } else {
      cb();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    restrictionData,
    preferredData,
    hasRestrictionError,
    hasPreferrredError
  ]);

  // Invoke apply for each child
  function onApply() {
    setRestrictionData({...restrictionData, hasReceived: false});
    setPreferredData({...preferredData, hasReceived: false});
    setTimeout(() => {
      if (restrictionData.iframe) {
        restrictionData.iframe.sendApply();
      }
      if (preferredData.iframe) {
        preferredData.iframe.sendApply();
      }
    });
  }

  return (
    <>
      <div className="audience-builder">
        <div className="header">
          <p> Target Audience </p>
          <div className="cta">
            <FlightButton
              theme="secondary"
              label="Cancel"
              onClick={() => props.onCancel()}
            />
            <FlightButton
              theme="primary"
              label="Apply changes"
              onClick={() => onApply()}
            />
          </div>
        </div>
        {flags['tx_ao_enabled'] && props?.experience?.audienceOptimizationEligible && (
          <div className="tab">
            <div className="tab__content">
              <div className="tab__content-links">
                <a
                  href="# "
                  className={selectedTab === 'first' ? 'selected' : ''}
                  onClick={() => setSelectedTab("first")}
                >
                  <span> Restrictions </span>
                  {hasRestrictionError && (
                    <span className="tab-icon"> {getIcon('warning', '')} </span>
                  )}
                </a>
                  <a
                    href="# "
                    className={selectedTab === 'second' ? 'selected' : ''}
                    onClick={() => setSelectedTab("second")}
                  >
                    <span> Preferred Audience </span>
                    {hasPreferrredError && (
                      <span className="tab-icon"> {getIcon('warning', '')} </span>
                    )}
                  </a>
              </div>
              <div className={selectedTab === 'first' ? 'underline left' : 'underline right'}> </div>
            </div>
          </div>
        )}
        <div className="main">
          <div className="iframe-container">
            <AudienceIframe
              type="restriction"
              rule={props.ruleBody}
              isVisible={selectedTab === 'first'}
              onInit={(iframe: any) => {
                setRestrictionData({...restrictionData, iframe})
              }}
              audienceHasError={props.audienceHasError}
              experience={props.experience}
            />
            {flags['tx_ao_enabled'] && (
              <AudienceIframe
                type="preferred"
                rule={props.seedRuleBody}
                isVisible={selectedTab === 'second'}
                onInit={(iframe: any) => {
                  setPreferredData({...preferredData, iframe})
                }}
                audienceHasError={props.audienceHasError}
                experience={props.experience}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        isVisible={preferredAudienceFirstTimeModalProps.isVisible}
        title="You set a preferred audience"
        content={
          <div className="preferred-audience-notify-modal">
            <p> To generate accurate audience optimization suggestions, we will target up to {props.experience?.explorationBudget?.populationSize || 0} users outside of your preferred audience. Once the suggestions are ready, you will be notified. </p>
            <FlightCheckbox
              label="Don't show this again"
              checkState={dontShowPreferredAudienceFirstTime}
              onSelect={() => setDontShowPreferredAudienceFirstTime(dontShowPreferredAudienceFirstTime === 'SELECTED' ? 'UNSELECTED' : 'SELECTED')}
            />
          </div>
        }
        cancelButton={{
          name: 'Cancel',
          onClick: () => { setPreferredAudienceFirstTimeModalProps({...preferredAudienceFirstTimeModalProps, isVisible: false}) }
        }}
        primaryButton={{
          name: 'Continue',
          onClick: () => {
            if (dontShowPreferredAudienceFirstTime === 'SELECTED') {
              setTrait('tx_never_show_preferred_save_modal', true);
            }
            preferredAudienceFirstTimeModalProps.cb() }
        }}
      />
    </>
  )
}
