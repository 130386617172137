
import React, {useState,useEffect} from 'react';
import "./NoAccess.scss";
declare global {
  var Flybits:any;
}

var inject = function(){
  const script = document.createElement('script');
  script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/flybits@2.4.21/dist/flybits.js');
  document.body.appendChild(script);
}();

export default function NoAccess() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setError] = useState('');

  var login = function(){
    setError('');
    setLoading(true);
    
    if(!email){
      setError('Missing email.');
      setLoading(false);
      return;
    }

    if(!password){
      setError('Missing password.');
      setLoading(false);
      return;
    }

    var url = process.env.REACT_APP_API_URL + '/sso/auth/authenticate';
    Flybits.util.Api.fetch(url,{
      method: 'POST',
      body: JSON.stringify({
        email: email,
        password: password,
        projectID: '15F17238-D61C-4C2E-BB69-B14567B98633'
      })
    }).then(function(res: any){
      var token = res.headers['x-authorization'];
      window.location.replace("/redirect?origin=https://mycard.demo.flybits.com&path=templated-experiences&token="+token);
    }).catch(function(e: any){
      console.log('> uh oh ', e);
      if(e instanceof Flybits.Validation){
        var err = e.firstError();
        setError(err.header+': '+err.message);
      }
    }).then(function(){
      setLoading(false);
    });
  };

  var handleEmail = function(evt: any){
    setEmail(evt.target.value)
  };
  var handlePassword = function(evt: any){
    setPassword(evt.target.value);
  };

  var handlePassKeyDown = function(evt: any){
    if(evt.key === 'Enter'){
      login();
    }
  };

  return (
    <div className="no-access">
      <form className="entry-fields">
        <input className="entry-field email" type="text" placeholder="Email" value={email}onChange={handleEmail}/>
        <input className="entry-field" type="password" placeholder="Password" value={password} onKeyDown={handlePassKeyDown} onChange={handlePassword}/>
        <div className="login-trigger trigger-btn" onClick={login}>LOGIN</div>
        <div className={`loading-overlay ${isLoading?"enabled":""}`}></div>
        <div className="error-msg">{errorMsg}</div>
      </form>
      <div className="header">
        Access Denied
      </div>
      <div className="description">
        <p> You do not have enough permissions to access Experience Studio. Please <b> contact an administrator. </b> </p>
        <p> Vous ne disposez pas des autorisations suffisantes pour accéder à Experience Studio. Veuillez <b> contacter un administrateur. </b> </p>
      </div>
    </div>
  )
}
