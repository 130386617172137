
import BaseAPI from './base.api';
 
export default class LabelsAPI extends BaseAPI {
  private ROUTES = {
    all: '/kernel/labels?limit=1000000'
  }

  getLabels(searchTerm: string): Promise<[]> {
    return this.GET(this.ROUTES.all + `&search=${searchTerm}`);
  }
}
