import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as AuthActions from 'redux/auth/auth.action';
import useActions from 'hooks/useActions';

// Utilities
import qs from 'qs';
import { difference } from 'lodash';

// Service
import Storage from 'services/storage';

import './RedirectIndex.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function RedirectIndex() {

  const history = useHistory();
  const location = useLocation();
  const authActions = useActions(AuthActions);
  const storage = new Storage();

  const requiredParams = ['origin', 'path', 'token'];
  const whitelistPaths = [
    'templated-experiences',
    'templated-experiences/template-selection',
  ];

  useEffect(() => {
    handleRedirect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRedirect() {
    const query:{path?:string, token?: string, origin?: string} = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });

    let isValidRequest = difference(requiredParams, Object.keys(query)).length === 0
    const { origin, token, path } = query;
    if (isValidRequest && !!whitelistPaths.find(p => path!!.indexOf(p) !== -1)) {
      storage.setItem('origin', origin?.split('/#')[0]);
      authActions.authenticateUser(token).then(() => {
        history.replace(path!!);
      });
    } else {
      // invalid request, redirect back to origin
      window.location.href = origin!!;
    }
  }

  return (
    <div className="redirect-container">
      <CircularProgress/>
    </div>
  )
}
