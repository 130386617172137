import { TExperience, TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import { PaginationResponse } from 'interface/shared/api.interface';

export const TExperienceActionType = {
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
  SET_TEMPLATE: "SET_TEMPLATE",
  SET_INSTANCES: "SET_INSTANCES",
  SET_INSTANCE: "SET_INSTANCE",
  SET_MODIFIED: "SET_MODIFIED",
  SET_TEMP: "SET_TEMP",
  UPDATE_INSTANCES: "UPDATE_INSTANCES",
  SET_PUSH_TYPES: "SET_PUSH_TYPES",
}

export interface TExperienceState {
  instances: PaginationResponse<TExperienceInstance> | null;
  instance: TExperienceInstance | null;
  template: TExperienceInstance | null;
  isLoading: boolean;
  errorMessage: string;
  isModified: boolean;
  previewContent?: any;
  pushTypes: Array<object>;
  temp: {
    delivery: {
      trigger: string,
      frequencyTitle: string,
      frequencyDescr: string,
      frequencyString: Array<string>,
      frequency: {
        total: string,
        value: string,
        period: {
          key: string,
          name: string,
        },
      },
      limitString: Array<string>,
      limit: {
        key: string,
        name: string
      },
      remain: string,
      delayString: Array<string>,
      delay: {
        selected: string,
        value: string,
        period: {
          key: string,
          name: string,
        },
      },
    },
    trigger: undefined,
    instance: TExperienceInstance | undefined
  }
}

export interface SetTemplateAction {
  type: typeof TExperienceActionType.SET_TEMPLATE,
  payload: {
    template: TExperience | null
  }
}

export interface SetInstanceAction {
  type: typeof TExperienceActionType.SET_INSTANCE,
  payload: {
    instance: TExperienceInstance | null
  }
}

export interface SetInstancesAction {
  type: typeof TExperienceActionType.SET_INSTANCES,
  payload: {
    instances: PaginationResponse<TExperienceInstance>
  }
}

export interface SetLoadingAction {
  type: typeof TExperienceActionType.SET_LOADING,
  payload: {
    flag: boolean
  }
}

export interface SetErrorAction {
  type: typeof TExperienceActionType.SET_ERROR,
  payload: {
    errorMessage: string
  }
}

export interface SetModifiedAction {
  type: typeof TExperienceActionType.SET_MODIFIED,
  payload: boolean
}

export interface UpdateInstancesAction {
  type: typeof TExperienceActionType.UPDATE_INSTANCES,
  payload: {
    id: string
    status: string
  }
}

export interface SetTempAction {
  type: typeof TExperienceActionType.SET_TEMP,
  payload: any
}
