import React, {useEffect, useContext, useState} from 'react';
import { PushFormContext } from 'components/PushMessage/PushFormProvider/PushFormProvider';
import { customFormSchema, customFormSchemaDraft } from 'validator/push-message.validator';
import useForm from 'hooks/useForm';
import './CustomForm.scss';
import {FlightSelect} from '@flybits/webapp-design-system-react';
import {useSelector} from "react-redux";

export default function CustomForm() {

  const context: any = useContext(PushFormContext);
  const {customPushType, setCustomPushType} = context;
  const [customFields, setCustomFields] = useState<any>({});
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const {data, onChangeCustomField} = useForm({
    formData: {...context.formFields?.data},
    formSchema: customFormSchema,
    formSchemaDraft: customFormSchemaDraft,
    isDraft: context?.isDraft,
    validateOnLoad: context?.validateOnLoad,
  });


  useEffect(() => {
    const pushTypeKey = context?.selectedNotificationType?.key;
    reduxTemplatedExperienceState.pushTypes.forEach((pushType:any) => {
      if(pushType.id === pushTypeKey) {
        setCustomPushType(pushType);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.selectedNotificationType?.key]);


  useEffect(() => {
    for (let field in context?.formFields?.data) {
      if(field !== 'pushPayloadTypeId') {
        setCustomFields({...customFields, [field]: context.formFields.data[field]});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function renderCustomFields(data: any) {
    let fieldKeys = Object.keys(context.formFields?.data);
    let renderFields = fieldKeys.map(k => {
      if(customPushType?.schema[k]?.type === 'dropdown') {
        const fieldOptions = customPushType?.schema[k]?.valueOptions?.map((option:string) => ({key: option, name: option}))
        return <FlightSelect
          key={customPushType?.schema[k]?.description}
          className="basic-form__custom-field"
          label={customPushType?.schema[k]?.description}
          options={fieldOptions}
          selected={{key:k, name:data[k]?.value || ''} || {}}
          hasLabelAnimation
          handleOptionClick={(e: any) => updateCustomField(k, e)}
          dropdownMaxHeight="150px"
          width="252px"
          errorMessage={data[k]?.error}
          hasError={data[k]?.hasError}
        />
      }
      return true;
    });
    if(fieldKeys.length) {
      return <div className="basic-form__custom-container">{renderFields}</div>
    }
    return false;
  }

  function updateCustomField(key: string, value: any) {
    onChangeCustomField(key, value);
    context.updateCustomField(
      'data',
      {
        pushPayloadTypeId: customPushType.id,
        ...customFields,
        [key]: value.key
      }
    ); // update context
  }


  return (
    <div className="custom-form">
      {context.formFields?.data?.pushPayloadTypeId && renderCustomFields(data)}
    </div>
  )
}
