import { Content } from 'interface/content/content.interface';
import { PaginationResponse } from 'interface/shared/api.interface';
import { TExperience, TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import {
  TExperienceActionType,
  SetTemplateAction,
  SetInstancesAction,
  SetLoadingAction,
  SetErrorAction,
  SetModifiedAction,
  SetInstanceAction,
  UpdateInstancesAction,
  SetTempAction,
} from 'redux/templated-experience/templated-experience.type';

import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { getIncompleteCount, validateTemplatedExperience } from 'validator/templated-experience.validator';
import {
  findActionParent,
  convertToInstance,
  getPushPayload,
  generateUUID,
  updateEmptyContentName,
  cleanupTimezone,
  serializeScheduleData,
  deserializeScheduleData,
  deserializeActivationData,
  serializeActivationData,
  serializeDelivery,
  serializeTriggerData,
  deserializeTriggerData,
  getJourneyMode,
} from 'helpers/templated-experience.helper';
import { showSnackbar } from 'redux/snackbar/snackbar.action';
import { ExperienceSchedule } from 'interface/experience/experience.interface';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { trackEvent } from 'helpers/analytics.helper';

function setLoading(payload: { flag: boolean }): SetLoadingAction {
  return {
    type: TExperienceActionType.SET_LOADING,
    payload,
  };
}

function setError(payload: { errorMessage: any }): SetErrorAction {
  return {
    type: TExperienceActionType.SET_ERROR,
    payload,
  };
}

export function setPushTypes(payload: any) {
  return {
    type: TExperienceActionType.SET_PUSH_TYPES,
    payload,
  };
}

export function setTemplate(payload: { template: TExperienceInstance | null }): SetTemplateAction {
  return {
    type: TExperienceActionType.SET_TEMPLATE,
    payload,
  };
}

export function setInstance(payload: { instance: TExperienceInstance | any }): SetInstanceAction {
  return {
    type: TExperienceActionType.SET_INSTANCE,
    payload,
  };
}

function setInstances(payload: { instances: PaginationResponse<TExperienceInstance> }): SetInstancesAction {
  return {
    type: TExperienceActionType.SET_INSTANCES,
    payload,
  };
}

export function setModified(payload: boolean): SetModifiedAction {
  return {
    type: TExperienceActionType.SET_MODIFIED,
    payload,
  };
}

export function updateInstances(payload: { id: string; status: string }): UpdateInstancesAction {
  return {
    type: TExperienceActionType.UPDATE_INSTANCES,
    payload,
  };
}

export function setTemp(payload: any): SetTempAction {
  return {
    type: TExperienceActionType.SET_TEMP,
    payload,
  };
}

/**
 * This function is responsible for modifying the value of "actionBody" of action with corresponding actionID.
 * Any modification to the actionBody should be done through this function to streamline the way we access and modify
 * data in templatedExperience data in redux.
 * @param actionID the id of the box/card
 * @param callback the json object that should replace the actionBody of action with actionID
 */
function _updateActionBody(actionID: string, callback: () => object) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { templatedExperience } = getState();
    let { template, instance } = templatedExperience;
    if (!isEmpty(template)) {
      // update template (create route)
      const [parentIdx, childIdx] = findActionParent(template, actionID);
      if (template?.steps?.length && template?.steps[parentIdx]?.actions[childIdx]?.actionBody!!) {
        template.steps[parentIdx].actions[childIdx].actionBody = callback();
      }
      template = validateTemplatedExperience(template);
      dispatch(
        setTemplate({
          template: {
            ...template,
            incompleteCount: getIncompleteCount(template),
            showError: getIncompleteCount(template) === 0 ? false : template.showError,
          },
        }),
      );
    } else {
      // update instance (edit route)
      const [parentIdx, childIdx] = findActionParent(instance, actionID);
      instance.steps[parentIdx].actions[childIdx].actionBody = callback();
      instance = validateTemplatedExperience(instance);
      dispatch(
        setInstance({
          instance: {
            ...instance,
            incompleteCount: getIncompleteCount(instance),
            showError: getIncompleteCount(instance) === 0 ? false : instance.showError,
          },
        }),
      );
    }
  };
}

export function setSchedule(schedule: TExperienceInstance['schedule']) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { templatedExperience } = getState();
    let { template, instance } = templatedExperience;
    if (!isEmpty(template)) {
      dispatch(
        setTemplate({
          template: {
            ...template,
            schedule,
          },
        }),
      );
    } else {
      dispatch(
        setInstance({
          instance: {
            ...instance,
            schedule,
          },
        }),
      );
    }
  };
}

export function clearTemplate() {
  return (dispatch: any) => {
    dispatch(setTemplate({ template: null }));
  };
}

export function clearInstance() {
  return (dispatch: any) => {
    dispatch(setInstance({ instance: null }));
  };
}

export function fetchInstances(opts: any) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .getTemplatedInstances(opts)
        .then((data: PaginationResponse<TExperienceInstance>) => {
          dispatch(setInstances({ instances: data }));
          resolve(data);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function fetchInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return async (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .getTemplatedExperienceInstance(id)
        .then((instance: TExperienceInstance) => {
          instance.schedule = deserializeScheduleData(instance.schedule);
          instance.steps = deserializeTriggerData(instance.steps);
          instance.activation = deserializeActivationData(instance.activation, instance.schedule);
          let _instance = validateTemplatedExperience(instance);
          _instance = {
            ..._instance,
            incompleteCount: getIncompleteCount(_instance),
          };
          dispatch(
            setInstance({
              instance: _instance,
            })
          );
          dispatch(
            setTemp({
              instance: _instance,
            }),
          );
          resolve(_instance);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function fetchTemplate(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .getTemplatedExperience(id)
        .then((template: TExperience) => {
          let _template: TExperienceInstance = convertToInstance(template);
          _template.steps = deserializeTriggerData(_template.steps);
          _template = {
            ...validateTemplatedExperience(_template),
            status: 'Draft',
            incompleteCount: getIncompleteCount(_template),
          };
          dispatch(
            setTemplate({
              template: _template,
            }),
          );
          resolve(_template);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function createTemplatedInstance(
  payload: {
    status: string;
    schedule: ExperienceSchedule;
  } = {
    status: 'Draft',
    schedule: {
      start: 0,
      end: 0,
      timezone: '',
    },
  },
) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();

  return (dispatch: any, getState: any) => {
    let template: TExperienceInstance = getState().templatedExperience.template;
    template = updateEmptyContentName(template, 'Untitled');
    template.schedule = serializeScheduleData(template.schedule);
    template.steps = serializeTriggerData(template.steps);
    template.status = payload.status;
    template.journeyMode = getJourneyMode(template);
    if (template.schedule?.start && template.schedule.start > moment().unix() && template.status !== 'Draft') {
      template.status = 'Scheduled';
      trackEvent({
        category: 'Templated Experiences',
        action: 'Create with a schedule',
      });
    } else {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Create and Activate',
      });
    }

    return new Promise((resolve, reject) => {
      template = generateUUID(template);
      template = validateTemplatedExperience(template);
      template.name =
        template.name && template.name !== 'Untitled'
          ? template.name
          : `Untitled ${moment().format('MMM D, YYYY, hh:mm:ssa')}`;
      template.templateID = template.id; // include template id in the POST
      dispatch(setTemplate({ template: template })); // update the error state
      if (template.status === 'Draft' || getIncompleteCount(template) === 0) {
        _createTemplatedInstance(template);
      }

      function _createTemplatedInstance(template: any) {
        dispatch(setLoading({ flag: true }));
        templatedExperienceAPI
          .createTemplatedInstance(template)
          .then((res) => {
            let isDraft = template.status === 'Draft' ? ' (DRAFT)' : '';
            dispatch(
              showSnackbar({
                content: `Successfully created experience${isDraft}: "${template.name}"`,
              }),
            );
            dispatch(setModified(false));
            resolve(res);
          })
          .catch((err: any) => {
            dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
            reject();
          })
          .finally(() => {
            dispatch(setLoading({ flag: false }));
          });
      }
    });
  };
}

export function updateInstanceSchedule(payload: { schedule: ExperienceSchedule }) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any, getState: any) => {
    let instance: TExperienceInstance = getState().templatedExperience.instance;
    payload.schedule = serializeScheduleData(payload.schedule);
    instance.activation = serializeActivationData(instance.activation, payload.schedule);
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .updateTemplatedExperienceInstanceSchedule(instance.id, payload.schedule)
        .then((res) => {
          dispatch(
            showSnackbar({
              content: `Successfully updated schedule of the experience: "${instance.name}"`,
            }),
          );
          resolve(res);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function updateTemplatedInstance(
  payload: { status: string } = { status: 'Draft' },
  queryParams: object | undefined,
) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any, getState: any) => {
    let instance: TExperienceInstance = getState().templatedExperience.instance;
    instance.status = payload.status;
    instance.schedule = {
      ...instance.schedule,
      timezone: cleanupTimezone(instance.schedule.timezone || ''),
    };
    return new Promise((resolve, reject) => {
      instance.schedule = serializeScheduleData(instance.schedule);
      instance.steps = serializeTriggerData(instance.steps);
      instance.journeyMode = getJourneyMode(instance);
      instance.activation = serializeActivationData(instance.activation, instance.schedule);
      instance = validateTemplatedExperience(instance);
      instance.name = instance.name || `Untitled ${moment().format('MMM D, YYYY, hh:mm:ssa')}`;
      instance.templateID = instance.id; // include template id in the POST
      dispatch(setInstance({ instance: instance })); // update the error state
      if (instance.status === 'Draft' || getIncompleteCount(instance) === 0) {
        _updateTemplatedInstance(instance, queryParams);
      }

      function _updateTemplatedInstance(instance: any, queryParams: object | undefined) {
        dispatch(setLoading({ flag: true }));
        templatedExperienceAPI
          .updateTemplatedExperienceInstance(instance, queryParams)
          .then((res) => {
            dispatch(
              showSnackbar({
                content: `Successfully updated experience: "${instance.name}"`,
              }),
            );
            dispatch(setModified(false));
            resolve(res);
          })
          .catch((err: any) => {
            dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
            reject();
          })
          .finally(() => {
            dispatch(setLoading({ flag: false }));
          });
      }
    });
  };
}

export function activateTemplatedExperienceInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .activateTemplatedExperienceInstance(id)
        .then((res: any) => {
          if (res.status === 'Active' || res.status === 'Scheduled') {
            dispatch(updateInstances({ id, status: res.status }));
            let content = `The experience has been successfully ACTIVATED`;
            let type = 'success';
            if (res.status === 'Scheduled') {
              content = `The experience has been successfully SCHEDULED`;
              type = 'info';
            }
            dispatch(showSnackbar({ content, type }));
            resolve(res);
          }
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

export function deactivateTemplatedExperienceInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .deactivateTemplatedExperienceInstance(id)
        .then((res: any) => {
          if (res.status === 'Inactive') {
            dispatch(updateInstances({ id, status: res.status }));
            dispatch(
              showSnackbar({
                content: `The experience has been DEACTIVATED`,
                type: 'default',
              }),
            );
            resolve(res);
          }
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

export function deleteTemplatedExperienceInstance(
  instance: TExperienceInstance,
  currentRowPerPage: number,
  currentPage: number,
) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .deleteTemplatedExperienceInstance(instance.id)
        .then((res: any) => {
          dispatch(fetchInstances({ limit: currentRowPerPage, offset: currentRowPerPage * (currentPage - 1) }));
          dispatch(
            showSnackbar({
              content: `Successfully deleted experience: "${instance.name || 'Untitled'}"`,
              type: 'default',
            }),
          );
          resolve(res);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

export function setAudience(payload: {
  ruleBody: string;
  seedRuleBody: string;
  ruleStringRepresentation: string;
  seedRuleStringRepresentation: string;
}) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { template, instance } = getState().templatedExperience;
    const target: TExperienceInstance = isEmpty(template) ? instance : template;
    target.steps[0].ruleStringRepresentation = payload.ruleStringRepresentation;
    target.steps[0].seedRuleStringRepresentation = payload.seedRuleStringRepresentation;
    target.steps[0].ruleBody = payload.ruleBody || ''; //ME-1002 payload comes in stringified from ember.
    target.steps[0].seedRuleBody = payload.seedRuleBody || '';
    target.steps[0].hasError =
      (isEmpty(payload.ruleStringRepresentation) && isEmpty(payload.seedRuleStringRepresentation)) || false;
    if (!isEmpty(template)) {
      dispatch(
        setTemplate({
          template: {
            ...template,
            incompleteCount: getIncompleteCount(template),
            showError: !!getIncompleteCount(template),
          },
        }),
      );
    }
    if (!isEmpty(instance)) {
      dispatch(
        setInstance({
          instance: {
            ...instance,
            incompleteCount: getIncompleteCount(instance),
            showError: !!getIncompleteCount(instance),
          },
        }),
      );
    }
  };
}

export function setName(name: string) {
  return (dispatch: any, getState: any) => {
    const template: TExperienceInstance = getState().templatedExperience.template;
    template.name = name;
    dispatch(setTemplate({ template }));
  };
}

export function setInstanceName(name: string) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const instance: TExperienceInstance = getState().templatedExperience.instance;
    instance.name = name;
    dispatch(setInstance({ instance }));
  };
}

export function setContent(actionID: string, content: Content[]) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const contentIds = content?.map((item) => item.id);
    _updateActionBody(actionID, () => ({
      contentIds: contentIds,
      contents: content,
    }))(dispatch, getState);
  };
}

// Form object to action
export function setCustomPush(action: any, form: any) {
  const payload = getPushPayload(action);
  payload.title = form.title;
  payload.alert = form.alert;
  payload.data = { ...form.data };
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    if (temp?.delivery) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

// Form object to action
export function setContentPush(action: any, form: any) {
  const payload = getPushPayload(action);
  if (form) {
    payload.title = form.title;
    payload.alert = form.alert;
    payload.data = {
      contentId: {
        value: form?.contentId || '',
      },
    };
  }
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    if (!isEmpty(temp?.delivery) && temp.delivery.trigger !== '') {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

/**
 * This function is responsible for creating amplifiedPush.
 * @param action is the pushpayload created and sent from calling component
 * @param form validated form
 */
export function setAmplifiedPush(action: any, form: any) {
  const payload = getPushPayload(action);
  if (form) {
    payload.title = form?.title;
    payload.alert = form?.alert;
  }
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience?.temp;
    // get existing contentFrom State (1) for instance (update) - or -
    // (2) for template (create)
    let contentInstanceOrTemplate =
      (!isEmpty(getState().templatedExperience?.instance) &&  getState().templatedExperience?.instance) ||
      getState().templatedExperience?.template;
    if (contentInstanceOrTemplate) {
      contentInstanceOrTemplate = contentInstanceOrTemplate?.steps[0]?.actions;
    }
  
    // only one push can exist, any changes
    // will have to overwrite existing push attached to the content
    if (contentInstanceOrTemplate.length  !== 2 && action?.actionID === '00000000-0000-0000-0000-000000000000') {
      contentInstanceOrTemplate?.push(action);
    } 
      
    if (temp?.delivery) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.actionID, () => action.actionBody)(dispatch, getState);
  };
}

export function removeAmplification() {
  return (dispatch: any, getState: any) => {
    let contentInstanceOrTemplate =
      (!isEmpty(getState().templatedExperience?.instance) && getState().templatedExperience?.instance) ||
      getState().templatedExperience?.template;

    const removeAmplification = contentInstanceOrTemplate?.steps[0]?.actions.filter(
      (item: any) => item.actionType !== 'push',
    );
    const getInstanceOrTemplate = isEmpty(getState().templatedExperience?.instance) ? 'template' : 'instance';
    contentInstanceOrTemplate.steps[0].actions = removeAmplification;
    let removeAmplifyFromObject = validateTemplatedExperience(contentInstanceOrTemplate); 

    dispatch(
      getInstanceOrTemplate === 'instance' ?
      setInstance({
        instance: {
          ...removeAmplifyFromObject,
          incompleteCount: getIncompleteCount(contentInstanceOrTemplate),
          showError: getIncompleteCount(contentInstanceOrTemplate) === 0 ? false : contentInstanceOrTemplate.showError,      
        },
      }) :
      setTemplate({
        template: {
          ...removeAmplifyFromObject,
          incompleteCount: getIncompleteCount(contentInstanceOrTemplate),
          showError: getIncompleteCount(contentInstanceOrTemplate) === 0 ? false : contentInstanceOrTemplate.showError,      
        },
      }) ,
    );
  };
}

// Form object to action
export function setWebLinkPush(action: any, form: any) {
  const payload = getPushPayload(action);
  payload.title = form.title;
  payload.alert = form.alert;
  payload.data = {
    url: form.url || {},
  };
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    if (temp?.delivery) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

// Form object to action
export function setBasicPush(action: any, form: any) {
  const payload = getPushPayload(action);
  payload.title = form.title;
  payload.alert = form.alert;
  delete payload.data;
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    // get languages and parse it through
    if (temp?.delivery) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

export function showError(type: 'instance' | 'template') {
  return (dispatch: any, getState: any) => {
    if (type === 'instance') {
      const instance: TExperienceInstance = getState().templatedExperience.instance;
      dispatch(setInstance({ instance: { ...instance, showError: true } }));
    } else {
      const template: TExperienceInstance = getState().templatedExperience.template;
      dispatch(setTemplate({ template: { ...template, showError: true } }));
    }
  };
}

export function setTempDelivery(payload: any) {
  return (dispatch: any) => {
    dispatch(setTemp({ delivery: payload }));
  };
}

export function setStepTrigger({ stepId, payload }: any) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { templatedExperience } = getState();
    let { template, instance } = templatedExperience;
    if (!isEmpty(template)) {
      // update template (create route)
      let stepIndex = template.steps.indexOf(template.steps.find((step: any) => step.id === stepId));
      template.steps[stepIndex].trigger = {
        ...template.steps[stepIndex].trigger,
        ...payload,
      };
      template = validateTemplatedExperience(template); //TODO: add validation for trigger
      dispatch(
        setTemplate({
          template: {
            ...template,
            incompleteCount: getIncompleteCount(template),
            showError: getIncompleteCount(template) === 0 ? false : template.showError,
          },
        }),
      );
    } else {
      // update instance (edit route)
      let stepIndex = instance.steps.indexOf(instance.steps.find((step: any) => step.id === stepId));
      instance.steps[stepIndex].trigger = {
        ...instance.steps[stepIndex].trigger,
        ...payload,
      };
      instance = validateTemplatedExperience(instance);
      dispatch(
        setInstance({
          instance: {
            ...instance,
            incompleteCount: getIncompleteCount(instance),
            showError: getIncompleteCount(instance) === 0 ? false : instance.showError,
          },
        }),
      );
    }
  };
}

export function checkExperienceName(opts: any) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .getTemplatedInstances(opts)
        .then((data: PaginationResponse<TExperienceInstance>) => {
          resolve(data);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}
