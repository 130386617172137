import React, { useEffect } from 'react';
import Storage from 'services/storage';

import { useDispatch } from 'react-redux';
import { showSnackbar } from 'redux/snackbar/snackbar.action';

import { IFrameHelper } from 'helpers/iframe.helper';
import '../../Audience/AudienceIframe/AudienceIframe.scss';

interface IProps {
  id: string;
  isEdit?: boolean;
  onLoad?: (iframe: any) => void;
  isVisible: boolean;
  payload?: {}
}

export default function ContentIframe(props: IProps) {
  let iframe: any;
  const storage = new Storage();
  const dispatch = useDispatch();


  async function openIframe() {
    let origin = await storage.getItem('origin');
    origin = origin || 'http://localhost:4200/#';
    let baseURL = '';
    if (props.isEdit) {
      baseURL = `${origin.split('/#')[0]}/#/content-hub/content/${props.id}/edit-v2?hideSidebar=true`;
    } else {
      baseURL = `${origin.split('/#')[0]}/#/content-hub/templates/${props.id}/create-v2?hideSidebar=true`;
    }

    let URL = baseURL;
    if (!iframe) {
      iframe = new IFrameHelper(`id-contentIframe`, document.querySelector(`.iframe-contentIframe`), URL);
    } else {
      iframe.setURL(URL);
    }
    return iframe
      .loadIframe()
      .then(() => {
        iframe.sendOptionalPayload(props.payload);
        props.onLoad && props.onLoad(iframe);
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            content: `Error loading content iframe`,
            type: 'error',
          }),
        );
      });
  }

  useEffect(() => {
    openIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className={`audience-iframe ${!props.isVisible ? 'hidden' : ''}`}>
      <div className={`iframe-container iframe-contentIframe`}></div>
    </div>
  );
}
