
import BaseAPI from './base.api';
import { LanguageResponse } from 'interface/settings/settings.interface';

export default class SettingAPI extends BaseAPI {
  private ROUTES = {
    allLanguages: 'sso/settings/configs/languages',
    allCategories: 'sso/settings/configs/point-sol-categories',
    allSidepanels: 'sso/settings/configs/point-sol-sidepanel',
  }

  getLanguages(): Promise<LanguageResponse> {
    return this.GET(this.ROUTES.allLanguages);
  }

  getCategories(): Promise<any> {
    return this.GET(this.ROUTES.allCategories);
  }

  getSidePanelSettings(): Promise<any> {
    return this.GET(this.ROUTES.allSidepanels);
  }
}
