import React, { useState } from 'react';
import { ReactComponent as AmplificationIcon } from 'assets/icons/megaphone.svg';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import { FlightButton } from '@flybits/webapp-design-system-react';

import './AmplifiedPush.scss';
import { useActions } from 'redux/action';

export const AmplificationBar = () => {
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const proceedToRemoveAmplification = () => {
    reduxTemplatedExperienceAction.removeAmplification();
    setConfirmModalProps({ ...confirmModalProps, isVisible: false });
  };

  const _confirmModalProps = {
    isVisible: false,
    icon: '',
    title: `Remove Amplification`,
    content: `If you remove the amplification, all of the push notification's configurations will be lost.`,
    primaryButton: {
      name: 'Confirm',
      onClick: () => proceedToRemoveAmplification(),
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setConfirmModalProps({ ...confirmModalProps, isVisible: false }),
    },
  };

  const [confirmModalProps, setConfirmModalProps] = useState({ ..._confirmModalProps });
  const handleKeyDown = (e: any) => {
    if(e.key === "Enter") {
      e.stopPropagation();
      setConfirmModalProps({ ...confirmModalProps, isVisible: true });  
    }
  }
  return (
    <>
      <ConfirmModal {...confirmModalProps} />
      <div className="amplification-bar__amplification">
        <div className="amplification-bar__amplification__items">
          <AmplificationIcon className="amplification-bar__amplification__items__icon" />
          <span className="amplification-bar__amplification__items__primary-text">Amplified by</span>
          <div
            tabIndex={0}
            onKeyDown={handleKeyDown}>
            <FlightButton
              theme="minor"
              size="medium"
              className="amplification-bar__amplification__items__remove-text"
              onClick={(e: any) => {
                e.stopPropagation();
                setConfirmModalProps({ ...confirmModalProps, isVisible: true });
              }}
              label={'Remove'}
            />
          </div>
        </div>
      </div>
    </>
  );
};
