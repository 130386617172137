import React, { useState, useEffect } from 'react';
import ContentAPI from 'services/api/content.api';

import { Content } from 'interface/content/content.interface';
import ContentListModal from 'components/Modal/ContentModal/ContentListModal';
import { uniqBy, some } from 'lodash';

import ContentEditorModal from 'components/Modal/ContentModal/ContentEditorModal/ContentEditorModal';

interface IProps {
  isVisible: boolean;
  showTemplates: boolean;
  isCalledFromPush: boolean;
  onCancel: () => void;
  onBack: () => void;
  selectedId?: string;
  editedInstanceId?: string;
  isAfterCreate: boolean;
  onApply: (content: Content[] | Content | null) => void;
  onEditInstance: (content: Content | null) => void;
  onCreateContent: () => void;
  actionBody?: any;
}

interface PaginatedContent {
  total: 0;
  content: Content[];
}

export default function ContentModal(props: IProps) {
  const contentAPI = new ContentAPI();
  const [content, setContent] = useState<PaginatedContent>({ total: 0, content: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [templateContent, setTemplateContent] = useState<Content>();
  const [searchTerm, setSearchTerm] = useState('');
  const [isListModalVisible, setListModal] = useState(false);
  const [isEditorModalVisible, setEditorModal] = useState(false);

  let limit = 7;

  const getHasMore = () => {
    return content?.total > content?.content?.length ? true : false;
  };

  const handleFetchMoreData = async () => {
    if (props.isVisible) {
      if (getHasMore()) {
        const incrementPage = page + 7;
        setContent({ content: content?.content, total: content?.total });
        setTimeout(async () => {
          await fetchContent(incrementPage);
          setPage(incrementPage);
        }, 600);
      }
    }
  };

  //function to handle edited instances under (1) multi-select (2) paginated list circumstances
  const setEditedInstances = (editedInstance: any) => {
    const filteredlist = content.content.filter((el) => el.id !== props.editedInstanceId);
    const combinedContentInstances = [editedInstance, ...filteredlist];
    setContent({ content: combinedContentInstances, total: content.total });
  };

  const setContentInstances = (res: any) => {
    if ((content.content && content.content.length !== 0 && !content.content[0]?.templateId) || searchTerm !== '') {
      return res.data;
    } else {
      return uniqBy(content?.content ? [...content.content, ...res.data] : res.data, 'id');
    }
  };

  const setContentTemplates = (res: any) => {
    if (content?.content[0]?.templateId || searchTerm !== '') {
      return res.data;
    } else {
      return uniqBy(content?.content ? [...content.content, ...res.data] : res.data, 'id');
    }
  };

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const fetchContent = async (idx: number) => {
    if (!props.showTemplates && props.isVisible) {
      setIsLoading(true);
      let params = { offset: idx, limit: limit, search: searchTerm };
      if (searchTerm !== '') {
        //skip pagination for searchable queries
        params = { offset: 0, limit: 10000, search: searchTerm };
      }
      await contentAPI.getContentInstances(params).then((res: any) => {
        if (res.data.length !== 0) {
          //because the response is paginated, which means sometimes previously selected values
          // may not be included in the list as it is fetched with a createdAt sorting order
          // below we deal with (1) Push, where we search the response for a single instance, if it
          // does not exist we fetch it again. (2) Content, where we search the reponse for all the multiple
          // instances that could've been fetched and if they don't exist, we fetch them and append to the
          // main content response.
          if (!props.isCalledFromPush) {
            if (props.actionBody?.contentIds?.length !== 0) {
              let containsMultiPleSelectedContent: string[] = [];
              props?.actionBody?.contentIds?.map(async (item: any) => {
                if (!some(res.data, { id: item })) {
                  containsMultiPleSelectedContent.push(item);
                }
              });
              if (
                containsMultiPleSelectedContent &&
                containsMultiPleSelectedContent.length !== 0 &&
                searchTerm === ''
              ) {
                let resultant = res;
                let appendAdditionalInstance: any[] = [];
                props.actionBody?.contentIds.map((item: string) => {
                  contentAPI.getInstance(item).then((additionalInstance: any) => {
                    appendAdditionalInstance.push({ ...additionalInstance });
                    setContent(() => {
                      const filetedList = uniqBy([...appendAdditionalInstance, ...resultant.data], 'id');
                      return {
                        content: setContentInstances({ data: filetedList }),
                        total: resultant.pagination.totalRecords,
                      };
                    });
                  });
                });
              }
            }
          } else {
            if (props.selectedId && props.selectedId !== '') {
              const containsSelectedContent = uniqBy(
                res.data.find((instance: { id: string | undefined }) => instance.id === props.selectedId),
                'id',
              );
              if (!containsSelectedContent && searchTerm === '') {
                let resultant = res;
                contentAPI.getInstance(props.selectedId).then((additionalInstance: any) => {
                  const appendAdditionalInstance = { data: [additionalInstance, ...resultant.data] };
                  setContent(() => {
                    return {
                      content: setContentInstances(appendAdditionalInstance),
                      total: resultant.pagination.totalRecords,
                    };
                  });
                });
              }
            }
          }
          setContent(() => {
            return {
              content: setContentInstances(res),
              total: res.pagination.totalRecords,
            };
          });
        } else {
          setContent({ total: 0, content: [] });
        }
        setIsLoading(false);
      });
    } else if (props.isVisible) {
      setIsLoading(true);
      const params = { offset: idx, limit: limit, search: searchTerm };
      await contentAPI.getContentTemplates(params).then((res: any) => {
        if (res.data.length !== 0) {
          setContent(() => {
            return {
              content: setContentTemplates(res),
              total: res.pagination.totalRecords,
            };
          });
        }
        setIsLoading(false);
      });
    }
  };

  const fetchInstance = () => {
    let _id = props.editedInstanceId || '';
    contentAPI.getInstance(_id).then((editedInstance: any) => {
      setEditedInstances(editedInstance);
    });
  };

  const handleCancel = () => {
    resetDeps();
    if (!props.showTemplates) {
      props.onCancel();
    } else {
      props.onBack();
    }
  };

  const handleApply = (args: any) => {
    if (props.showTemplates) {
      setTemplateContent(args);
      setTimeout(() => setListModal(false), 100);
      setEditorModal(true);
    } else {
      props.onApply(args);
    }
  };

  const handleEditInstance = (instanceToEdit: any) => {
    setTemplateContent(instanceToEdit);
    setTimeout(() => setListModal(false), 100);
    setEditorModal(true);
  };

  const fetchDependencies = async () => {
    await fetchContent(0);
  };

  const resetDeps = () => {
    setContent({ total: 0, content: [] });
    setPage(0);
  };

  const handleEditorClose = () => {
    setEditorModal(false);
    setTimeout(() => setListModal(true), 100);
  };

  const handleEditorOnNext = (args: any) => {
    if (props.showTemplates) {
      props.onApply(args);
      setTimeout(() => setEditorModal(false), 100);
    } else {
      props.onEditInstance(args);
      setTimeout(() => setEditorModal(false), 100);
    }
  };

  useEffect(() => {
    fetchDependencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible, props.showTemplates, searchTerm]);

  useEffect(() => {
    if (props.editedInstanceId && props.editedInstanceId !== '') {
      fetchInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editedInstanceId, props.isAfterCreate]);

  if (!props.isVisible && searchTerm !== '') {
    setSearchTerm('');
  }

  useEffect(() => {
    resetDeps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showTemplates]);

  useEffect(() => {
    setListModal(props.isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  return (
    <>
      <ContentListModal
        {...props}
        isVisible={isListModalVisible}
        icon="in-app.svg"
        isAfterCreate={props.isAfterCreate}
        title={props.showTemplates ? 'Choose a content template' : 'Select content'}
        listData={content?.content || []}
        page={page}
        onHandleSearch={(term: string) => handleSearch(term)}
        hasMore={getHasMore()}
        fetchMoreData={handleFetchMoreData}
        isCreateContentVisible={!props.showTemplates ? true : false}
        isLoading={isLoading}
        onCancel={handleCancel}
        onEditInstance={(instanceToEdit) => handleEditInstance(instanceToEdit)}
        onApply={(args) => handleApply(args)}
      />
      <ContentEditorModal
        isVisible={isEditorModalVisible}
        title={templateContent?.templateType ? 'Edit Content' : 'New Content'}
        onCancel={handleEditorClose}
        content={templateContent}
        onNext={(e) => handleEditorOnNext(e)}
      />
    </>
  );
}
