
import BaseAPI from './base.api';

import { PaginationResponse } from 'interface/shared/api.interface';
import { Content } from 'interface/content/content.interface';

export default class ContentAPI extends BaseAPI {
  private ROUTES = {
    allInstances: '/kernel/content/instances',
    allTemplates: '/kernel/content/templates',
    getInstance: 'kernel/content/instances/:id?data=true&bypassAdmin=true&managementMode=true&returnRichText=true'
  }

  params = {
    limit: 0,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc'
  };

  getContentInstances(opts = {}): Promise<PaginationResponse<Content>> {
    this.params = Object.assign(this.params, opts);
    return this.GET(this.ROUTES.allInstances, this.params);
  }

  getContentTemplates(opts = {}): Promise<PaginationResponse<Content>> {
    this.params = Object.assign(this.params, opts);
    return this.GET(this.ROUTES.allTemplates, this.params);
  }

  getInstance(id: string): Promise<null> {
    return this.GET(this.buildURL(this.ROUTES.getInstance, {id: id}));
  }
}